import { useHistory } from "react-router-dom";

export const GoBack = (props) => {
    let history = useHistory();

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          if(props.onAction)
          {
            props.onAction();
            return;
          }else{
            history.goBack();
          }
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 10,
          color: "var(--color-mode-accent)",
        }}
        to="#"
      >
        {/* <span className="fa fa-chevron-left"></span> */}
        <img src={require('../assets/svg/left-icon.png')} style={{width: 30}} />
      </a>
    </>
  );
};
