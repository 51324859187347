/**
 * External dependencies
 */
import constants from './components/constants';
import React, { Component } from 'react';
import postData from "./components/poster";
import fallBackProductList from './data/fallBackProductList.json';
import getCachedRates from './getCachedRates.js'
import getCachedQuestions from './getCachedQuestions.js'

/**
 * Data
 */
import steps from './data/steps'
import moment from 'moment';
const url = constants.api_url;

let disabled_products = ["204", "302", "164", "165", "166", "168", "10", "150"];
let active_products = ["167", "138", "9", "301", "300"];
// let disable_plans = ["1208", "1209"];
let disable_plans = [ ];

const getTitle = function(str)
{
	str = (str||'').toLowerCase();
	let setss = 	[
		['replace existing insurance','Replacement'],
		['hazardous sport','Hazardous Activities'],
		['license suspended','Drivers License'],
		['alcohol abuse','Drug/Alcohol'],
		['similar insurance','Similar Coverage'],
		['replace or change existing','Replacement'],
		['reside in the home','Home'],
		['citizens of','U.S. Citizen'],
		['prescription medication','Medication'],
		['heart disease','Heart / Blood Pressure'],
		['declined for insurance','Decline Coverage'],
		['acquired immune','AIDS'],
		['rocky mountain','Specified Disease'],
		['pregnant','Pregnancy'],
		['actively at work','Active at Work'],
		['carcinoma in situ','Cancer in Situ'],
		['prostate specific-antigen','PSA / CEA Test'],
		['other than normal','Abnormal test'],
		['outside the reference range','Abnormal test'],
		['prescribed medications', 'RX Medications'],
		['medications been prescribed', 'RX Medications'],
		['surgery within the last 5 years', 'Surgery'],
		['last 12 months by a physician', 'Physician'],
		['surgery advised', 'Surgery'],
		['test pending', 'Pending Test'],
		['disability income', 'Disability'],
		['hmo plan', 'HMO'],
		['replacement is involved','Replacement'],
		['surgery has not yet been performed','Pending Surgery'],
		['10 years','Cancer / 10 Yrs'],
		['leukemia','Melanoma, Hodgkins, Leukemia'],
		['prostate specific antigen','PSA / CEA Test'],
		['replace any other insurance','Replacement'],
		['dental, vision','Current Coverage'],
		['existing health coverage','Current Coverage'],
		['replace any existing','Replacement']
	
		];
		let title = '';
	setss.map(function(item, i){
		if(str.indexOf(item[0])  > -1 )
		{
			title = item[1];
		}
	})
	return title;
}
 

const AppContext = React.createContext();

export class AppProvider extends Component {
	constructor(props) {
		super(props);
		let stepData = localStorage.getItem("step_data5");
		let agent_info = localStorage.getItem("agent_info");
		agent_info = JSON.parse(agent_info || "{}");
		let template_id =  localStorage.getItem('orange_usingTemplateId') ||'default'; 
		
		if(!stepData){
			stepData = {
				"ch-primary-1" : true,
				"radio-withdrawal" : "Monthly",
				"schedule":'Monthly',
				beneficiary_repeater: [{}],
				// split_repeater: [{}, {}, {}, {}],
				"paymentTypeId": '140',
				"mail_policy_to":"Insured",
				"decline-email-contact":  "yes",
				"requested-effective-date": moment().add(1, 'day').toDate().toISOString().split("T")[0],
				"agent-signature-date": (new Date()).toISOString().split("T")[0],
				"pricing-mode": "monthly",
				"mail_to_agent" : localStorage.getItem("color_mode_emcpr_agent") != 'unchecked' ? 'yes' : false,
				"mail_to_customer" : localStorage.getItem("color_mode_emcpr_applicant") == 'checked' ? 'yes' : false,
			};
			
		}else{
			stepData = JSON.parse(stepData);
		}
		stepData['split_template'] = template_id;
		if(template_id == 'default')
		{
			stepData.split_repeater = [{},{},{},{}];
			stepData['agent-name'] = agent_info.name;
			stepData['agent-no'] = agent_info.agent_code;
			stepData['agent_code'] = agent_info.agent_code;
	
			
		}else{
			let dt = {};
			let templates =  localStorage.getItem('orange_agentTemplates');
			templates = JSON.parse(templates ||'[]') || [];
			templates.map(item=>{
				if(template_id==='template_'+item.id)
				{
					dt = item;
				}
			})
	 
			stepData['agent-no']=dt.agent_no;
			stepData['agent-name']=dt.printed_agent_name;
			stepData['agent_code']=dt.agent_no;
			stepData['split_repeater']=JSON.parse(dt.split_settings||'[{},{},{},{}]')||[{},{},{},{}];
			console.log(dt);
		  
		}

		this.state = {
			steps      : [],
			 
			formValues : {
				 
			},
			
			stepValues : stepData
		}
	}

	componentDidMount() {
		this.setState({
			steps,
			rates : {},
			loadedProducts : true,
			// plan_overrides
		}, this.maybeLoadPlans);
		
	}

	getToken(cb){
		if(window.is_offline_mode)
		{
			cb("_NO_TOKEN_");
			return;

		}
		 
		postData
		.getData(url + "manhattan.api.php?action=token").then(function(resp){
			// console.log(resp);
			if(resp == '_NO_INTERNET_')
			{
				// alert('no internet, will not load token');
				cb("_NO_TOKEN_");
				return;
			}
			if(resp.token_type)
			{
				let token = ''+resp.token_type+' '+resp.access_token;
				window.sessionStorage.setItem('manhattan_token', token);
				let time = moment()
				time.add((resp.expires_in - 300), 'seconds');
				let expi = time.unix()
				window.sessionStorage.setItem('token_expiry', expi);
				cb(token);
			}else{
				alert('Failed to fetch tokens');
				cb("_NO_TOKEN_");
			}
		});
	}

	maybeLoadToken(cb){
		let token = window.sessionStorage.getItem('manhattan_token');
		let token_expiry = window.sessionStorage.getItem('token_expiry');
		let now = moment().unix()
		if(!token || !token_expiry || (parseInt(token_expiry) < now) || token === '_NO_TOKEN_') 
		{
			// console.log("will fetch new token");
			this.getToken(cb);
		}else{
			// console.log("will reuse token");
			cb(token);
		}

	}


	addPlansToSteps(resp)
	{
		// console.log(resp);
		let products = [];
		resp.sort((a, b) => {
			if ( a.productName < b.productName ){
				return -1;
			}
			if ( a.productName > b.productName ){
				return 1;
			}
			return 0;
		});

		resp = resp.filter((item, i)=>{
			if( disabled_products.indexOf( ('' + item.productId) ) > -1 )
			{
				return false;
			}
			return true;
		})

		

		resp.map( (item, i)=>{
			products.push({
				id: 'product_'+item.productId,
				text: item.productName,
				standout: true,
				subtext: ' ',
				disabledDefault: active_products.indexOf(""+item.productId) < 0  ? true : false,
				isChecked: false,
			})
		})
		console.log(products);
		let steps = this.state.steps;
		steps = steps.map(function(item, i){
			if(item.is_plan_step)
			{
				item.showLoading = false
				item.checkboxes[0].values = products;
			}
			return item;
		});
		// console.log('steps are: ', steps)
		// console.log('products_plans are: ', resp)
		this.setState({
			steps:steps,
			loadedProducts: true,
			products_plans : resp
			// plan_overrides
		});
	}

	maybeLoadPlans(){
		this.addPlansToSteps(fallBackProductList);
		return;
	}


	maybeLoadPrices(product, pricePaymentType, cb){
		// console.log('kk', this.state)
		if(!this.state || !this.state.stepValues)return;
		let state = this.state.stepValues['state'];
		let gender = this.state.stepValues['gender'];
		let dob_year = this.state.stepValues['dob_year'];
		let dob_month = this.state.stepValues['dob_month'];
		let dob_date = this.state.stepValues['dob_date'];
		let schedule = this.state.stepValues['schedule'];


		let md = this.state.stepValues[product+'_radio'];
 
		if( this.state.stepValues['spouse-date'] )
		{

			// console.log('here');
			if(md == 'Self & Spouse' || md == 'Family' )
			{
				console.log('here2');
				let dob_year_spouse  = (new Date(  this.state.stepValues['spouse-date'] )).getFullYear();
				console.log(dob_year_spouse, dob_year)
				if( parseInt(dob_year_spouse) < parseInt(dob_year) )
				{
					dob_year = dob_year_spouse;
					dob_month = (new Date(  this.state.stepValues['spouse-date'] )).getUTCMonth() + 1
					dob_date = (new Date(  this.state.stepValues['spouse-date'] )).getDate()
				}
			}

		}

		// console.log(this.state.stepValues, product);
		let plan = '';
		// let mode = 'product_6_radio';
		let schedules = {
			"Monthly" :  320,
			"Quarterly": 321,
			"Semi-Annually": 322,
			"Annually": 323,
		}
		

		if(schedule && schedules[schedule])
		{
			schedule = schedules[schedule]
		}else{
			schedule = schedules['Monthly'];
		}
		
		// console.log( product  );
		// product_6_radio
		let mode = this.state.stepValues[product+'_radio'];

// console.log(dob_year);
		let obj = {
			Quote:  {
				"StateCode": state,
				"BirthDate": ""+dob_year+"-"+dob_month+"-"+dob_date,
				"GenderId": gender == 'Male' ? 110: 111,
				"TobaccoUsageFlg": false,
				"BillFrequencyId": schedule,
				"paymentTypeId" : 140,
			},
			"SelectedPlans": [],
			selectedRiders : [],
			Dependents : []
		};


		if(mode === 'Self')
		{
			// no need to create dependents table
		}else{
			
			if(mode==='Self & Child' || mode==='Family' || mode ==='Self & Child(ren)')
			{
				for(let i = 1 ;i < 11;i++)
				{
					let dep_enabled = this.state.stepValues['ch-dependent'+i];
					if(dep_enabled)
					{
					
						let ht =  this.state.stepValues['dependent'+i+'-h'];
						let wt =  this.state.stepValues['dependent'+i+'-w'];
						ht = (ht||'').replace('"', '').replace('\\', '');
						ht = ht.split("'");
						ht = parseInt(ht[0]*12) + parseInt(ht[1]||0);
						let nm =  this.state.stepValues['dependent'+i+'-full-name'];
						let dt =  this.state.stepValues['dependent'+i+'-date'];
						nm = nm.split();
						if(nm.length == 1)	nm.push('-')
						obj.Dependents.push({
							"RelationshipTypeId": 333,
							"FirstName": nm[0],
							"MiddleName": nm.length == 3 ? nm[1] : "",
							"LastName": nm.length == 3 ? nm[2]:nm[1],
							"SSN": "", // hardcoded
							"Height": parseInt(ht),
							"Weight": parseInt(wt),
							"BirthDate": moment(dt).format('YYYY-MM-DD'),
							"GenderId": 110 //hardcoded
						});
					}
				}
			}
			if(mode==='Self & Spouse' || mode==='Family' || mode==='Single Parent Family')
			{
				
				let ht =  this.state.stepValues['spouse-h'] || '';
				let wt =  this.state.stepValues['spouse-w'] || '';
				ht = ht.replace('"', '').replace('\\', '');
				ht = ht.split("'");
				ht = parseInt(ht[0]*12) + parseInt(ht[1]||0);
				let dt =  this.state.stepValues['spouse-date'] || '';

				let nm =  this.state.stepValues['spouse-full-name'] || '';
				nm = nm.split();
				if(nm.length === 1)	nm.push('-');

				obj.Dependents.push({
					"RelationshipTypeId": 332,
					"FirstName": nm[0],
					"MiddleName": nm.length == 3 ? nm[1] : "",
					"LastName": nm.length == 3 ? nm[2]:nm[1],
					"SSN": "", // hardcoded
					"Height": parseInt(ht),
					"Weight": parseInt(wt),
					"BirthDate": moment(dt).format('YYYY-MM-DD'),
					"GenderId": 110 //hardcoded
				});
			}
		}
		// console.log(obj);

		let product_id  = product.replace('product_', '');
		product_id = parseInt(product_id);
		let selected_product = this.state.products_plans.filter((item, i)=>{
			if(item.productId == product_id)	return true;return false;
		});
		if(selected_product.length)
		{
			selected_product = selected_product[0];
			
		}else{
			return;
		}
		selected_product.plans.map((plan)=>{
			if(   disable_plans.indexOf( "" + plan.planUnitId ) > -1 )
			{
				return;
			}
			obj.SelectedPlans.push({
				"PlanUnitId": plan.planUnitId,
				"EffectiveDate":  new moment().format('YYYY-MM-DD')
			})
			if(plan.riders)
			{
				plan.riders.map((rider, i)=>{
					obj.selectedRiders.push({
						"riderUnitId": rider.riderUnitId
					});
				})
			}
			
		});
		

		let is_internet = sessionStorage.getItem('is_internet_available');
		if(is_internet == 'no')
		{
			// alert('NO INTERNET');
			this.setState({
				rates : getCachedRates(  obj, product_id , this.state.mode, mode)
			})
			cb && cb();
			return;
		}



		this.maybeLoadToken((token)=>{
			console.log('token is: '+ token);
			if(token == '_NO_TOKEN_')
			{
				this.setState({
					rates : getCachedRates(  obj, product_id , this.state.mode)
				})
				cb && cb();
				return;
			}
			// console.log('getting plans now');
			postData
			.postData(url + "manhattan.api.php?action=getRates&token="+token, {
				postData : JSON.stringify( obj )
			}).then( (resp)=> {
				if(resp=='_NO_INTERNET_')
				{
					console.log('----')
					// alert('no internet, using cached prices');
					this.setState({
						rates : getCachedRates(  obj, product_id , this.state.mode)
					})
					cb && cb();
					return;
				}
				let rates  = { ...this.state.rates, ...resp };
				this.setState({
					rates: rates
				});
				cb && cb( );
 
			});
		});
	};

	maybeLoadQuestions = ( ) => {
		let state = this.state.stepValues['state'];
		let all_products  = this.state.products_plans;

		let plans = [];
		let selectedProducts = [];
		all_products.map(item=>{
			if( this.state.stepValues['product_'+item.productId] ){
				selectedProducts.push('p_'+item.productId);
				let pln =  this.state.stepValues['product_'+item.productId+'_plan'];

				if(pln)
				{
					pln = pln.replace('product_'+item.productId+'|planUnit', '');
					pln = parseInt(pln);
					item.plans.map((itemmm)=>{
						if(itemmm.planUnitId == pln){
							plans.push( itemmm.planId );
						}
					})

					// 
				}

			}
		})
		plans = plans.join(',');
			

		
			
		this.maybeLoadToken((token)=>{

			postData
			.getData(url + "manhattan.api.php?action=getQuestions&STATE_CODE="+state+"&PLANS="+plans+"&token="+token, { }, true).then( (resp)=> {

				let stepValues = this.state.stepValues;
				if(resp == '_NO_INTERNET_')
				{
					// alert('using cached questions.');
					resp = getCachedQuestions(state,plans.split(','), selectedProducts);
					// return [ ];
				}

				console.log('vasu', resp)
				let steps = this.state.steps;
				let questions_index = 0;
				// is_before_question_step
				let i = -1;
				for(let i = 0; i < steps.length;i++)
				{
					if( steps[i].is_before_question_step  )
					{
						questions_index = i+1;
						break;
					}
				}
				steps = steps.filter(function(item){
					if(item.is_question_step)return false;return true;
				})
				// let question_steps = resp;



				let questions = { 

				}
				let qids = [];
 
				resp.map(function(item, i){
					let question_set = 0;
					if(item.parentQuestionId)
					{
						question_set = item.parentQuestionId;
					}else{
						question_set = item.questionId;
					}
					item = JSON.parse(JSON.stringify(item));
					if(item['question_'+state])
					{
						item.question = item['question_'+state];
					}
					if(item['parentQuestion'+state])
					{
						item.parentQuestion = item['parentQuestion'+state];
					}

					if( ! questions['q_'+question_set]   )
					{
						//
						questions['q_'+question_set] = {
							master: [],
							child: [],
						};
						//
					}
					
					if(item.parentQuestionId)
					{
						questions['q_'+question_set].child.push(  item );
					}else{
						qids.push(item.questionId)
						questions['q_'+question_set].master.push(  item );
					}
				});

				console.log('vasu', questions)

				// console.log(JSON.stringify(questions));
				// question = questions.map(function(item, i){
				// 	if(item.plan)

				// })
				let question_ids = Object.keys(questions);

				let new_questions = [];
				// console.log(questions);
				question_ids.map((qid, i)=>{
					let q = questions[qid];

					
					let tmp = {};
					// console.log(q)
					if(q.master.length == 0)
					{
							return;
					}

					// if(q.master[0].audience =='Agent'){return;} // kyu remove kiya?
					tmp.subtitle = q.master[0].question;
					tmp.title = getTitle(q.master[0].question);
					tmp.is_question_step = true;
					tmp.radios = [
						{
							name: qid,
							isRequired: true,
							validationMessage: 'Radio is required',
							values: [  ]
						},
					];
					let kdone =[];
					q.master.map((qq, mm)=>{
						if(kdone.indexOf( ''+qid+'_'+qq.answerId) > -1)
						{
							//
						}else{
							kdone.push( ''+qid+'_'+qq.answerId);
							tmp.radios[0].values.push({
								id: ''+qid+'_'+qq.answerId,
								text: qq.answer,
								value : ''+qq.answerId+'|'+qq.answer,
								autoProceed: q.child.length == 1  ? 
								q.child[0].parentAnswerId == qq.answerId ?  false  : true
								: false  ,
								isChecked: false,
							});
						}
					})
					if(q.child.length == 1)
					{
						tmp.textarea =  {
							isRequired: true,
							visibleIfCondition: ''+q.child[0].parentAnswerId+'|'+q.child[0].parentAnswer,
							visible_if: qid,
							id: 'q_'+q.child[0].parentQuestionId+'||'+q.child[0].questionId,
							text: q.child[0].question,
							};
						
					}else{
						// todo
						let rads = {};
						// console.log(' its coming here.. its coming here..', q.child);
						let done_qs = [];
						let decease_name_mode = false;
						
						if(q.child.length > 10 && q.child[0].parentQuestion.toLowerCase().indexOf('addison')  > -1 && q.child[0].parentQuestion.toLowerCase().indexOf('mountain')  > -1 )
						{
							decease_name_mode =true;
						}
						let done_ansids = [];
						let has_more_radio = 0;
						q.child.map(function(item, i){
							if(!item.answer)
							{
								// console.log("---- textarea is: ", item);
								// is text field;
								if(item.question === 'If YES, please circle the disease(s) and list the name(s) of the person(s) to be excluded from dread disease coverage')
								{
									item.question = 'Please list the name(s) of the person(s) to be excluded from dread disease coverage';
								}
								

								if(tmp.textarea)
								{
									tmp.textarea2 =  {
										isRequired: true,
										visibleIfCondition: ''+item.parentAnswerId+'|'+item.parentAnswer,
										visible_if: qid == 'q_10055' && has_more_radio ? "q_10055||135" : qid,
										id: 'q_'+item.parentQuestionId+'||'+item.questionId,
										text: item.question,
									};
								}else{
									tmp.textarea =  {
										isRequired: true,
										visibleIfCondition: ''+item.parentAnswerId+'|'+item.parentAnswer,
										visible_if: qid == 'q_10055' && has_more_radio ? "q_10055||135" : qid,
										id: 'q_'+item.parentQuestionId+'||'+item.questionId,
										text: item.question,
									};
								}
								
								return;
							}

							if(!rads['sq_'+item.questionId])
							{
								done_qs.push('sq_'+item.questionId);
								rads['sq_'+item.questionId] = {
									extraClass:  i == 0 ? 'gapabove   no-br': '   no-br',
									visibleIfCondition: ''+q.child[0].parentAnswerId+'|'+q.child[0].parentAnswer,
									visible_if: qid,
									has_visible_if : true,
									label: item.question,
									name: 'q_'+item.parentQuestionId+'||'+item.questionId,
									isRequired: false,
									validationMessage: 'Radio is required',
									values: [  ],
									isq : true
								};
								has_more_radio++;
							}
							stepValues['q_'+item.parentQuestionId+'||'+item.questionId] = false;
							if(done_ansids.indexOf(item.parentQuestionId+'||'+item.questionId+'_'+item.answerId) > -1 )
							{
								//
							}else{
								done_ansids.push(item.parentQuestionId+'||'+item.questionId+'_'+item.answerId);
								rads['sq_'+item.questionId].values.push({
									id: item.parentQuestionId+'||'+item.questionId+'_'+item.answerId,
									text: decease_name_mode ? item.question : item.answer,
									trueFalseMode : decease_name_mode || false,
									type : decease_name_mode ? 'checkbox':  'radio',
									trueRepresentative: '330|Yes',
									value : ''+item.answerId+'|'+item.answer,
									label: item.question,
									isChecked: false,
								})
							}
							
						});
						// if(decease_name_mode)
						// {	if(!tmp.extraRadios)
						// 	tmp.extraRadios  = [] ;
						// 	done_qs.map(function(item, i){
						// 		tmp.extraRadios.push( rads[ item ] );
						// 	})

						// }else{
							done_qs.map(function(item, i){
								tmp.radios.push( rads[ item ] );
							})

						// }
						

						
					}
 
					if(
						(tmp.subtitle||'').toLowerCase().indexOf('addison') > -1 &&
						(tmp.subtitle||'').toLowerCase().indexOf('mountain') > -1
					)
					{
						// yha pe
						tmp.subtitle = 'I hereby represent that to the best of my knowledge, information and belief, no person to be insured under this policy is now or has ever been diagnosed or treated for:';
						tmp.featuresIsSmall = true;
						tmp.features = [{"text":"Addison’s Disease"},{"text":"Amyotrophic Lateral Sclerosis"},{"text":"Diphtheria"},{"text":"Encephalitis"},{"text":"Epilepsy"},{"text":"Legionnaires Disease"},{"text":"Lupus Erythematosus"},{"text":"Meningitis"},{"text":"Multiple Sclerosis"},{"text":"Muscular Dystrophy"},{"text":"Myasthenia Gravis"},{"text":"Niemann Pick Disease"},{"text":"Osteomyelitis"},{"text":"Poliomyelitis"},{"text":"Reye’s Syndrome"},{"text":"Rheumatic Fever"},{"text":"Rocky Mountain Spotted Fever"},{"text":"Sickle Cell Anemia"},{"text":"Tetanus"},{"text":"Toxic Epidermal Necrolysis"},{"text":"Toxic Shock Syndrome"},{"text":"Tuberculosis"},{"text":"Tularemia"},{"text":"Typhoid Fever"},{"text":"Whipple’s Disease"},{"text":"Whooping Cough"},{"text":"Tay-Sachs disease"}];
					}else if(
						(tmp.subtitle||'').toLowerCase().indexOf('alcoholism,') > -1 
					)
					{
						let tmpmsg = tmp.subtitle.split('\r\n');

						// yha pe
						tmp.subtitle = tmpmsg.shift();
						tmp.featuresIsSmall = true;
						tmpmsg = tmpmsg.map((kkw,i) => {
							return {text: kkw}
						});
						tmp.features = tmpmsg;
					}
					new_questions.push(tmp);
				})

				sessionStorage.setItem('questions_tmp', JSON.stringify(new_questions));
				sessionStorage.setItem('questions_raw_tmp', JSON.stringify(resp));
				new_questions.map((qq, i)=>{
					
					steps.splice(questions_index, 0, qq);
					questions_index++;
				})
				
				// qids
				
				
				stepValues["qids"] = qids.join('|');
				// debugger;
				localStorage.setItem("step_data5", JSON.stringify(stepValues));
				this.setState({
					steps : steps,
					stepValues: stepValues
				})


			});
		})
		 

		
	}






	handleChangeFormValues = (formValues) => {
		this.setState({
			formValues
		})
	}
	

	handleChangeStepValues = (stepValues, cb, is_plan_step, is_price_step) => {
		// this.stepValues[]
		// maybeLoadQuestions
		this.setState({
			stepValues
		}, ()=>{
			cb  && cb();
		})
		localStorage.setItem("step_data5", JSON.stringify(stepValues));
	}

	handleAddDateRow = (newRow) => {
		this.setState((state) => ({
			...state,
			steps: state.steps.map((step) => {
				if (step?.dates) {
					return {
						...step,
						dates: [...step.dates, newRow],
					}
				}

				return step
			})
		}))
	}
	handleChangeMode = (mode) =>{

		let stepValues = this.state.stepValues;

		stepValues["mode"] = mode;
		this.handleChangeStepValues(stepValues, ()=>{
			this.setState({
				mode : mode
			})
		});
		
	}

	isOnlyDvh(){

		const stepValues = this.state.stepValues
		let has_self = true;
		let has_any_other_than_dvh = false;
		constants.override_list.map((item, i) => {
			// console.log(stepValues[item ], stepValues[item+'_radio']);
			if( stepValues[item ] )
			{
				if(item !='product_301')
				{
					has_any_other_than_dvh = true;
				}
			}
			return item;
			
		});

		if(has_any_other_than_dvh)
		{
			return false;
		}
		return true;
		

	}

	hasSingleSelf(){
		const stepValues = this.state.stepValues
		let has_self = true;
		let has_any_other_than_dvh = false;
		constants.override_list.map((item, i) => {
			// console.log(stepValues[item ], stepValues[item+'_radio']);
			if( stepValues[item ] )
			{
				if(item !='product_301')
				{
					has_any_other_than_dvh = true;
				}
				if(   stepValues[item+'_radio']==='Self' )
				{
					// has_self= true;
				}else{
					// return false;
					has_self= false;
				}

			}
			
			return item;
			
		});
		
		if(has_any_other_than_dvh)
		{
			return has_self;
		}else{
			// dvh k alava kuch ni h .. skip krenge
			return false;
		}
		

	}

	

	render() {
		const handleChangeFormValues = this.handleChangeFormValues
		const handleChangeStepValues = this.handleChangeStepValues
		const handleAddDateRow       = this.handleAddDateRow
		const maybeLoadQuestions = this.maybeLoadQuestions
		const maybeLoadPrices = this.maybeLoadPrices.bind(this)
		const handleChangeMode = this.handleChangeMode
		
		let steps = this.state.steps;
		let products_plans = this.state.products_plans;

		const formValues = this.state.formValues
		const stepValues = this.state.stepValues

		steps = steps.filter((item, i) => {
			if(!item.showonlyon)
			{
				return true;
			}
			if(!this.state.mode)
			{
				return true;
			}
		 
			if(item.showonlyon === this.state.mode)
			{
				// debugger;
				if( this.state.mode==='payroll' && item.is_payroll_auth && stepValues.is_pyrl_price_bank ==='yes' )
				{
					// this is payroll auth page, and bank is choosen
					return false;
				}
				return true;
			}
			if(this.state.mode === 'payroll' &&  stepValues.is_pyrl_price_bank ==='yes'  && item.is_payroll_auth_alt){
				return true;
			}
			return false;
		})

		if(!this.hasSingleSelf())
		{
			steps = steps.filter((item, i) => {
				if(item.is_height_field)
				{
					return false;
				}
				return true;
			});
		}
		if(this.isOnlyDvh())
		{
			steps = steps.filter((item, i)=>{
				if(item.has_benef_repeater){
					return false;
				}
				return true;
			})
		}


		steps = steps.filter((item, i) => {
			if(item.is_agent_step)
			{
				if( ! stepValues.agent_is_here  )
				{
					return false;
				}
			}
			if(!item.showonlyif)
			{
				return true;
			} 
			return false;
		});
 
		
		return (
			<AppContext.Provider value={{
				steps,
				products_plans,
				// plan_overrides,
				formValues,
				rates : this.state.rates,
				stepValues,
				handleAddDateRow,
				handleChangeMode,
				handleChangeFormValues,
				maybeLoadQuestions,
				maybeLoadPrices,
				handleChangeStepValues
			}}>
				{this.state.loadedProducts ? this.props.children : <div className="wrapper innerAppWrapper"><div className="page_cust">
				<div className="lds-rollerWrap">
				
						</div>
						<div className="lds-rollerWrap">
						
						
						<div className="dot-flashing"></div>
						
						 
						</div>
						</div></div>}
			</AppContext.Provider>
			
		);
	}
}

export default AppContext;



