import React, {Component} from 'react';
import cx from 'classnames';
import Radio from '../components/radio/radio';
import { GoBack } from '../components/GoBack';
 
let step = {
   text: 'Choose the schedule which you want to pay',
   radios: [
     {
       // isHalfHalf: true,
       name: 'schedule',
       isRequired: true,
       validationMessage: 'Radio is required',
       values: [
         {
           id: 'schedule_monthly',
           name: 'schedule_monthly',
           text: 'Monthly',
           isChecked: false,
         },
         {
           id: 'schedule',
           name: 'radio2a-name-q2',
           text: 'Quarterly',
           isChecked: false,
         },
         {
           id: 'radio2b-name-q2_y',
           name: 'radio2b-name-q2',
           text: 'Semi-Annually',
           isChecked: false,
         },
         {
           id: 'radio2b-name-q2_n',
           name: 'radio2b-name-q2',
           text: 'Annually',
           isChecked: false,
         },
       ],
     },
   ],
 };
 class ScheduleSelection extends Component {
   handleChange(a, b, c, d) {
     this.props.onScheduleChange(b);
   }
   goBack(){
    this.props.onBack && this.props.onBack( ); 
   }
   render() {
     let cartData = this.props.cartData;
     let total = 0;
     return (
       <section className={'  ' + cx('section', ' ')} style={{paddingTop: 0}}>
         <div className="container">
           <div className="card p-2 " style={{padding: 16}}>
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                Pay Period
                </h1>
                <GoBack  onAction={()=>{
                  this.goBack();
                }}/>
              </div>
             <div className="mb-30"></div>
             <div className="shadow_card radio_card " style={{marginLeft:0, marginRight: 0}}>
               {step.radios && (
                 <div className="radios">
                   {step.radios.map((radio, index) => {
                     return (
                       <ul>
                         {radio.values.map(radioValue => (
                           <li key={radioValue.id}>
                             <Radio
                               name={radio.name}
                               id={radioValue.id}
                               defaultChecked={
                                 radioValue.text === cartData[radio.name] ||
                                 (radioValue.text === 'Monthly' &&
                                   !cartData[radio.name])
                               }
                               text={radioValue.text}
                               hint={radioValue.hint}
                               onChange={(a, b, c) => {
                                 // console.log(radioValue.autoProceed)
                                 this.handleChange(
                                   a,
                                   b,
 
                                   //   radioValue.autoProceed
                                 );
                               }}
                             />
                           </li>
                         ))}
                       </ul>
                     );
                   })}
                   {/* </ul> */}
                 </div>
               )}
             </div>
           </div>
         </div>
       </section>
     );
   }
}

export default ScheduleSelection;
