/**
 * External dependencies
 */
 import React, {Component} from 'react';
 import cx from 'classnames';
 import {nanoid} from 'nanoid';
 
 class Radio extends Component {
   handleChange = e => {
	//  console.log(
	//    'new val : ',
	//    this.props.trueFalseMode && e.target.value === this.props.currentVal
	// 	 ? false
	// 	 : e.target.value,
	//  );
	
	 this.props.onChange(
		e.target.name,
	   this.props.trueFalseMode && e.target.value === this.props.currentVal
		 ? false
		 : e.target.value,
	   false,
	   false,
	   this.props.name.replace('_plan', '') + '_price',
	   this.props.price,
	 );
   };
 
   render() {
	 let {
	   name,
	   id,
	   value,
	   schedule,
	   text,
	   disabled,
	   features,
	   price,
	   defaultChecked,forceType,
	   
	 } = this.props;
 	if(!price){
	  price ='';
	}
	// console.log(value||text, defaultChecked)
	 return (
	   <div className="radio-alt">
		 <input
		   type={forceType||"radio"}
		   disabled={disabled}
		   name={name}
		   id={id}
		   value={value || text}
		   onChange={this.handleChange}
		   defaultChecked={defaultChecked}
		 />
 
		 <label htmlFor={id} className={"cls_"+id}>
		   <strong>{text}</strong>
		   {/* {
			   defaultChecked ? 'yes' : 'no'
		   } */}
 
		   {features ? features.map(feature => {
			 return <span key={nanoid()}>{feature}</span>;
		   }):false}
 		   {price ?  <span className={'radio__price sche_' + schedule}>
			 {/* {price.split('.')[0]}.<span>{price.split('.')[1]}</span> */}
			 {price.split('.')[0]}.{price.split('.')[1]}
		   </span> : false}
		  
		 </label>
	   </div>
	 );
   }
 }
 
 export default Radio;
 