/**
 * External dependencies.
 */
import React, { Component } from 'react';
import cx from 'classnames';

class Wrapper extends Component {
	render() {
		const { className, children } = this.props
		
		return (
			<div className={cx('wrapper innerAppWrapper', className)}>
				{children}
			</div>
		);
	}
}

export default Wrapper;
