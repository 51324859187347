/**
 * External dependencies
 */
 import React, {Component} from 'react';
 import cx from 'classnames';
 
 class RadioForProducts extends Component {
   handleChange = e => {
	 this.props.onChange(
	   e.target.name,
	   this.props.trueFalseMode
		 ? e.target.checked
		   ? this.props.trueRepresentative ? this.props.trueRepresentative : true
		   : false
		 : e.target.value,
	 );
   };
 
   render() {
	 let {
	   name,
	   id,
	   text,
	   value,
	 
 
	   defaultChecked,
	   disabled,
	   className,
	    
	   type,
	 
	 } = this.props;
	 
	 return (
	   <div
		 className={cx('radio', {
		   [`${className}`]: className,
		 })}>
		 <input
		   type={type || 'radio'}
		   name={name || id}
		   id={id}
		   disabled={disabled}
		   value={value||text}
		   onChange={this.handleChange}
		   checked={ defaultChecked}
		 />
 
		 <label
		   htmlFor={id}>
		   {text}
		 </label>
	   </div>
	 );
   }
 }
 
 export default RadioForProducts;
 