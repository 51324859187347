const postData = async   function(url = '', data = {}, showError) {
  // Default options are marked with *
  let request_obj = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  };
  try {
    const response = await fetch(url, request_obj)
    return response.json(); // parses JSON response into native JavaScript objects
  } catch(err) {
    console.log(err);
    if(showError)
    {
      return '_NO_INTERNET_|'+err.message;
      
    }else{
      return '_NO_INTERNET_';
    }
    
  }
}


const getDataText = async   function(url = '', data = {}, forceFail) {
  if(forceFail)
  {
    return '_NO_INTERNET_';
  }
  // Default options are marked with *
  let header = {
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  };
  let auth =localStorage.getItem('auth_token');
  if(auth)
  {
    header['Authorization'] = auth;
  }
  let request_obj= {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: header,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };
  try {
    const response = await fetch(url, request_obj)
    return response.text(); // parses JSON response into native JavaScript objects
  } catch(err) {
    return '_NO_INTERNET_';
  }
}

const getData = async   function(url = '', data = {}, forceFail, useAgentToken) {
  if(forceFail)
  {
    return '_NO_INTERNET_';
  }
  // Default options are marked with *
  let header = {
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  };
  let auth =localStorage.getItem(useAgentToken ? 'agent_token' : 'auth_token');
  if(auth)
  {
    header['Authorization'] = auth;
  }
  let request_obj= {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: header,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };
  try {
    const response = await fetch(url, request_obj)
    return response.json(); // parses JSON response into native JavaScript objects
  } catch(err) {
    console.log(err);
    return '_NO_INTERNET_';
  }
}

const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const funcs = {postData, getData, getDataText, uuidv4};
  export default funcs ;