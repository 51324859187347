let disable_plans = [ ];
// let disable_plans = ["1208", "1209"];
const getName = function(item)
{
    // if(item.planUnitFlag )
    // {
        if( item.planName.indexOf(item.unitName)  > -1 )
        {
            return item.planName
        }
        else if(item.unitName  == item.planName )
        {
            return item.unitName
        }
        else
        {
            return item.planName+' - '+item.unitName;
        }
    // }else
    // {
    //     return item.planName;
    // }
}
const getOverridesNew = function (
    all_products_data,
    rates,
    cur_sel,
    product,
    mode,
    for_,
    state,
    age,
    age_2,
    stepvals,
    extra,
  ) {
 
    let cur_product_id = parseInt(product.replace('product_', ''));
    let current_product = all_products_data.filter(function(item, i){
        // console.log(item.productId , cur_product_id)
        if(item.productId == cur_product_id)
            return true;
        return false;
    });
    if(!current_product.length)
    {
        alert('Failed to load product');
        return {};
    }
    current_product = current_product[0];

    let all_plans = current_product.plans;
    let current_plan = false;
    let total  = 0;

    let plans = all_plans.filter(function(item, i){
        if( disable_plans.indexOf( ""+item.planUnitId) > -1)
            return false;
        return true;
    }).map(function(item, i){
        
        let this_is_current = false;
        if(product+'|planUnit'+item.planUnitId == cur_sel)
        {
            current_plan = item;
            this_is_current = true;
        }
        // if(!rates['p_'+item.planUnitId])
        //         return false;
        if(this_is_current)
        {
            total+= rates['p_'+item.planUnitId] ? parseFloat(rates['p_'+item.planUnitId]).toFixed(2)  :  0;
        }
        return {
            id : product+'|planUnit'+item.planUnitId,
            text: getName(item),
            features:[],
            price : rates['p_'+item.planUnitId] ? parseFloat(rates['p_'+item.planUnitId]).toFixed(2)  :  '0',
            // price:(10).toFixed(2),
            
        }
    });

    plans = plans.filter(function(item){
        // console.log(item, current_plan)
        if(item == false)return false;return true
    });


 let objs_opts = [ {
    id: 'radio-insurance1',
    text: 'Self',
    isChecked: false,
  },
  {
    id: 'radio-insurance2',
    text: 'Self & Child',
    isChecked: false,
  },
  {
    id: 'radio-insurance3',
    text: 'Self & Spouse',
    isChecked: false,
  }];

  if(current_product.productId == 138)
  {
    objs_opts.push({
        id: 'radio-insurance5',
        text: 'Single Parent Family',
        isChecked: false,
      } );
  }



  objs_opts.push({
    id: 'radio-insurance4',
    text: 'Family',
    isChecked: false,
  });


  if( age  < 18 && current_product.productId == 167  )
  {

    objs_opts = [ {
        id: 'radio-insurance1',
        text: 'Self',
        isChecked: false,
    }];
  }
//   this.context.stepValues["dob_year"]


    let return_obj =  {
        productName : current_product.productName,
        radios: [
            {
                name: product+'_radio',
                isRequired: true,
                validationMessage: 'Radio is required',
                values: objs_opts
            },
        ],
        radiosAlt: [
            {
                name: product+'_plan',
                isRequired: true,
                validationMessage: 'Radio is required',
                values: plans
            }
        ]
    }
    

    // console.log('----------------------------------------------------------------------------');
    // console.log(cur_sel, state, product);
    if(current_plan && current_plan.riders && current_plan.riders.length  && (

        product != 'product_138'  || ( state != 'MI' && state != 'CA' && state != 'MO' && state != 'PA')

    ))
    {
        current_plan.riders.map((item, i)=>{

            let nm_txt = (item.riderName).toLowerCase() +  (item.riderName == item.unitName ? '' :  (' - ' + (item.unitName||''))).toLowerCase();
            let nm_id = product+'|planUnit'+current_plan.planUnitId+'|rider'+item.riderUnitId;
            if(rates['r_'+item.riderUnitId] && 
                (
                    stepvals[ product+'_rider_'+(i+1) ] == nm_txt || 
                    stepvals[ product+'_rider_'+(i+1) ] == nm_id
                )
            )
            {
                total = parseFloat(total)+ parseFloat(rates['r_'+item.riderUnitId]);
            }
            return_obj.radiosAlt.push({
                name: product+'_rider_'+(i+1),
                title : 'Select Riders',
                class:'riders_h1',
                showTitle : i==0,
                isRequired: item.riderType === "Optional" ? false : true,
                validationMessage: 'Radio is required',
                values: [{
                    id : nm_id,
                    forceType : 'checkbox',
                    text: nm_txt,
                    // features:[],
                    price:rates['r_'+item.riderUnitId]? parseFloat(rates['r_'+item.riderUnitId]) .toFixed(2) : '0',
                    
                }]
            });
        })
        return_obj.total =parseFloat(total).toFixed(2);  
    }

    return return_obj;
}

export default getOverridesNew;
