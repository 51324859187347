/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * External dependencies
 */
 import React, {Component} from 'react';

 /**
  * Provider
  */
 import AppContext from '../../app-provider';
 
 class Progress extends Component {
   renderEditMode() {
	 let f = window.localStorage.getItem('editMode');
	 if (f === 'yes') {
	   //
	 } else {
	   return false;
	 }
	 return (
	   <div className="edit_mode_banner">
		 <div className="shell">
		   Application EDIT MODE
		   <a
			 className="#"
			 onClick={e => {
			   e.preventDefault();
			   window.localStorage.setItem('editMode', '');
			   window.localStorage.setItem('using_agent', '');
			   window.localStorage.setItem('step_data5', '');
			   window.localStorage.setItem('unique_identifier', '');
			   window.location.href = '';
			 }}>
			 Exit
		   </a>
		 </div>
	   </div>
	 );
   }
   render() {
	 const {index, noBack, showingCounts} = this.props;
	 const stepsLength = this.context.steps.length;
	 const progress = parseInt((index / stepsLength) * 100) + '%';
 
	 return (
	   <div className="progress">
		 <div
		   className={
			 'progress__head ' +
			 (this.props.showCart ? 'progresshowingcart' : '')
		   }>
		   {/* {
						 noBack ?  <button style={{display:'none'}} />:	<button className="progress__btn" onClick={ this.props.goBack}>
							 <i className="ico-angle-left"></i>
						 </button>	
					 } */}
		   <p>&nbsp;</p>
		   {showingCounts ? (
			 <p className="absp">
			   {index || 1}/{stepsLength}
			 </p>
		   ) : (
			 false
		   )}
		 </div>
 
		 <div
		   className="progress__bar"
		   onClick={e => {
			 this.props.onClickProgress && this.props.onClickProgress(e);
		   }}>
		   <span style={{width: progress}}></span>
		 </div>
 
		 {this.renderEditMode()}
	   </div>
	 );
   }
 }
 
 Progress.contextType = AppContext;
 
 export default Progress;
 