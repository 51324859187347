import React from 'react';

const FinalMsg = props => (
  <div className="widget-message">
    <p>
      By Clicking Submit above, you agree that all information you provided was
      accurate and you have read information provided @{' '}
      <a href="https://www.manhattanlife.com/disclosure">
        https://www.manhattanlife.com/disclosure;
      </a>{' '}
      which includes Insured's Right to Examine Policy, Guaranteed Renewable for
      Life informaiton, Limitations and Exclusions, Thirty Day to Return
      overview, Pre-Existing Conditions & Termination information listed in your
      policy. You also agree that you have had time to review your policy, and
      any questions you might have, were answered. Submitting application also
      provides Agreement to policy MIB Language.{' '}
    </p>

    <p>
      Should have any questions during enrollment,
      <br />
      please call{' '}
      <span>
        <a href="tel:8007523419">800-752-3419</a>
      </span>
    </p>
  </div>
);
const Hints = ({hints}) => {
  if (!hints) return false;
  return (
    <>
      {hints.map((hint, index) => {
        return (
          <p key={index} className="form__hint">
            {hint}
          </p>
        );
      })}
    </>
  );
};
const SmallComps = {
  FinalMsg: FinalMsg,
  Hints: Hints,
};
export default SmallComps;
