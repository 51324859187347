/**
 * External dependencies
 */
import React, { Component } from 'react';

class Field extends Component {
	handleChange = (e) => {
		this.props.onChange( e.target.id, e.target.checked )
	}

	render() {
		const { id, text , checked} = this.props;

		return (
			<div className="checkbox-alt">
				<input 
					type="checkbox" 
					id={id}
					checked={checked}
					// defaultChecked={checked}
					onChange={this.handleChange} 
				/>

				<label htmlFor={id}>
					{text}
				</label>
			</div>
		);
	}
}

export default Field;