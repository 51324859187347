
const accident_individual_family = require('./accident_individual_family.json');
const accident_individual_self = require('./accident_individual_self.json');
const accident_individual_self_child = require('./accident_individual_self_child.json');
const accident_individual_self_spouse = require('./accident_individual_self_spouse.json');
const accident_payroll_family = require('./accident_payroll_family.json');
const accident_payroll_self = require('./accident_payroll_self.json');
const accident_payroll_self_child = require('./accident_payroll_self_child.json');
const accident_payroll_self_spouse = require('./accident_payroll_self_spouse.json');

const ccp_individual_family = require('./ccp_individual_family.json');
const ccp_individual_self = require('./ccp_individual_self.json');
const ccp_individual_self_child = require('./ccp_individual_self_child.json');
const ccp_individual_self_spouse = require('./ccp_individual_self_spouse.json');
const ccp_payroll_family = require('./ccp_payroll_family.json');
const ccp_payroll_self = require('./ccp_payroll_self.json');
const ccp_payroll_self_child = require('./ccp_payroll_self_child.json');
const ccp_payroll_self_spouse = require('./ccp_payroll_self_spouse.json');


const affordable_individual_family = require('./affordable_individual_family.json');
const affordable_individual_self = require('./affordable_individual_self.json');
const affordable_individual_self_child = require('./affordable_individual_self_child.json');
const affordable_individual_self_spouse = require('./affordable_individual_self_spouse.json');
const affordable_payroll_family = require('./affordable_payroll_family.json');
const affordable_payroll_self = require('./affordable_payroll_self.json');
const affordable_payroll_self_child = require('./affordable_payroll_self_child.json');
const affordable_payroll_self_spouse = require('./affordable_payroll_self_spouse.json');

const fl_affordable_individual_family = require('./affordable_fl/fl_affordable_individual_family.json');
const fl_affordable_individual_self = require('./affordable_fl/fl_affordable_individual_self.json');
const fl_affordable_individual_self_child = require('./affordable_fl/fl_affordable_individual_self_child.json');
const fl_affordable_individual_self_spouse = require('./affordable_fl/fl_affordable_individual_self_spouse.json');
const fl_affordable_payroll_family = require('./affordable_fl/fl_affordable_payroll_family.json');
const fl_affordable_payroll_self = require('./affordable_fl/fl_affordable_payroll_self.json');
const fl_affordable_payroll_self_child = require('./affordable_fl/fl_affordable_payroll_self_child.json');
const fl_affordable_payroll_self_spouse = require('./affordable_fl/fl_affordable_payroll_self_spouse.json');


const fl_ccp_individual_family = require('./ccp_fl/ccp_individual_family.json');
const fl_ccp_individual_self = require('./ccp_fl/ccp_individual_self.json');
const fl_ccp_individual_self_child = require('./ccp_fl/ccp_individual_self_child.json');
const fl_ccp_individual_self_spouse = require('./ccp_fl/ccp_individual_self_spouse.json');
const fl_ccp_payroll_family = require('./ccp_fl/ccp_payroll_family.json');
const fl_ccp_payroll_self = require('./ccp_fl/ccp_payroll_self.json');
const fl_ccp_payroll_self_child = require('./ccp_fl/ccp_payroll_self_child.json');
const fl_ccp_payroll_self_spouse = require('./ccp_fl/ccp_payroll_self_spouse.json');



const dvh_individual_family = require('./dvh_individual_family.json');
const dvh_individual_family_big = require('./dvh_individual_family_big.json');
const dvh_individual_self = require('./dvh_individual_self.json');
const dvh_individual_self_child = require('./dvh_individual_self_child.json');
const dvh_individual_self_spouse = require('./dvh_individual_self_spouse.json');
const dvh_payroll_family = require('./dvh_payroll_family.json');
const dvh_payroll_family_big = require('./dvh_payroll_family_big.json');
const dvh_payroll_self = require('./dvh_payroll_self.json');
const dvh_payroll_self_child = require('./dvh_payroll_self_child.json');
const dvh_payroll_self_spouse = require('./dvh_payroll_self_spouse.json');


// const hhc_individual_family = require('./hhc_individual_family.json');
// const hhc_individual_self = require('./hhc_individual_self.json');
// const hhc_individual_self_child = require('./hhc_individual_self_child.json');
// const hhc_individual_self_spouse = require('./hhc_individual_self_spouse.json');
// const hhc_payroll_family = require('./hhc_payroll_family.json');
// const hhc_payroll_self = require('./hhc_payroll_self.json');
// const hhc_payroll_self_child = require('./hhc_payroll_self_child.json');
// const hhc_payroll_self_spouse = require('./hhc_payroll_self_spouse.json');

let rates = {
    affordable:{
        individual : {
            self : affordable_individual_self,
            self_spouse: affordable_individual_self_spouse,
            self_child:affordable_individual_self_child,
            family: affordable_individual_family,
        },
        payroll: {
            self : affordable_payroll_self,
            self_spouse:affordable_payroll_self_spouse ,
            self_child:affordable_payroll_self_child,
            family: affordable_payroll_family,
        },
    },    affordablefl:{
        individual : {
            self : fl_affordable_individual_self,
            self_spouse: fl_affordable_individual_self_spouse,
            self_child:fl_affordable_individual_self_child,
            family: fl_affordable_individual_family,
        },
        payroll: {
            self : fl_affordable_payroll_self,
            self_spouse:fl_affordable_payroll_self_spouse ,
            self_child:fl_affordable_payroll_self_child,
            family: fl_affordable_payroll_family,
        },
    },

 
    accident:{
        individual : {
            self : accident_individual_self,
            self_spouse: accident_individual_self_spouse,
            self_child:accident_individual_self_child,
            family: accident_individual_family,
        },
        payroll: {
            self : accident_payroll_self,
            self_spouse:accident_payroll_self_spouse ,
            self_child:accident_payroll_self_child,
            family: accident_payroll_family,
        },
    },
    ccp:{
        individual : {
            self : ccp_individual_self,
            self_spouse: ccp_individual_self_spouse,
            self_child:ccp_individual_self_child,
            family: ccp_individual_family,
        },
        payroll: {
            self : ccp_payroll_self,
            self_spouse: ccp_payroll_self_spouse,
            self_child:ccp_payroll_self_child,
            family: ccp_payroll_family,
        },
    },
    ccpfl:{
        individual : {
            self : fl_ccp_individual_self,
            self_spouse: fl_ccp_individual_self_spouse,
            self_child:fl_ccp_individual_self_child,
            family: fl_ccp_individual_family,
        },
        payroll: {
            self : fl_ccp_payroll_self,
            self_spouse: fl_ccp_payroll_self_spouse,
            self_child:fl_ccp_payroll_self_child,
            family: fl_ccp_payroll_family,
        },
    },
    dvh:{
        individual : {
            self : dvh_individual_self,
            self_spouse: dvh_individual_self_spouse,
            self_child:dvh_individual_self_child,
            family: dvh_individual_family,
            family_big: dvh_individual_family_big,
        },
        payroll: {
            self : dvh_payroll_self,
            self_spouse: dvh_payroll_self_spouse,
            self_child:dvh_payroll_self_child,
            family: dvh_payroll_family,
            family_big: dvh_payroll_family_big,
        },
    },
    // hhc:{
    //     individual : {
    //         self : hhc_individual_self,
    //         self_spouse: hhc_individual_self_spouse,
    //         self_child:hhc_individual_self_child,
    //         family: hhc_individual_family
    //     },
    //     payroll: {
    //         self : hhc_payroll_self,
    //         self_spouse: hhc_payroll_self_spouse,
    //         self_child:hhc_payroll_self_child,
    //         family: hhc_payroll_family
    //     },
    // }
}

export default rates;