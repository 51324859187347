
const constants = {
    api_url:   'https://app.mhlez.com/api/', // change here

    product_age_range : {
      "p_167":{min:0, max:64}, // affordable choice 
      'p_138':{min:18, max:64}, // accident
      'p_300':{min:18, max:64}, // hhc
      'p_9':{min:18, max:69},// cancer
      'p_301':{min:18, max:99}, // dvh
    } ,


    plan_names: {
      cancer_insurance: "Cancer Insurance",
      cancer_insurance_fob: "Cancer Insurance FOB",
      cancer_insurance_cp4000: "Cancer Insurance CP4000",
      home_health_care: "Home Health Care",
      cancer_insurance_cp4000_ppl: "Cancer Insurance CP4000 - PPL",
      first_occurrence_cancer: "First Occurrence Cancer",
      cancer_heart_stroke: "Cancer Heart Stroke",
      accident_insurance: "Accident Insurance",
      std_manhattan_life: "STD Manhattan Life",
      accident_paid: "Accident Insurance - Paid",
      accident_express: "Accident Insurance - Express",
      affordable_choice: "Affordable Choice",
      dental_vision_hearing_insurance: "Dental Vision Hearing Insurance",
      dental_dvh_select : "DVH Select",
      cpr: 'CPR  - Critical Illness Protection and Recovery',
      out_of_pocket:"Out of Pocket Expense",
      first_choice_victory: "First Choice Victory Series"
    },
    override_list: [
      'product_9',
      'product_138',
      'product_167',
      'product_301',
      // "cancer_insurance_cp4000",
      // "cancer_insurance_cp4000_ppl",
      // "home_health_care",
      // "accident_express",
      // 'accident_paid',
      // 'cpr',
      // "std_manhattan_life",
      // "cancer_insurance_fob",
      // "out_of_pocket",
      // "dental_vision_hearing_insurance",
      // "out_of_pocket",
      // "first_choice_victory",
      // "dental_dvh_select",
    ],
    plans : {
      "cancer_insurance_cp4000" : ['Plan A', "Plan B w $50" , "Plan B w $100" , "Plan C" , "Plan D" ],
      "cancer_insurance_cp4000_ppl" : ['PPL1', "PLL2 - 50$ Wellness" , "PLL2 - 100$ Wellness" , "PPL3 - 100$ Wellness" , "PPL3 - 125$ Wellness",
        "PPL3 - 150$ Wellness","PPL4 - 100$ Wellness","PPL4 - 125$ Wellness","PPL4 - 150$ Wellness", ],
      "home_health_care": [ "Classic", "Classic + EBR", "Deluxe", "Deluxe + EBR" ],
      "accident_express" : [ "Low", "High" ],
      "accident_paid" : [ "Low", "High" ],
      "cpr": [ "Plan 1", "Plan 2", "Plan 3", "Plan 4",  ],
      "cancer_insurance_fob": [ "5,000","10,000","20,000","30,000","40,000","50,000" ],
      "dental_vision_hearing_insurance" : [ "$1,000", "$1,500", "$3000" ],
      "dental_dvh_select": [
        "deductible_$100 Deductible",
        "deductible_$0 Deductible"
      ],
      "out_of_pocket": [
        //
      ]
    }

  };
  export default constants;
  