/**
 * External dependencies.
 */
import React, { Component }  from 'react';
import cx from 'classnames';
import Cart from './cart';
import ScheduleSelection from './ScheduleSelection';
class Section extends Component {
	render() {
		const { className, children ,noPaddingTop, extraClass, showNextButton, slideOut,noPaddingBottom, goToRight, slideIn, slideOutOpposite,
			goToLeft, routeParams, slideInOpposite} = this.props;
			if(showNextButton)
			{
				return (<section style={noPaddingBottom ? noPaddingTop ? {paddingTop:0, paddingBottom: 0} : {paddingBottom: 0} : noPaddingTop ? {paddingTop:0}:{}} className={ 
				
					(slideOutOpposite ? ' slideOutOpposite ': ' ' )+  '  ' +
					(goToLeft ? ' goToLeft ': ' ' )+  '  ' +
					(slideInOpposite ? ' slideInOpposite ': ' ' )+  '  ' +
					
					
					(slideOut ? ' slideOut ': ' ' )+  '  ' +(goToRight ? ' goToRight ': ' ' )+  '  ' +(slideIn ? ' slideIn ': ' ' )+  '  ' +cx('section', className)}>
						<Cart routeParams={routeParams} cartDataChange={this.props.cartDataChange} onChange={this.props.cartChange} onBack={this.props.onBack} cartData={this.props.cartData} />
						{
							this.props.noShell ? children : <div className="shell">
							{children}
						</div>
						}
					
				</section>);
			}
			if(this.props.showCart )
			{
				return <Cart routeParams={routeParams}  cartDataChange={this.props.cartDataChange} onChange={this.props.cartChange} onBack={this.props.onBack} cartData={this.props.cartData} />
			}
			if(this.props.showScheduleSelection)
			{
				return <ScheduleSelection onBack={this.props.onBack} onScheduleChange={this.props.onScheduleChange} cartData={this.props.cartData} />
				
			}
		return (
			<section style={noPaddingBottom ? noPaddingTop ? {paddingTop:0, paddingBottom: 0} : {paddingBottom: 0} : noPaddingTop ? {paddingTop:0}:{}} className={ 
				((extraClass||'') ? ' '+extraClass+ ' ' : ' ') + 
				(slideOutOpposite ? ' slideOutOpposite ': ' ' )+  '  ' +
				(goToLeft ? ' goToLeft ': ' ' )+  '  ' +
				(slideInOpposite ? ' slideInOpposite ': ' ' )+  '  ' +
				
				
				(slideOut ? ' slideOut ': ' ' )+  '  ' +(goToRight ? ' goToRight ': ' ' )+  '  ' +(slideIn ? ' slideIn ': ' ' )+  '  ' +cx('section', className)}>

					{
						this.props.noShell ? children : <div className="shell">
						{children}
					</div>
					}
				
			</section>
		);
	}
}

const SectionBar = ({ children }) => (
	<div className="section__bar">
		{children}
	</div>
);

const SectionHead = ({ children, step, cls }) => (
	<header className={"section__head head_stepp_"+step + ' '+cls}>
		{children}
	</header>
);

const SectionBody = ({ children, step, cls }) => (
	<div className={"section__body stepp_"+step  +' '+cls} >
		{children}
	</div>
);

const SectionActions = ({ children }) => (
	<div className="section__actions">
		{children}
	</div>
);

const SectionFoot = ({ children, className }) => (
	<div className={"section__foot " + (className || '')}>
		{children}
	</div>
);

Section.Head = SectionHead;
Section.Body = SectionBody;
Section.Foot = SectionFoot;
Section.Bar  = SectionBar;
Section.Actions  = SectionActions;

export default Section;
