
import React, {Component} from 'react';
 import Field from './field';
 import Button from '../../components/button/button';
 
 
class BenefRepeater extends Component {


state = {len:1}

    componentDidMount(){
        let {value} = this.props;
        let len = 0 ;
        if(!value)
            len = 1;
        else 
            len = value.length;
        this.setState({
            len : len
        })
    }
    componentDidUpdate(prevprops, prevState)
    {
        let {value} = this.props;
        let len = 0 ;
        if(!value)
            len = 1;
        else
            len = value.length;
        if (len !== this.state.len) {
            this.setState({
                len : len
            })
         }
    }

    changeVal(k, v, i){
        let {value} = this.props;
        if(!value)
            value=  [{}];
        value = JSON.parse(JSON.stringify(value));
        value[i][k] =v;
        // console.log(this.props.name, value)
        this.props.onChange(value, this.props.name, false, 'yes');
    }

    handleChange(){
        //
    }




    render(){

        let {value} = this.props;
        let {len} = this.state;

        // console.log(len)
        
        return (
            <div>
                {
                    len ? 
                    <div>
                        {[...Array(len)].map((item, i)=>{
                            return (
                                <div className='form__row benefRowSet'>
                                     <div className='form__col form__col--1of3 altsize3 nomr'>
                                        <Field
                                            name={'benef_first'}
                                            label={'First Name'}
                                            fixedLabel
                                            value={ (value[i] ? value[i].benef_first : '')}
                                            onChange={(k,v)=>{
                                                this.changeVal(k, v, i);
                                            }}
                                        />
                                    </div>
                                    <div className='form__col form__col--1of3 altsize3 nomr ismiddle'>
                                        <Field
                                            name={'benef_last'}
                                            label={'Last Name'}
                                            fixedLabel
                                            value={ (value[i] ? value[i].benef_last : '')}
                                            onChange={(k,v)=>{
                                                this.changeVal(k, v, i);
                                            }}
                                        />
                                    </div>
                                    <div className='form__col form__col--1of3 altsize2 nomr'>
                                        <Field
                                            name={'benefrelation'}
                                            type="select"
                                            options={["Parent", "Spouse", "Child", "GrandChild", "GrandParent", "Nephew", "Niece", "Brother", "Sister", "Aunt", "Uncle", "Other", "Self"]}
                                            label={'Relationship'}
                                            value={ (value[i] ? value[i].benefrelation : '')}
                                            onChange={(k,v)=>{
                                                this.changeVal(k, v, i);
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : false
                }
                <div className="form__actions mb5" style={{display:'flex', 'justifyContent':'space-between',marginTop:10,marginBottom:5}} >
                    <Button
                          color="border"
                          type="button"
                          size="small"
                          onClick={(e)=>{

                            let {value} = this.props;
                            if(!value)
                                value=  [{}];
                            value = JSON.parse(JSON.stringify(value));
                            value.push({});
                            this.props.onChange(value, this.props.name, false, 'yes');
                            // this.setState({len : len+1});
                          }}>
                          <i className="ico-plus"></i>
                          Add Beneficiary
                        </Button>{' '}
                        {
                            len > 1 ? <Button
                            color="border"
                            type="button" style={{'min-width':'auto'}}
                            size="small"
                            onClick={(e)=>{
  
                              let {value} = this.props;
                              if(!value)
                                  value=  [{}];
                              value = JSON.parse(JSON.stringify(value));
                              value.pop();
                              this.props.onChange(value, this.props.name, false, 'yes');
                            }}>
                            {/* <i className="ico-minus"></i> */}
                            Remove last row
                          </Button>  :false
                        }
                       
                      </div>


            </div>
        );
    }

}

export default BenefRepeater;