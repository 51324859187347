/**
 * External dependencies
 */
import React, { Component } from 'react';

class Field extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			  checked  :props.checked
		}
	}
	handleChange = (e) => {
		this.props.onChange( e.target.id, e.target.checked )
		this.setState({checked: !this.state.checked})
	}

	render() {
		const { id } = this.props;

		return (
			<div className="checkbox">
				<input 
					type="checkbox" 
					checked={ this.props.checked}
					id={id} 
					onChange={this.handleChange} 
				/>

				<label htmlFor={id}></label>
			</div>
		);
	}
}

export default Field;