/**
 * External dependencies
 */
import React, { Component } from 'react';
import cx from 'classnames';

class ListFeatures extends Component {
	render() {
		const { children, isSmall } = this.props;

		return (
			<ul className={cx('list-features', {['list-features--small']: isSmall})}>
				{children}				
			</ul>
		);
	}
}

export default ListFeatures;