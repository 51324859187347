/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import jquery from 'jquery';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import { SketchPicker } from "@bahouse/react-color"; 
import { GoBack } from '../components/GoBack';
let $ = jquery;

const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',right:0,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }, 
}
class Colors extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };


  componentDidMount() {
    let colors ={};
    for(let i = 1; i <= 10;i++)
    {
      colors['color_'+i] = localStorage.getItem("app_color_"+i);
      if(!colors['color_'+i])
      {
        colors['color_'+i] = '#fb6622';
      }

    }
    this.setState(colors);
  }
  
  changeColor(item, i){
    console.log(item, i)
    this.setState({
      ['color_'+item] : i.hex
    })
    localStorage.setItem("app_color_"+item, i.hex);
  }

  render() { 
    console.log(this.state)
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png')}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  App Colors
                </h1>

                <GoBack />
              </div>
            </Section.Head>
            <Section.Body>
              <div
                style={{margin: 8, background: 'none'}}
                className="colors_screen">
                  
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((item, i)=>{
                      return (
                        <div className="color_selection_box" key={item}>
                          <div className="color_selection_top">
                            <div>
                              <label>Color {item}:</label>
                            </div>
                            <div className="color_selection_top_right">
                              <button onClick={(e)=>
                              {
                                this.setState({['showingColorPicker_'+item] : true});
                                $('body').css({overflow:'hidden'})
                              }}
                              className="color_btn"
                                style={{ background: this.state['color_'+item] }}
                              ></button>
                                { this.state['showingColorPicker_'+item] ? <div style={ styles.popover }>
                                <div style={ styles.cover } onClick={(e)=>
                              {
                                $('body').css({overflow:'auto'})
                                this.setState({['showingColorPicker_'+item] : false});
                              }}/>
                              <div>
                              <div className="colorPreview"  >
                                <div style={{backgroundColor : this.state['color_'+item]}} >
                                  <div>Preview</div>
                                </div>
                                </div>
                                <SketchPicker disableAlpha  color={ this.state['color_'+item] } onChange={ ( e)=>{
                                  this.changeColor(item, e);
                                } } />
                                
                              </div>
                              </div> : null }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
              </div>
            </Section.Body>

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default Colors;
