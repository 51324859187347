
import rates from './data/cached_rates/index.js';

let PRICES = {
  
    167 : rates.affordable.individual,
    '167_payroll' : rates.affordable.payroll,

    138 : rates.accident.individual,
    '138_payroll' : rates.accident.payroll,
    
    9: rates.ccp.individual ,
    '9_payroll' : rates.ccp.payroll,

    '9_fl': rates.ccpfl.individual ,
    '9_fl_payroll' : rates.ccpfl.payroll,

    '167_fl': rates.affordablefl.individual ,
    '167_fl_payroll' : rates.affordablefl.payroll,

    301 : rates.dvh.individual,
    '301_payroll' : rates.dvh.payroll,
}

const getCachedRates = function(data, product_id, price_mode, self_mode)
{

    console.log(data);
    console.log('-------------------')

    let state = data.Quote.StateCode;
    state = (state||'').toUpperCase();
    let mode = 'self';

    if(self_mode === 'Family')
    {
        self_mode = 'family';
        mode = 'family';
    }

    if(self_mode === 'Self & Child')
    {
        self_mode = 'self_child';
        mode = 'self_child';
    }

    if(self_mode === 'Self & Spouse')
    {
        self_mode = 'self_spouse';
        mode = 'self_spouse';
    }
    if(self_mode === 'Single Parent Family')
    {
        self_mode = 'family';
        mode = 'family';
    }
    // console.log(self_mode, mode);
    let slabs = [
        [18,44,   1991,],
        [45,54,     1972,],
        [55,64,     1962,],
        [65,69,     1954,],
    ];

    let afc_slabs = [
 
        [0,17,   2023],
        [18,29,   1999],
        [30,39,   1988],
        [40,49,   1978],
        [50,64,     1965,],
        [65,69,     1954,],
    ];

    let afc_fl_slabs = [
        [1,17,2006],
        [18,18,2005],
        [19,19,2004],
        [20,20,2003],
        [21,21,2002],
        [22,22,2001],
        [23,23,2000],
        [24,24,1999],
        [25,25,1998],
        [26,26,1997],
        [27,27,1996],
        [28,28,1995],
        [29,29,1994],
        [30,30,1993],
        [31,31,1992],
        [32,32,1991],
        [33,33,1990],
        [34,34,1989],
        [35,35,1988],
        [36,36,1987],
        [37,37,1986],
        [38,38,1985],
        [39,39,1984],
        [40,40,1983],
        [41,41,1982],
        [42,42,1981],
        [43,43,1980],
        [44,44,1979],
        [45,45,1978],
        [46,46,1977],
        [47,47,1976],
        [48,48,1975],
        [49,49,1974],
        [50,50,1973],
        [51,51,1972],
        [52,52,1971],
        [53,53,1970],
        [54,54,1969],
        [55,55,1968],
        [56,56,1967],
        [57,57,1966],
        [58,58,1965],
        [59,59,1964],
        [60,60,1963],
        [61,61,1962],
        [62,62,1961],
        [63,63,1960],
        [64,64,1959],
    ];

    let ccl_fl_slabs = [
        [18,29,     1998,],
        [30,39,     1987,],
        [40,49,     1977,],
        [50,59,     1967,],
        [60,69,     1957,],
    ]

    let dvh_slabs = [
        [3,  7,     2012,],
        [18,39,     1991,],
        [40,54,     1972,],
        [55,64,     1962,],
        [65,74,     1952,],
        [75,84,     1942,],
        [85,99,     1937,],
    ];
 
    let add_money_for_extra = false;
    let included_child = 0;
    let add_extra_mode = '';
    if(product_id==='301'||product_id===301||product_id==='301_payroll')
    {
        add_extra_mode = 'family_big';
        add_money_for_extra = true;
        slabs = dvh_slabs;
        included_child = 3;
    }

    if(product_id==='167'||product_id===167||product_id==='167_payroll')
    {
        add_extra_mode = 'self';
        included_child =4;
        add_money_for_extra = true;
        slabs = afc_slabs;
        if(state == 'FL'  || state == 'fl')
        {
            product_id = '' + product_id + '_fl';
            slabs = afc_fl_slabs
        }
    }

    if(product_id == 9 || product_id == '9')
    {
        if(state == 'FL'  || state == 'fl')
        {
            product_id = '' + product_id + '_fl';
            slabs = ccl_fl_slabs
        }
    }

    if(price_mode === 'payroll')
    {
        product_id = ''+product_id+'_payroll'
    }
    let rates  = PRICES[product_id] ? PRICES[product_id]  : {};

    if(!data){
        return [];
    }

    let add_extra = 0;
    // let add_extra_group = "2012";
    // console.log(data.Dependents);
    if(!data.Dependents || data.Dependents.length === 0)
    {
        mode = 'self';
    }else{
        if(  self_mode === 'family' && data.Dependents.length > (included_child + 1)){
            add_extra = data.Dependents.length - (included_child + 1);
        }else if(  self_mode === 'self_child' && data.Dependents.length > included_child ){
            add_extra = data.Dependents.length - included_child;
        }
    }

    if(add_money_for_extra)
    {
        //
    }else{
        add_extra = 0;
    }

    console.log('add_extra', add_extra, mode)
    let rates_mode = rates[mode];

    if(!rates_mode)
    {
        rates_mode = {};
    }
    
    
    let BirthDate = data.Quote.BirthDate;
    let plans = [];
    let riders = [];
    data.SelectedPlans.map((item)=>{plans.push(item.PlanUnitId)});
    data.selectedRiders.map((item)=>{riders.push(item.riderUnitId)});
    
    let age = parseInt((new Date()).getFullYear()) - parseInt(BirthDate);
    // console.log(age);
    let avg_year_1 = 0;
    slabs.map((item)=>{
        if(age >= item[0] && age <= item[1])
        {
            avg_year_1 = item[2];
        }
        return item;
    });

    let prices = {};
   
    plans.map(item => {
        // console.log('p_'+state+'_'+avg_year_1+'_'+item)
        let rate_1 = rates_mode['p_'+state+'_'+avg_year_1+'_'+item]?rates_mode['p_'+state+'_'+avg_year_1+'_'+item]:0;
        let extra = 0
        if(add_extra > 0){
            let extra_age_group = avg_year_1;
            if(product_id==='167'||product_id===167||product_id==='167_payroll')
            {
                extra_age_group  = '2023';
            }
            let big_rate = (rates[add_extra_mode]['p_'+state+'_'+extra_age_group+'_'+item]?rates[add_extra_mode]['p_'+state+'_'+extra_age_group+'_'+item] : rate_1 ) 
            // debugger;
            // console.log('big_rate', big_rate);
            // console.log('big_rate', rate_1);
            let big_rate_diff = big_rate ;
            // console.log('big_rate_diff', big_rate_diff);
            extra  = big_rate_diff * add_extra;

            // console.log(rate_1, add_extra, extra, rates['self']['p_'+state+'_'+add_extra_group+'_'+item], 'p_'+state+'_'+add_extra_group+'_'+item, rates['self'])
        }
        prices['p_'+item] = rate_1 + extra;
        return item;
    });

    riders.map(item => {
        let extra = 0
        // console.log('r_'+state+'_'+avg_year_1+'_'+item);
        // console.log(rates_mode['r_'+state+'_'+avg_year_1+'_'+item]);
        let extra_age_group = avg_year_1;
        if(product_id==='167'||product_id===167||product_id==='167_payroll')
        {
            extra_age_group  = '2023';
        }
        let rate_1 = rates_mode['r_'+state+'_'+avg_year_1+'_'+item]?rates_mode['r_'+state+'_'+avg_year_1+'_'+item]:0;
        if(add_extra > 0){

            let big_rate = (rates[add_extra_mode]['r_'+state+'_'+extra_age_group+'_'+item]?rates[add_extra_mode]['r_'+state+'_'+extra_age_group+'_'+item] : rate_1);
            let big_rate_diff = big_rate ;
            extra  = big_rate_diff * add_extra;
        }
        prices['r_'+item] = rate_1 + extra;
        return item;
    });

    // console.log(prices);
    //debugger;
    return prices;
    
}

export default getCachedRates;