/**
 * External dependencies
 */
import React, { Component } from 'react';

/**
 * Layouts
 */
import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';

/**
 * Components 
 */
import Progress from '../components/progress/progress';
import Button from '../components/button/button';
import Field from '../components/field/field';

/**
 * Provider
 */
import AppContext from '../app-provider';

class Info extends Component {
	handleChange = (name, value) => {
		let formValues = this.context.formValues;

		formValues[name] = value;

		this.context.handleChangeFormValues(formValues)
	}

	handleSubmit = (e) => {
		e.preventDefault();
		
		// console.log(this.context.formValues);
	}

	render() {
		return (
			<Wrapper>
				<Section className="section--info">
					<Section.Bar>
						<Progress 
							index="1"
						/>
					</Section.Bar>

					<Section.Head>
						<h1>Let’s start with the basics</h1>

						<p>We’ll use this info to Match the best plans available.</p>
					</Section.Head>

					<Section.Body>
						<div className="form">
							<form action="?" method="post" onSubmit={this.handleSubmit}>
								<div className="form__row">
									<div className="form__col form__col--1of2">
										<Field
											isValid={false}
											name="first_name"
											label="First Name"
											placeholder="First Name"
											value={this.context.formValues["first_name"]}
											onChange={this.handleChange}
										/>
									</div>

									<div className="form__col form__col--1of2">
										<Field
											isValid={false}
											name="last_name"
											label="Last Name"
											placeholder="Last Name"
											value={this.context.formValues["last_name"]}
											onChange={this.handleChange}
										/>
									</div>
									
									<div className="form__col">
										<Field
											isValid={false}
											name="employer"
											label="Employer"
											placeholder="Employer"
											value={this.context.formValues["employer"]}
											onChange={this.handleChange}
										/>
									</div>
								</div>

								<div className="form__row form__row--primary">
									<div className="form__col">
										<div className="form__controls">
											<h5>
												Date of Birth

												<i className="ico-calendar"></i>
											</h5>
										</div>

										<Field
											isValid={false}
											name="dob_date"
											label="DD"
											placeholder="DD"
											value={this.context.formValues["dob_date"]}
											onChange={this.handleChange}
										/>

										<Field
											isValid={false}
											name="dob_month"
											label="MM"
											placeholder="MM"
											value={this.context.formValues["dob_month"]}
											onChange={this.handleChange}
										/>

										<Field
											isValid={false}
											name="dob_year"
											label="YYYY"
											placeholder="YYYY"
											value={this.context.formValues["dob_year"]}
											onChange={this.handleChange}
										/>
									</div>
								</div>
							</form>
						</div>
					</Section.Body>

					<Section.Actions>
						<Button.Link
							to="/steps/1"
							size="large"
							color="gray"
						>
							Let's Begin

							<i className="ico-angle-right-white"></i>
						</Button.Link>
					</Section.Actions>
				</Section>
			</Wrapper>
		)
	}
}

Info.contextType = AppContext

export default Info;