/**
 * External dependencies
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Browser } from '@capacitor/browser';
import postData from "../components/poster";
import constants from "../components/constants";

/**
 * Layouts
 */
import Wrapper from "../layouts/wrapper";
import Header from "../layouts/header";
import Section from "../layouts/section";

/**
 * Components
 */
// import Progress from '../components/progress/progress';
import Button from "../components/button/button";
import Menu from "./menu";
const url = constants.api_url;

const onlyFirstname = (item) =>{
  if(!item) item = '-';
  item = item.split(' ');
  // if(item.length < 2)
  //   return item[0];
  return item[0];
}
 class Home extends Component {
  state = { pendingUploadsCount: 0, showingAnimation : true, usingTemplateObj: {}, agent_info: {} , hideDropdown : true};
  getSubmitLabel(lbl) {
    let id = localStorage.getItem("unique_identifier");
    if (id) {
      return "Continue";
    } else {
      return lbl;
    }
  }


  deleteTemplate(id) {
    if (window.confirm("Are you sure you want to delete this template?")) {
      postData
        .getData(url + "listSplitsAgent.php?delete_id_app=" + id)
        .then(data => {
          window.swal('Success', "Template deleted successfully.", 'success');
          this.loadTemplates();
        });
    }
  }

  componentDidMount() {
    let pending_updates = localStorage.getItem("pending_updates");
    pending_updates = JSON.parse(pending_updates || "[]");

    let allowedSides = localStorage.getItem("allowedSides");
    if (!allowedSides) allowedSides = "both";

    let obj  = {};

    if (pending_updates && pending_updates.length > 0) {
      obj = {
        pendingUploadsCount: pending_updates.length,
        allowedSides: allowedSides,
      }
    } else {
      obj = {
        allowedSides: 'both',
      }
    }
    setTimeout( () => {
      this.setState({
        showingAnimation : false
      })
    },3000)
    
    let agent_info = localStorage.getItem("agent_info");
    
    agent_info = JSON.parse(agent_info || "{}");
    
    obj.agent_info = agent_info;

    this.setState(obj, this.loadTemplates);
  }



  loadTemplates() {
    this.setState({
      loaded: false,
    });
    let agent_info = localStorage.getItem("agent_info");
    agent_info = JSON.parse(agent_info || "{}");

    if (!agent_info) return;
    postData
      .getData(url + "listSplitsAgent.php?for_agent=" + agent_info.id)
      .then(data => {
        data.list = data.list.map((item, i)=>{
          item.templateFlag = Math.random();
          return item;
        })
        localStorage.setItem(
          "orange_agentTemplates",
          JSON.stringify(data.list),
        );
        this.loadData();
      })
      .catch(err => {
        this.loadData();
      });
  }
  loadData() {
    let id = localStorage.getItem("orange_usingTemplateId");
    let name = localStorage.getItem("orange_usingTemplate");
    let templates = localStorage.getItem("orange_agentTemplates");
    let objt = {};
    templates = JSON.parse(templates || "[]") || [];
 
    if (id == "default") {
      objt = [{
        template_name:'default',
        printed_agent_name: this.state.agent_info.name,
        agent_no: this.state.agent_info.agent_number
      }]
    } else {
      objt = templates.filter((item) =>
        "template_" + item.id == id ? true : false
      );
    }
    let obj = {
      usingTemplate: name,
      usingTemplateId: id,
      usingTemplateObj: objt[0],
      templates: templates,
    };

    console.log(objt);
    this.setState(obj);
  }

  changeTemplate1(e) {
    e.preventDefault();
    this.setState({
      changingTemplate: true,
    });
  }

  changeTemplate2(e, obja) {
    let id = e;
    let obj = {
      changingTemplate: false,
      usingTemplateObj : obja,
      menushowing:false
    };
    if (id == "default") {
      obj.usingTemplate = "default";
      obj.usingTemplateId = "default";
    } else {
      obj.usingTemplateId = id;
      this.state.templates.map((item, i) => {
        if ("template_" + item.id == id) {
          obj.usingTemplate = item.template_name;
        }
      });
    }
    this.setState(obj);
    localStorage.setItem("orange_usingTemplateId", id);
    localStorage.setItem("orange_usingTemplate", obj.usingTemplate);
    localStorage.setItem("orange_usingTemplateFlag", obja.templateFlag);
    
  }

  toggleOptions() {
    let allowedSides = localStorage.getItem("allowedSides");
    if (!allowedSides) allowedSides = "both";

    if (allowedSides === "both") {
      allowedSides = "left";
    } else if (allowedSides === "left") {
      allowedSides = "right";
    } else if (allowedSides === "right") {
      allowedSides = "both";
    }
    localStorage.setItem("allowedSides", allowedSides);
    this.setState({
      allowedSides: allowedSides,
    });
  }


  renderAgentConfigList(mode, selected) {
     
    if (mode === "list") {
      if (this.state.templates) {
        return (
          <div className="tempalteListWrap">
            <span>Select Template</span>
            <ul className="templateList">
              <li
                className={
                  selected == "default"
                    ? " selected templatechooser"
                    : "templatechooser"
                }>
                  <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                  
                <a
                  href="#"
                  className="isFirst"
                  onClick={e => {
                    e.preventDefault();
                    this.changeTemplate2('default', {
                      template_name:'default',
                      printed_agent_name: this.state.agent_info.name,
                      agent_no: this.state.agent_info.agent_number,
                      templateFlag : 'default'
                    });
                  }}>
                  {" "}
                  {/* <strong> */}
                    {/* <i>Default</i> */}
                    <span className="metas">
                      <i>
                    {this.state.agent_info ? this.state.agent_info.name : ''} - {this.state.agent_info ? this.state.agent_info.agent_number : ''}
                    </i>
                  </span>
                  {/* </strong> */}
                </a>
              </li>
              {this.state.templates.map((item, i) => (
                <li
                  key={item.id}
                  className={
                    selected == "template_" + item.id
                      ? " selected templatechooser"
                      : " templatechooser"
                  }>
                    <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                  <a
                    href="#"
                    className="isFirst"
                    onClick={e => {
                      e.preventDefault();
                      this.changeTemplate2("template_" + item.id, item);
                    }}>
                    <strong>
                      {item.template_name}
                      <span className="meta">
                        {item.printed_agent_name} - {item.agent_no}
                      </span>
                    </strong>
                  </a>
             
                  <Link className="actionitem" to={"/edit-template/" + item.id}>Edit
                  </Link>
                </li>

                // <li key={item.id} className={selected == 'template_'+item.id ? " selected ": ""} value={item.id} onClick={()=>{
                //   this.setAgentConfigName('template_'+item.id);
                // }}><span>
                //   {item.template_name}</span>
                // </li>
              ))}
            </ul>
          </div>
        );
      }
    }
  }


  render() {
    let agent = this.props.match.params.agent;
    let allowedSides = this.state.allowedSides;
    let { usingTemplateObj} = this.state;
    if(!usingTemplateObj)
    usingTemplateObj = {};

    return (
      <Wrapper className="homewrapper">
        <div class="side-img-wrap" style={{    position: 'absolute',right: 30,maxWidth: 80,top: 30, zIndex:1}}>
                <img src={require("../assets/images/new_home/side-img.png")} alt="" />
            </div>
            <div style={{zIndex:2, position:'relative'}}>
        <div className="is_header_right_top">
          <button
            className="toggle_box_badge"
            onClick={this.toggleOptions.bind(this)}
          >
            ACH / EMP
          </button>
          {/* 
          <div className="homeavataricon">
          <a
              style={{ position: 'relative' }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  menuVisible: true,
                });
              }}
            >
              <img
                alt=""
                src={require('../assets/new_icons/avatar_new.gif')}
              />
            </a>
          </div> */}
          <small className="  homeavatariconchwnger">
            <span class="form-group homedropparent">
              <span class="homeavataricon">
                <a
                  style={{ position: "relative" }}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      menuVisible: true,
                    });
                  }}
                >
                  <img
                    alt=""
                    src={require("../assets/new_icons/avatar_new.gif")}
                  />
                </a>
              </span>

              <div class="ddown">
                <button
                  class="btnDropdown"
                  onClick={(e) => {
                    this.setState({ menushowing : !this.state.menushowing  });
                  }}
                >
                  <strong>
                    {usingTemplateObj.template_name}
                  </strong>
                  <br />
                  <span class="meta">
                    {onlyFirstname(usingTemplateObj.printed_agent_name)} -{" "}
                    {usingTemplateObj.agent_no}
                  </span>
                </button>
                <div
                  class={
                    "dropdown-menu " + (this.state.menushowing ? "d-block" : "")
                  }
                >
                  
                   <div
                      class={"dropdown-item"+((!usingTemplateObj || usingTemplateObj.template_name == 'default') ? ' selected' :" f")}>
                          <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeTemplate2('default', {
                          template_name:'default',
                          printed_agent_name: this.state.agent_info.name,
                          agent_no: this.state.agent_info.agent_number,
                          templateFlag : 'default'
                        });
                      }} 
                    >
                       <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                      <strong><i>Default</i></strong>
                      <br />
                      <span class="meta">
                        {this.state.agent_info.name} - {this.state.agent_info.agent_number}
                      </span>
                      </a> 
                       </div>
                  {this.state.templates
                    ? this.state.templates.map((item, i) => {
                        return (
                          <div
                            class={"dropdown-item"+(usingTemplateObj && usingTemplateObj.template_name == item.template_name ? ' selected' :'')}>
                              <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeTemplate2("template_" + item.id, item);
                            }}
                            key={item.id}
                          >
                             <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                            <strong>{item.template_name}</strong>
                            <br />
                            <span class="meta">
                              {item.printed_agent_name} - {item.agent_no}
                            </span>
                            </a>
                            <div>
                            <Link className="actionitem" to={"/edit-template/" + item.id}>Edit
                  </Link>
                  <Link
                    className="text-danger actionitem"
                    onClick={e => {
                      e.preventDefault();
                      this.deleteTemplate(item.id);
                      // this.setAgentConfigName('template_'+item.id);
                    }}
                    to={"/edit-template/" + item.id}>
                    {" "}
                    <i className="fa fa-trash"></i>
                  </Link>
                          </div>
                          </div>
                        );
                      })
                    : false}

<div
class={"dropdown-item last-item"} style={{paddingLeft:0}}>
<Link
to="/add-template"
style={{ 
  fontSize: 13,
  width: "100%",fontWeight:500,
  textAlign: "left",
  display: "inline-block",
  color: '#5b5d60' 
}}>
+ Add template
</Link>
<Link to='/' onClick={e=>{
  e.preventDefault();
  this.setState({menushowing: false})
}} 
style={{ 
  fontSize: 13,
  width: "100%",fontWeight:500,
  textAlign: "right",
  display: "inline-block",color: '#5b5d60' 
}}>
  &times; Close
</Link>
</div>

                </div>
              </div>

              {/* <select
                value={this.state.usingTemplateId}
                onChange={this.changeTemplate2.bind(this)}
                className="form-control"
              >
                <option value="default">{this.state.agent_info ? this.state.agent_info.name : 'Default'}</option>
                {this.state.templates
                  ? this.state.templates.map((item, i) => {
                      return (
                        <option value={'template_' + item.id} key={item.id}>
                          {item.printed_agent_name}
                        </option>
                      );
                    })
                  : false}
              </select> */}
            </span>
          </small>
        </div>
        
        
        <Header className=" less_mrgn_topf">
          <Link to="/" className="logo">
            <img
              style={{ maxWidth: 240 }}
              src={require("../assets/logo.png")}
              alt=""
            />
          </Link>
        </Header>

        <Section className="section--main">
        
          <Section.Head cls="ishomeheader">
            <h1 className="text-center noMinHeight">Insurance made easy</h1>

            <p className="text-center">
             
            </p>

            <div className="ladder_radio_button_row">
              {allowedSides === "both" || allowedSides === "left" ? (
                <div className="ladder_input_card individuals">
                  <input
                    id="ladder_individuals"
                    name="insurance_line"
                    type="radio"
                    value="individual"
                    onChange={() => {
                      this.setState({
                        selectedType: "individual",
                      });
                    }}
                  />
                  <label htmlFor="ladder_individuals">
                    <div className="ladder_label_icon">
                     
                      <img
                        className="ico_home"
                        src={require("../assets/images/individual.jpg")}
                        alt=""
                      />
                      
                    </div>
                    <div className="ladder_label_title">
                      Individual & Families
                    </div>
                    <span className="ladder_radio_btn"></span>
                  </label>
                </div>
              ) : (
                false
              )}
              {allowedSides === "both" ? (
                <div className="separator_text">or</div>
              ) : (
                false
              )}
              {allowedSides === "both" || allowedSides === "right" ? (
                <div className="ladder_input_card employers">
                  <input
                    onChange={() => {
                      this.setState({
                        selectedType: "payroll",
                      });
                    }}
                    id="ladder_employers"
                    name="insurance_line"
                    type="radio"
                    value="payroll"
                  />
                  <label htmlFor="ladder_employers">
                    <div className="ladder_label_icon">
                      {/* <img className="ico_home" src={require('./assets/images/payroll.png')} alt="" /> */}
                      <img
                        className="ico_home"
                        src={require("../assets/images/payroll.jpg")}
                        alt=""
                      />
                      {/* <img style="display: none;" className="ico_home_cl" src="images/ico_home_color.jpg" alt="" />  */}
                    </div>
                    <div className="ladder_label_title">Employers</div>
                    <span className="ladder_radio_btn"></span>
                  </label>
                </div>
              ) : (
                false
              )}
            </div>

            <div className="text-center btn_wrap">
              <Button.Link
                disabled={!this.state.selectedType}
                onClick={() => {
                  // this.setState({})
                  localStorage.setItem("using_agent", agent);
                }}
                to={"/mode/" + this.state.selectedType + "/steps/1"}
                size="medium"
              >
                {this.getSubmitLabel("Get Started")}
              </Button.Link>
              <br />

              <div class=""></div>

              <br />

              {this.state.pendingUploadsCount > 0 ? (
                <small className="mt-1">
                  You have {this.state.pendingUploadsCount} pending uploads.
                  Click <Link to="/pendingSubmissions">here</Link> to view.
                </small>
              ) : (
                false
              )}
            </div>
          </Section.Head>

          <Section.Foot className="is_home_image">
            {/* <img src={require("../assets/images/img1.png")} alt="" /> */}
            <img src={require("../assets/images/new_home/foo-img.png")} alt=""  style={{opacity:0.5, width:'100%'}} />
          </Section.Foot>

          
       
        </Section>
        
        <Menu
          isVisible={this.state.menuVisible}
          agent_info={this.state.agent_info}
          onClose={() => {
            this.setState({
              menuVisible: false,
              hideDropdown: true
            });
          }}
          hideDropdown={this.state.hideDropdown || false}
          hideMain={!this.state.hideDropdown}
          renderDropdown={
            <div className="agent_config_select_new">
              {this.renderAgentConfigList("list", this.state.usingTemplateId)}
              {/* </select> */}
              <Link
                to="/add-template"
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  width: "100%",
                  textAlign: "center",
                  display: "inline-block",
                }}>
                + Add template
              </Link>
            </div>
          }
          // renderLinks={
          //   <div style={{ textAlign: "center", marginTop: 30 }}>
          //     <small className="mt-1">
          //       <span class="fa fa-sign-out" /> Sign In/Registration{" "}
          //       <a
          //         onClick={() => {
          //           localStorage.setItem("orange_isLoginDone2", "");
          //           sessionStorage.setItem("orange_isPinDone", "");
          //           localStorage.setItem("agent_token", "");
          //           localStorage.setItem("agent_name", "");
          //           localStorage.setItem("agent_code", "");
          //           localStorage.setItem("agent_pin", "");
          //           localStorage.setItem("agent_email", "");
          //           //  this.goToPage('#welcome');
          //           window.location.href = "/#welcome";
          //         }}
          //       >
          //         Click here
          //       </a>
          //     </small>
          //   </div>
          // }
        />
        </div>
        {
          this.state.showingAnimation ?  <div class="page7 max-375 abss ">
          <div class="page1-sm-img">
              <img   src={require("../assets/images/new_home/new-loader.gif")}  />
          </div>
      </div> : false
        }
        
      </Wrapper>
    );
  }
}

export default Home;