/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * External dependencies
 */
import React, {Component, Fragment} from "react";
import cx from "classnames";
import InputMask from "react-input-mask";
import {Redirect, Link} from "react-router-dom";
import {IonHeader, IonIcon} from "@ionic/react";
import {refreshCircleOutline, locationOutline} from "ionicons/icons";
import { Device } from '@capacitor/device';

import {Browser} from "@capacitor/browser";
import moment from "moment";
import {Capacitor} from "@capacitor/core";
import fallBackProductList from "../data/fallBackProductList.json";
import "fancy-notifications";
import {Plugins} from "@capacitor/core";
import {Keyboard} from "@capacitor/keyboard";

//  import getOverrides from "../data/plan_overrides";
import getOverridesNew from "../data/plan_overrides_new";
/**
 * Layouts
 */
import Wrapper from "../layouts/wrapper";
import Section from "../layouts/section";

/**
 * Components
 */
import Progress from "../components/progress/progress";
import Button from "../components/button/button";
import Field from "../components/field/field";
import Textarea from "../components/textarea/textarea";
import Radio from "../components/radio/radio";
import RadioAlt from "../components/radio/radio-alt";
import Checkbox from "../components/checkbox/checkbox";
import CheckboxAlt from "../components/checkbox/checkbox-alt";
import ListFeatures from "../components/features/list-features";
import poster from "../components/poster";

/**
 * Provider
 */
import AppContext from "../app-provider";
import postData from "../components/poster";
import constants from "../components/constants";
import jquery from "jquery";
import SmallComps from "./smallComponents/smallComponents";
import Menu from "./menu";
import { GoBack } from "../components/GoBack";
//  const { NotificationBadge } = Plugins;
// import steps from "../data/steps";

const {FancyNotifications} = Plugins;
let device_info_string = '';

const showMapPopup = (address)=>{
  jquery('#root_popup_outer').css({display:'flex'});
  jquery('#root_popup_inner').html('<iframe src="https://www.google.com/maps?q='+address+'&output=embed" />');
  jquery('#root_popup_close').click(function(){
    jquery('#root_popup_outer').hide();
  })
}
const getDeviceInfo = async ()=>{
  try{
    const info = await Device.getInfo();
    if(info.platform == 'web')
    {
      if(info.operatingSystem  =='mac')
      {
        info.operatingSystem  = 'Mac OS'
      }
      device_info_string =   info.operatingSystem +  ' ('+  navigator?.userAgentData?.brands[0]?.brand  +' - v'+ info.webViewVersion+')'
    }else{
      device_info_string = info.operatingSystem + ' ' +info.osVersion + ' ('+ info.manufacturer+" "+info.name+ ')';
    }
  }catch(e){
    // alert(e.toString())
  }

}

getDeviceInfo();
const openUrl = async (e, url) => {
  if (
    window.location.host == "localhost:3000" ||
    "app.mhlez.com" == window.location.host||
    "testapp.mhlez.com" == window.location.host
  )
    return true;
  e.preventDefault();
  await Browser.open({
    url: url,
    presentationStyle: "popover",
    toolbarColor: "#fda21b",
  });
};
const url = constants.api_url;
const product_age_range = constants.product_age_range;
let $ = jquery;

async function updateBadgeCount(cnt) {
  if (
    window.location.host == "localhost:3000" ||
    window.location.host == "localhost:3001" ||
    "app.mhlez.com" == window.location.host||
    "testapp.mhlez.com" == window.location.host
  )
    return;

  const check = await FancyNotifications.hasPermission();
  if (check.value) {
    if(!cnt)
    {
      FancyNotifications.clearBadgeCount();
    }else{
      FancyNotifications.setBadgeCount({count: cnt});
    }
  } else {
    const request = await FancyNotifications.requestPermission();
    if (request.value) {
      if(!cnt)
      {
        FancyNotifications.clearBadgeCount();
      }else{
        FancyNotifications.setBadgeCount({count: cnt});
      }
    } else {
        // User failed to grant permission show some dialog
    }
  } 
}
const AvatarIcon = () => (
  <img src={require("../assets/new_icons/avatar_new.gif")} />
);

const Logo = () => (
  <img alt="logo" src={require("../assets/images/logo_top.png")} />
);
const LogoHalf = () => (
  <img alt="logo" src={require("../assets/images/logo_top_half.png")} />
);
class Steps extends Component {
  constructor(props) {
    super(props);
    let inactive_plans = localStorage.getItem("orange_agentPlansDisabled");
    this.state = {
      fields: [],
      radios: [],
      radiosTop: [],
      radiosAlt: [],
      inactive_plans: JSON.parse(inactive_plans || "[]"),
      plansAllowed: [],
      card: [],
      radiosQuestionnaire: [],
    };
  }

  runningInterval(){
    // console.log('------------------------------')

    let m = window.location.pathname;
    if(m.indexOf('/mode') > -1)
    {

      let templates = localStorage.getItem("orange_agentTemplates");
      templates = JSON.parse(templates || "[]") || [];
      let template_id = localStorage.getItem("orange_usingTemplateId") || "default";
      let templateFlag = localStorage.getItem("orange_usingTemplateFlag") || 'default';
    
      let stepValues = this.context.stepValues;

      if (stepValues["split_template"] != template_id || stepValues['templateFlag']!=templateFlag) {

        this.setAgentConfigName(template_id);
      }
    }
  }

  componentDidMount() {
    this.iid = setTimeout(this.runningInterval.bind(this), 2000);

    // this.setState({
    //   keyboardShowing:  true
    // });
    if (Capacitor.getPlatform() != "web") {
      Keyboard.setResizeMode({
        mode: "native",
      });
      Keyboard.setScroll({isDisabled: false});

      Keyboard.addListener('keyboardWillShow', ( ) => {
        this.setState({
          keyboardShowing:  true
        });
      });
      
      Keyboard.addListener('keyboardDidHide', ( ) => {
        this.setState({
          keyboardShowing:  false
        });
      });

    }
    this.color_mode_show_pay_mode = localStorage.getItem(
      "color_mode_show_pay_mode" || "yes",
    );

    document.body.classList.add("yellow");
    let pending_updates = localStorage.getItem("pending_updates");

    let pending_drafts = localStorage.getItem("pending_drafts");
    if (!pending_drafts) pending_drafts = "[]";
    pending_drafts = JSON.parse(pending_drafts);

    pending_updates = JSON.parse(pending_updates || "[]");
    //  pending_updates.push({})
    if (
      (pending_updates && pending_updates.length > 0) ||
      (pending_drafts && pending_drafts.length > 0)
    ) {
      this.setState({
        has_pending_updates: true,
        pending_updates_count: pending_updates.length + pending_drafts.length,
      });
      // console.log('here');
      updateBadgeCount(pending_updates.length + pending_drafts.length);
    }else{
      updateBadgeCount(0);
    }
    this.doInternetC = setInterval(() => {
      let index = this.props.match.params.step;
      if (index <= 3) {
        this.doInternetCheck();
        // clearInterval(this.doInternetC);
      }
    }, 5000);
    setTimeout(() => {
      this.agent_info = JSON.parse(localStorage.getItem("agent_info") || "{}");
      this.agentCode = this.agent_info.agent_code || ""; 
      this.getAgentInfo(this.agent_info); 
    }, 1000);
  }

  shouldHideBasedOnAge(plan, yr) {
    if (!product_age_range[plan]) return false;

    if (!yr) {
      return false;
    }
    let cur_year = new Date().getFullYear();
    let age = cur_year - yr;
    if (product_age_range[plan].min > age) return true;
    if (product_age_range[plan].max < age) return true;
    return false;
  }

  deleteTemplate(id) {
    if (window.confirm("Are you sure you want to delete this template?")) {
      postData
        .getData(url + "listSplitsAgent.php?delete_id_app=" + id)
        .then(data => {
          window.swal('Success', "Template deleted successfully.", 'success');
          this.loadTemplates();
        });
    }
  }

  loadTemplates() {
    this.setState({
      loaded: false,
    });
    let agent_info = localStorage.getItem("agent_info");
    agent_info = JSON.parse(agent_info || "{}");

    if (!agent_info) return;
    postData
      .getData(url + "listSplitsAgent.php?for_agent=" + agent_info.id)
      .then(data => {
        data.list = data.list.map((item, i)=>{
          item.templateFlag = Math.random();
          return item;
        })
        localStorage.setItem(
          "orange_agentTemplates",
          JSON.stringify(data.list),
        );
        this.setState({
          templates: data.list,
        });
      })
      .catch(err => {
        let list = localStorage.getItem("orange_agentTemplates");
        list = JSON.parse(list || "[]") || [];
        this.setState({
          data: list,
          loaded: true,
          agent_info: agent_info,
        });
      });
  }

  getAgentInfo(agent_info) {
    let templates = localStorage.getItem("orange_agentTemplates");
    templates = JSON.parse(templates || "[]") || [];
    let template_id =
      localStorage.getItem("orange_usingTemplateId") || "default";
      
    let templateFlag = localStorage.getItem("orange_usingTemplateFlag");

    this.setState({
      agent: agent_info,
      plansAllowed: false,
      productsAllowed: false,
      templates: templates,
      split_template: template_id,
    });
    let stepValues = this.context.stepValues;

    if (stepValues["split_template"] != template_id || stepValues['templateFlag']!=templateFlag) {
      this.setAgentConfigName(template_id);
    }
  }

  setAgentConfigName(e) {
    if (!e) return;
    this.setState({
      menuVisible: false,
      split_template: e,
    });
    let stepData = this.context.stepValues;
    stepData["split_template"] = e;

    if (e == "default") {
      let agent_info = this.agent_info || {};

      stepData.split_repeater = [{}, {}, {}, {}];
      stepData["agent-name"] = agent_info.name;
      stepData["agent-no"] = agent_info.agent_code;
      stepData["agent_code"] = agent_info.agent_code;
      localStorage.setItem("orange_usingTemplate", "default");
      localStorage.setItem("orange_usingTemplateId", "default");
      localStorage.setItem("orange_usingTemplateFlag", 'default');
      this.context.handleChangeStepValues(stepData);

      return;
    }
    let template_set = this.state.templates.filter((item, i) =>
      "template_" + item.id == e ? true : false,
    );
    if (template_set.length === 0) return;
    let dt = template_set[0];
    stepData["agent-no"] = dt.agent_no;
    stepData["agent-name"] = dt.printed_agent_name;
    stepData["agent_code"] = dt.agent_no;
    stepData["templateFlag"] = dt.templateFlag;
    stepData["employer"] = dt.group_name || '';
    stepData["groupNumber"] = dt.group_no || '';
    localStorage.setItem("orange_usingTemplate", dt.printed_agent_name);
    localStorage.setItem("orange_usingTemplateFlag", dt.templateFlag);
    localStorage.setItem("orange_usingTemplateId", e);
    stepData["split_repeater"] = JSON.parse(
      dt.split_settings || "[{},{},{},{}]",
    ) || [{}, {}, {}, {}];

    this.context.handleChangeStepValues(stepData);
  }

  doInternetCheck() {
    postData
      .getDataText(url + "manhattan.api.php?action=netcheck")
      .then(resp => {
        if (resp == "__YES__") {
          this.setState({
            has_internet: true,
          });
        } else {
          this.setState({
            has_internet: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          has_internet: false,
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.doInternetC);
    clearInterval(this.iid);
    document.body.classList.remove("yellow");
  }

  isValidEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getTotals(multipler) {
    let products_list = fallBackProductList;
    let cartData = this.context.stepValues;
    let total = 0;
    products_list.map((pro, i) => {
      let item = "product_" + pro.productId;

      if (!cartData["" + item]) return;

      let tmp = {riders: []};
      // hai product Cart me

      // get price
      let price = cartData[item + "_price"]
        ? cartData[item + "_price"]
        : cartData[item.replace("_insurance", "") + "_price"];
      let price_main = price || 0;

      price = parseFloat(price_main);
      let i_ar = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
      // now get riders
      i_ar.map(ik => {
        if (cartData["" + item + "_rider_" + ik]) {
          price += parseFloat(
            cartData["" + item + "_rider_" + ik + "_price"] || 0,
          );
        }
      });

      if (!isNaN(price)) {
        total += parseFloat(price);
      }

      //  cart_items.push(tmp);
    });
    return (total * multipler).toFixed(2);
  }

  shouldShowCartBtn() {
    // if(this.has_cart_item){
    //   return true;
    // }
    // let products_list = window.localStorage.getItem('products_list');
    // let products_list  = fallBackProductList ? JSON.parse(fallBackProductList) : [];
    let has_cart_item = false;
    let has_cart_cnt = 0;
    fallBackProductList.map((pro, i) => {
      let item = "product_" + pro.productId;
      if (this.context.stepValues["" + item] && this.context.stepValues["" + item+'_plan']) {
        // console.log(item)
        has_cart_item = true;
        has_cart_cnt++
      }
    });
    if (has_cart_item) {
      this.has_cart_item = true;
      this.has_cart_cnt = has_cart_cnt;
      return true;
    }
  }

  addTh(t) {
    if (!t) {
      return;
    }
    if (t === "1") {
      return "1st";
    }
    if (t === "2") {
      return "2nd";
    }
    if (t === "3") {
      return "3rd";
    }
    return "" + t + "th";
  }

  getPlanFor(cur_obj, plan_id) {
    // console.log(this.context.plan_overrides);
    let mode = this.props.match.params.mode;
    let for_sel = this.context.stepValues[plan_id + "_radio"];
    let spouse_dob = this.context.stepValues["spouse-date"];
    let for_ = "";
    // console.
    switch (for_sel) {
      // case "Child Only":
      //   for_ = "child_only";
      //   break;
      case "Self":
        for_ = "self";
        break;
      case "Self & Child(ren)":
      case "Self & Child":
        for_ = "self_child";
        break;
      case "Family":
      case "Single Parent Family":
        for_ = "family";
        break;
      case "Self & Spouse":
        for_ = "self_spouse";
        break;
      case "Self & Child":
        for_ = "self_child";
        break;
      default:
        for_ = "self";
        break;
    }
    let state = this.context.stepValues["state"];
    if (!state) state = "TN";
    let date = this.context.stepValues["dob_date"];
    let month = this.context.stepValues["dob_month"];
    let year = this.context.stepValues["dob_year"];

    var dateObject = new Date("" + month + "/" + date + "/" + year);
    let age = this._calculateAge(dateObject);

    if (!age) age = 80;
    // console.log(for_sel, for_, mode);
    let my_age = age;
    // console.log(for_sel);
    let spouse_age = 0;
    if (for_sel === "Self & Spouse" || for_sel === "Family") {
      spouse_age = this._calculateAge(new Date(spouse_dob));
      // console.log('s: ', spouse_age)
      if (spouse_age > age) {
        my_age = spouse_age;
      }
    }
    // debugger;
    let data = getOverridesNew(
      this.context.products_plans,
      this.context.rates,
      this.context.stepValues[plan_id + "_plan"],
      plan_id,
      mode,
      for_,
      state,
      my_age,
      spouse_age,
      this.context.stepValues,
      {
        //  childen_count : this.getChildrenCount(this.context.stepValues),
        //  'dvh_deductible'  : this.context.stepValues['dental_dvh_select-plan-deductible'] || false,
        //  'dvh_vision'  : this.context.stepValues['dental_dvh_select-plan-vision'] || false,
        //  'dvh_hearing'  : this.context.stepValues['dental_dvh_select-plan-hearing'] || false,
        //  acci_expres_wellness:
        //    this.context.stepValues["accident_express_wellness"] || false,
        //  acci_paid_wellness:
        //    this.context.stepValues["accident_paid_wellness"] || false,
        //  acci_paid_off_the_job:
        //    this.context.stepValues["accident_paid_off_the_job"] || false,
        //  ccr: this.context.stepValues["cancer_insurance_cp4000_ccr"] || false,
        //  icr: this.context.stepValues["cancer_insurance_cp4000_icr"] || false,
        //  wc: this.context.stepValues["cpr_with_cancer"] || false,
        //  oop_1 : this.context.stepValues['out_of_pocket-plan'] || false,
        //  oop_2 : this.context.stepValues['out_of_pocket-plan-first_hospital'] || false,
        //  oop_3 : this.context.stepValues['out_of_pocket-plan-outpatientsurgery'] || false,
        //  oop_4 : this.context.stepValues['out_of_pocket-plan-emergency'] || false,
      },
    );

    // console.log(data)
    cur_obj.radios = data.radios;
    cur_obj.total = data.total;
    cur_obj.productName = data.productName;
    cur_obj.radiosAlt = data.radiosAlt;
    if (data.checkboxes) {
      cur_obj.checkboxes = data.checkboxes;
      cur_obj.ckeckboxes_below_fields = data.ckeckboxes_below_fields || false;
    }
    // debugger;
    // if (plan_id === 'home_health_care') {
    //   delete cur_obj.formHead.formHeadSubtitle;
    //   delete cur_obj.dates[2];
    //   delete cur_obj.dates[3];
    //   delete cur_obj.dates[4];
    //   delete cur_obj.dates[5];
    //   if (this.context.stepValues[plan_id + '_radio'] === 'Self & Spouse') {
    //     cur_obj.show_total = true;
    //     let price_1 = 0;
    //     let price_2 = 0;
    //     if (
    //       document.querySelectorAll('[name="hcc_plan_self"]:checked').length &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_self"]:checked')[0]
    //         .parentNode.querySelector('.radio__price') &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_self"]:checked')[0]
    //         .parentNode.querySelector('.radio__price').innerText &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_self"]:checked')[0]
    //         .parentNode.querySelector('.radio__price').innerText !== 'NA.NA'
    //     ) {
    //       price_1 = parseFloat(
    //         document
    //           .querySelectorAll('[name="hcc_plan_self"]:checked')[0]
    //           .parentNode.querySelector('.radio__price').innerText,
    //       );
    //     }
    //     if (
    //       document.querySelectorAll('[name="hcc_plan_spouse"]:checked')
    //         .length &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_spouse"]:checked')[0]
    //         .parentNode.querySelector('.radio__price') &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_spouse"]:checked')[0]
    //         .parentNode.querySelector('.radio__price').innerText &&
    //       document
    //         .querySelectorAll('[name="hcc_plan_spouse"]:checked')[0]
    //         .parentNode.querySelector('.radio__price').innerText !== 'NA.NA'
    //     ) {
    //       price_2 = parseFloat(
    //         document
    //           .querySelectorAll('[name="hcc_plan_spouse"]:checked')[0]
    //           .parentNode.querySelector('.radio__price').innerText,
    //       );
    //     }

    //     // console.log(price_1)
    //     // console.log(price_2)
    //     cur_obj.show_total_amnt = (price_1 + price_2).toFixed(2);
    //     // cur_obj.show_total_amnt= 0;
    //   }
    // }

    if (
      this.context.stepValues[plan_id + "_radio"] === "Family" ||
      this.context.stepValues[plan_id + "_radio"] === "Self & Spouse"
    ) {
      cur_obj.fields_after_radio = true;
      cur_obj.showSpouseGender = true;
      cur_obj.fields = [
        {
          show_if: "radio-insurance",
          id: "spouse-full-name",
          text: "Spouse Name",
          isHalf: true,
          isRequired: true,
          type: "text",
        },
        {
          show_if: "radio-insurance",
          id: "spouse-date",
          text: "Date of Birth",
          isRequired: true,
          isHalf: true,
          type: "mask",
        },
      ];
    }
    if (plan_id == "product_301") {
      cur_obj.disableHtWt = true;
      // cur_obj.showSpouseGender = false;
    }
    // console.log(cur_obj);
    return cur_obj;
  }

  removePlansAgentDoesntOffer(step) {
    
    if (!this.agentCode || this.agentCode === "undefined") {
      return step;
    }
    if (!this.state.plansAllowed) {
      return step;
    }
    let name = step.radiosAlt[0].name;
    name = name.replace("_plan", "");

    // let options = radiosAlt[0].values;
    // radiosAlt[0].values[i].text
    // console.log(this.state.plansAllowed);
    step.radiosAlt[0].values = step.radiosAlt[0].values.filter((item, i) => {
      let tmp = name + "_" + item.text;
      tmp = tmp
        .replace("-", "_")
        .replace("-", "_")
        .replace("-", "_")
        .replace("-", "_")
        .replace("-", "_")
        .replace("-", "_");
      if (this.state.plansAllowed && this.state.plansAllowed.indexOf(tmp) > -1)
        return true;
      return false;
    });
    // step =
    return step;
  }

  removeProductsAgentDoesntOffer(step) {
    let allowedProducts = localStorage.getItem("orange_agentProducts");
    if (!allowedProducts) {
      allowedProducts = "[]";
    }
    allowedProducts = JSON.parse(allowedProducts);

    if (!this.agentCode || this.agentCode === "undefined") {
      return step;
    }
    if (!allowedProducts || allowedProducts.length === 0) {
      return step;
    }

    let checkboxes_final = [];
    let checkbox_pre = step.checkboxes[0].values;

    checkbox_pre.map((item, i) => {
      if (
        allowedProducts.indexOf((item.id || "").replace("product_", "")) > -1
      ) {
        checkboxes_final.push(item);
      }
    });

    step.checkboxes[0].values = checkboxes_final;
    return step;
  }

  overrideStepForPlans(step) {
    let override_list = constants.override_list;
    if (1) {
      let tmp = JSON.parse(JSON.stringify(step));
      return this.getPlanFor(tmp, this.plan, this.age, this.state);
    }
    return step;
  }
  handleChange = (name, value, c, d, extra_key, extra_val) => {
    let stepValues = this.context.stepValues;

    stepValues[name] = value;
    if (extra_key) {
      stepValues[extra_key] = extra_val;
    }
    let deps = [
      "spouse-full-name",
      "dependent1-full-name",
      "dependent2-full-name",
      "dependent3-full-name",
      "dependent4-full-name",
      "dependent5-full-name",
      "dependent6-full-name",
      "dependent7-full-name",
      "dependent8-full-name",
      "dependent9-full-name",
      "dependent10-full-name",
      "dependent11-full-name",
      "dependent12-full-name",
      "dependent13-full-name",
      "dependent14-full-name",
      "dependent15-full-name",
      "dependent16-full-name",
      "dependent17-full-name",
      "dependent18-full-name",
    ];
    if (deps.indexOf(name) > -1) {
      // console.log('292')
      if (value && value.length > 2) {
        //  if(ch-dependent1)
        stepValues["ch-" + name.replace("-full-name", "")] = true;
      } else {
        if (!value || value === "") {
          stepValues["ch-" + name.replace("-full-name", "")] = false;
        }
      }
      this.setState({
        showing_prices: false,
      });
    }
    // stepValues[]
    this.context.handleChangeStepValues(stepValues);
    if (d) {
      setTimeout(() => {
        // ref={input => this.inputElement = input}
        // console.log(	this.inputElement)
        this.inputElement.click();

        // debugger;
        // document.getElementsByClassName('.nextBtn').trigger('click');
      }, 100);
    }
  };

  handleChangePhone = phone => {
    let stepValues = this.context.stepValues;

    stepValues["contact-phone"] = phone;
    this.context.handleChangeStepValues(stepValues);
  };

  handleChangeCard = e => {
    let stepValues = this.context.stepValues;
    stepValues[e.target.name] = e.target.value;
    // console.log(stepValues)
    this.context.handleChangeStepValues(stepValues);
  };

  // setGiStep = gi_handler => {
  //   let stepValues = this.context.stepValues;
  //   stepValues[gi_handler] = true;
  //   if (gi_handler === "is_gi_accident") {
  //     stepValues["radio-hazardous-activities"] = "";
  //     stepValues["message-hazardous-activities"] = "G.I.";
  //     stepValues["radio-athletics-sports"] = "";
  //     stepValues["message-athletics-sports"] = "G.I.";
  //     stepValues["radio-license-suspended"] = "";
  //     stepValues["message-license-suspended"] = "G.I.";
  //     stepValues["radio-dui-dwi"] = "";
  //     stepValues["message-dui-dwi"] = "G.I.";
  //     stepValues["radio-abuse"] = "";
  //     stepValues["message-abuse"] = "G.I.";
  //   } else if (gi_handler === "is_gi_std") {
  //     stepValues["message-occupation"] = "G.I.";
  //     stepValues["message-disease"] = "G.I.";
  //     stepValues["message-hospitalized"] = "G.I.";
  //     stepValues["message-medicare_gi"] = "G.I.";
  //     stepValues["message-license-suspended_gi"] = "G.I.";
  //     stepValues["message-physician_gi"] = "G.I.";
  //     stepValues["radio-hospitalized"] = "";
  //     stepValues["radio-occupation"] = "";
  //     stepValues["radio-license-suspended_gi"] = "";
  //     stepValues["radio-physician_gi"] = "";
  //     stepValues["radio-medicare_gi"] = "";
  //     stepValues["radio-disease"] = "";
  //   } else if (gi_handler === "is_gi_cpr") {
  //     stepValues["text-cpr-2a"] = "G.I.";
  //     stepValues["text-cpr-2b"] = "G.I.";
  //     stepValues["text-cpr-2c"] = "G.I.";
  //     stepValues["text-cpr-2d"] = "G.I.";
  //     stepValues["text-cpr-2e"] = "G.I.";
  //     stepValues["text-cpr-2f"] = "G.I.";
  //     stepValues["text-cpr-2g"] = "G.I.";
  //     stepValues["text-cpr-3"] = "G.I.";
  //   } else if (gi_handler === "is_gi_oop") {
  //     stepValues["message-physician"] = "G.I.";
  //     stepValues["message-blood-pressure"] = "G.I.";
  //     stepValues["message-proposed_insurance"] = "G.I.";
  //     stepValues["message-diagnosed_with_conditions"] = "G.I.";
  //   }
  //   this.context.handleChangeStepValues(stepValues);
  //   this.setState({
  //     showGi: false,
  //   });
  // };

  handleAgentInfoIsHere = (donext, doLast) => {
    let stepValues = this.context.stepValues;

    stepValues["agent_is_here"] = donext ? true : false;
    this.context.handleChangeStepValues(stepValues);
    if (donext)
      setTimeout(() => {
        if (doLast) {
          sessionStorage.setItem(
            "orange_isforcedLastShouldGoBackTo",
            this.step,
          );
          this.setState({
            goToLast: true,
          });
        } else {
          this.setState({
            goToNext: true,
          });
        }
      }, 1000);
  };

  handleChangeDate = e => {
    let stepValues = this.context.stepValues;
    stepValues[e.target.name] = e.target.value;
    this.context.handleChangeStepValues(stepValues);
  };

  handleAddRow = e => {
    e.preventDefault();

    const dates = this.context.steps[this.props.match.params.step - 1].dates;

    let nm = "dependent" + (dates.length - 1);
    const newDate = {
      title: "Dependent",
      isRequired: true,
      fieldName: {
        id: nm + "-full-name",
        text: "Full Name",
      },
      fieldDate: {
        id: nm + "-date",
        text: "MM/DD/YYYY",
      },
      fieldH: {
        id: nm + "-h",
        label: "HT",
        pattern: "\\d*",
      },
      fieldGender: {
        id: nm + "-gender",
        label: "Gender",
      },
      fieldW: {
        id: nm + "-w",
        label: "Wt",
        pattern: "\\d*",
      },
      checkbox: {
        id: "ch-" + nm + "",
      },
    };

    this.context.handleAddDateRow(newDate);
  };

  getRequiredFieldsForStep(fields) {
    return fields.filter(field => {
      return (
        field.isRequired && (!field.only_on || field.only_on === this.mode) && (!field.visible_if ||
          
          field.visible_if_condition.indexOf(this.context.stepValues[field.visible_if]) > -1
          
          
          )
      );
    });
  }

  isValid() {
    return this.getValidationErrors().length === 0;
  }

  getFirstSelectedPlan(step_values) {
    let index = this.props.match.params.step;
    let step = this.context.steps.filter((item, i) => {
      return item.is_plan_step;
    });
    step = step[0];
    let options = step.checkboxes[0]["values"].map(function (imte, i) {
      return imte.id;
    });
    // console.log(options);
    options = options.filter(item => {
      return item === "std_manhattan_life" ? false : true;
    });
    let stepValues = this.context.stepValues;
    options = options.filter((item, i) => {
      return stepValues[item] ? true : false;
    });

    // console.log(options)
    return options[0];
    // let stepValues = this.context.stepValues;
  }

  getPlan(f) {
    let plans = constants.plan_names;
    return plans[f];
  }

  getNextSelectedPlan(step_values, cur_idx) {
    let index = this.props.match.params.step;
    let cur_plan = this.props.match.params.plan;
    let step = this.context.steps.filter((item, i) => {
      return item.is_plan_step;
    });
    step = step[0];
    let options = step.checkboxes[0]["values"].map(function (imte, i) {
      return imte.id;
    });

    let stepValues = this.context.stepValues;
    options = options.filter(item => {
      return item === "std_manhattan_life" ? false : true;
    });
    options = options.filter((item, i) => {
      return stepValues[item] ? true : false;
    });
    // console.log(options);
    let cur_plan_found = 0;
    let cur_plan_id = -1;
    options.map(function (item, i) {
      if (item == cur_plan) {
        cur_plan_found = true;
        cur_plan_id = i;
      }
    });
    if (cur_plan_found) {
      if (options.length == cur_plan_id + 1) {
        return `/${++cur_idx}/`; // is last in selection
      }
      if (options[cur_plan_id + 1] == "std_manhattan_life") {
        return `/${++cur_idx}/`;
      }
      if (options[cur_plan_id + 1] == "out_of_pocket") {
        return `/${++cur_idx}/`;
      }
      return `/${cur_idx}/${options[cur_plan_id + 1]}`;
    } else {
      return `/${++cur_idx}/`;
    }

    // let stepValues = this.context.stepValues;
  }

  getValidationErrors() {
    let step = this.getStep();
    if (step && step.is_price_step) {
      step = this.overrideStepForPlans(step);
    }
    if (!step) {
      return true;
    }

    const stepFields = step.fields || [];

    // Find fields.fields
    const innerFields = this.getInnerFields(stepFields);

    // Combine all fields
    const allFields = [
      ...stepFields,
      ...innerFields,
      step.fieldCard || "",
      // step.fieldCard2 || '',
    ].filter(item => !!item);

    // console.log(allFields);
    // Validate fields
    const fields = this.getRequiredFieldsForStep(allFields);
    // console.log(fields);
    const invalidFields = this.getInvalidFields(fields);
    const fieldsMessages = this.getErrorsForFields(invalidFields);

    // Validate radios
    const radios = this.getRequiredFieldsForStep(step.radios || []);
    const radiosTop = this.getRequiredFieldsForStep(step.radiosTop || []);

    const invalidRadios = this.getInvalidRadios(radios);
    const invalidRadiosTop = this.getInvalidRadios(radiosTop);

    const radiosMessages = this.getErrorsForRadios(invalidRadios);

    // Validate radios alt
    const radiosAlt = this.getRequiredFieldsForStep(step.radiosAlt || []);
    const invalidRadiosAlt = this.getInvalidRadios(radiosAlt);
    const radiosAltMessages = this.getErrorsForRadios(invalidRadiosAlt);
    const radiosTopAltMessages = this.getErrorsForRadios(invalidRadiosTop);

    // Validate questionnaire
    const questionnaireRadios = this.getQuestionnaireRadios(step);
    const invalidQuestionnaire =
      this.getInvalidRadiosQuestion(questionnaireRadios);
    const questionnaireMessages = this.getErrorsForRadios(invalidQuestionnaire);

    // Validate dates

    const dates = this.getRequiredFieldsForStep(step.dates || []);
    // console.log(dates);
    const invalidDates = this.getInvalidDates(dates);
    // console.log(invalidDates);
    let datesMessages = this.getErrorsForDates(invalidDates);
    // debugger;
    if (this.context.stepValues[this.plan + "_radio"] === "Self") {
      datesMessages = [];
    }
    let extras = [];
    // console.log(step.textarea)

    if (step.is_contact_tab) {
      if (!this.context.stepValues["contact-phone"]) {
        extras.push({
          type: "phone",
        });
      }
    }
    if (
      step.textarea &&
      step.textarea.isRequired &&
      !this.context.stepValues[step.textarea.id]
    ) {
      if (step.textarea.visible_if) {
        if (
          this.context.stepValues[step.textarea.visible_if] ===
          step.textarea.visibleIfCondition
        ) {
          if (step.textarea2) {
            if (!this.context.stepValues[step.textarea2.id]) {
              extras.push({
                type: "textarea",
              });
            }
          } else {
            extras.push({
              type: "textarea",
            });
          }
        }
      } else {
        extras.push({
          type: "textarea",
        });
      }
    }
    // console.log(extras)

    // console.log(datesMessages);
    return [
      ...fieldsMessages,
      ...radiosMessages,
      ...radiosAltMessages,
      ...radiosTopAltMessages,
      ...questionnaireMessages,
      ...datesMessages,
      ...extras,
      // ...cardMessages,
      // ...questionnaireMessages
    ];
  }

  getInnerFields(fields) {
    let innerFields = [];

    fields.forEach(item => {
      innerFields = [...innerFields, ...(item.fields || [])];
    });

    return innerFields;
  }

  getQuestionnaireRadios(step) {
    if (!step.questionnaire) {
      return [];
    }

    let questionnaireRadios = [];
    if (step.questionnaire.hasRadioSet) {
      step.questionnaire.forEach(item => {
        questionnaireRadios = [...questionnaireRadios, ...item.radios];
      });
    } else {
      step.questionnaire.forEach(item => {
        questionnaireRadios = [...questionnaireRadios, ...item.radios];
      });
    }

    return questionnaireRadios;
  }

  isValidField(field, value) {
    if (!value) {
      if (field.is_main_date_field) {
        //
      } else {
        return false;
      }
    }
    // console.log(field, value);

    if (field.validationRule === "creditCard") {
      const trimed = value.replace(/_*-*/gi, "");

      return trimed.length === 9; // XXX-XX-XXXX
    } else if (field.validationRule === "benef-repeater") {
      let cnt = value.length;
      if (cnt === 0) return false;
      for (let i = 0; i < cnt; i++) {
        if (
          !value[i] ||
          !value[i].benef_first ||
          !value[i].benef_last ||
          !value[i].benefrelation
        ) {
          return false;
        }
      }
    } else if (field.validationRule == "date") {
      // console.log(this.context.stepValues);
      if (field.is_main_date_field && this.context.stepValues) {
        let y = this.context.stepValues["dob_year"];
        let m = this.context.stepValues["dob_month"];
        let d = this.context.stepValues["dob_date"];

        // console.log( moment(''+y+'-'+m+'-'+d));
        this.isAplicantLess18 = false;
        this.isAplicantVeryOld = false;
        if (moment("" + y + "-" + m + "-" + d).isValid()) {
          if (
            moment("" + y + "-" + m + "-" + d).isAfter(
              moment().subtract(18, "years"),
            )
          ) {
            // this.isAplicantLess18 = true;
            // return false;
          }
          if (
            moment("" + y + "-" + m + "-" + d).isBefore(
              moment().subtract(120, "years"),
            )
          ) {
            this.isAplicantVeryOld = true;
            return false;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  }

  getInvalidFields(fields) {
    return fields.filter(field => {
      return !this.isValidField(field, this.context.stepValues[field.id]);
    });
  }

  getInvalidRadios(radios) {
    return radios.filter(radio => {
      return !this.context.stepValues[radio.name];
    });
  }
  getInvalidRadiosQuestion(radios) {
    return radios.filter(radio => {
      let nm = radio.name;
      let nm2 = "";
      if (nm === "radio2-name-q1") {
        nm = "radio2a-name-q1";
        nm2 = "radio2b-name-q1";
      }
      if (nm === "radio2-name-q2") {
        nm = "radio2a-name-q2";
        nm2 = "radio2b-name-q2";
      }
      if (nm === "radio2-name-q3") {
        nm = "radio2a-name-q3";
        nm2 = "radio2b-name-q3";
      }
      if (nm === "radio2-name-q4") {
        nm = "radio2a-name-q4";
        nm2 = "radio2b-name-q4";
      }
      if (nm === "radio2-name-q5") {
        nm = "radio2a-name-q5";
        nm2 = "radio2b-name-q5";
      }

      if (nm2) {
        return !this.context.stepValues[nm] || !this.context.stepValues[nm2];
      } else {
        return !this.context.stepValues[nm];
      }
    });
  }

  getInvalidDates(dates) {
    return dates.filter(({fieldName, fieldDate, checkbox}) => {
      const valueName = this.context.stepValues[fieldName.id];
      const valueDate = this.context.stepValues[fieldDate.id];
      const valueChecked = this.context.stepValues[checkbox.id];

      return (
        !valueName ||
        !valueDate ||
        valueDate.replace(/_*\/*/gi, "").length !== 8 || // DD/MM/YYYY
        !valueChecked
      );
    });
  }

  getErrorsForFields(fields) {
    return fields.map(field => {
      return {
        id: field.id,
        message: field.validationMessage || `${field.id} is required`,
      };
    });
  }

  showAmnt(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getErrorsForRadios(radios) {
    return radios.map(radio => {
      return {
        name: radio.name,
        message: radio.validationMessage || `${radio.name} is required`,
      };
    });
  }
  goBack() {
    let index = this.props.match.params.step;
 
    let step = this.context.steps[index - 1];
    if (step.is_agent_step) {
      let goingBackTo = sessionStorage.getItem(
        "orange_isforcedLastShouldGoBackTo",
      );
      if (goingBackTo) {
        this.goingBackTo = goingBackTo;
        this.setState({
          goingBackToStep: true,
        });
        sessionStorage.setItem("orange_isforcedLastShouldGoBackTo", "");
        setTimeout(() => {
          this.handleAgentInfoIsHere(false);
        }, 1000);
        return;
      } else {
        this.setState({
          goingBack: true,
        });
      }
      
      setTimeout(() => {
        this.handleAgentInfoIsHere(false);
      }, 2000);
      return;
    }
    this.setState(
      {
        slideOutOpposite: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            slideOutOpposite: false,
            goingBack: true,
            goToLeft: true,
          });
        }, 400);
        setTimeout(() => {
          this.setState({
            slideInOpposite: true,
            goToLeft: false,
          });
        }, 450);
        setTimeout(() => {
          this.setState({
            slideInOpposite: false,
            // goToRight: true,
          });
        }, 850);
      },
    );
  }

  getErrorsForDates(dates) {
    return dates.map(date => {
      return {
        title: date.title,
        message: date.validationMessage || `${date.title} is required`,
      };
    });
  }

  getStep() {
    // console.log(this.context.steps, this.props.match.params.step - 1)
    return this.context.steps[this.props.match.params.step - 1];
  }

  forwardData(is_done) {
    if (
      this.props.match.params.step === 1 ||
      this.props.match.params.step === "1"
    )
      return;
    let mode = "edit";

    document.body.classList.add("yellow");
    let unique_id = localStorage.getItem("unique_identifier");
    let editMode = localStorage.getItem("editMode");
    if (!unique_id || unique_id === "") {
      mode = "new";
      unique_id = poster.uuidv4();
      localStorage.setItem("unique_identifier", unique_id);
    }
    if (is_done) {
      mode = "done";

      // if (this.context.stepValues && this.context.stepValues["default-agent"]) {
      //   let set_name = this.context.stepValues["default_agent_name"];

      //   postData
      //     .postData(url + "createSplit.php?agent_id=" + this.state.agent.id, {
      //       agent_no: this.context.stepValues["agent-no"],
      //       split_settings: JSON.stringify(  this.context.stepValues['split_repeater'] ),
      //       soliciting_agent_signature:
      //         this.context.stepValues["agent-signature"] || '',
      //       template_name: set_name,
      //       printed_agent_name: this.context.stepValues["agent-name"],
      //     })
      //     .then(() => {
      //       debugger;
      //     })
      //     .catch(function () {
      //       debugger;
      //     });

      //   // let kk = [
      //   //   "requested-effective-date", "agent-no", "agent-name", "agent-signature",
      //   //   "date" , "split-agent-name-1", "split-agent-per-1", "split-agent-name-2",
      //   //   "split-agent-per-2", "split-agent-name-3", "split-agent-per-3", "split-agent-name-4",
      //   //   "split-agent-per-4", "split-agent-name-5", "split-agent-per-5", "split-agent-name-6",
      //   //   "split-agent-per-6", "default-agent",
      //   // ];
      //   // let mm = {};
      //   // kk.map((f,m)=>{
      //   //   if(!this.context.stepValues[f])
      //   //     return f;
      //   //   // localStorage.setItem('def_'+f, );
      //   //   mm[f] = this.context.stepValues[f];
      //   //   return f;
      //   // });
      //   // let saved_names = localStorage.getItem("saved_names");
      //   // if(!saved_names)saved_names ='[]';
      //   // saved_names = JSON.parse(saved_names);
      //   // saved_names.push( set_name );
      //   // localStorage.setItem('saved_names', JSON.stringify(saved_names) );
      //   // localStorage.setItem('cc_'+set_name, JSON.stringify( mm )  )
      // }
    }

    if (is_done) {
      poster
        .postData(url + "saveData.php", {
          mode: mode,
          editMode: editMode === "yes" ? "yes" : "no",
          agent_code: this.agentCode,
          data: this.context.stepValues,
          device_info_string : device_info_string,
          identifier: unique_id,
        })
        .then(resp => {
          if (resp == "_NO_INTERNET_") {
            // store_offline
            let pending_updates = localStorage.getItem("pending_updates");
            if (!pending_updates) pending_updates = "[]";
            pending_updates = JSON.parse(pending_updates);

            pending_updates.push(unique_id);
            localStorage.setItem(
              "pending_updates",
              JSON.stringify(pending_updates),
            );
            localStorage.setItem(
              "pending_" + unique_id,
              JSON.stringify(this.context.stepValues),
            );
          }
          this.setState({
            submitted: true,
          });
        });
    }
  }

  removeSpouseOptionsIfNotReqiure(step) {
    // debugger;
    step = JSON.parse(JSON.stringify(step));

    // this.context.stepValues
    // console.log();
    let keys = Object.keys(this.context.stepValues || {});
    let has_family_option = false;
    keys.map((key, i) => {
      if (key.indexOf("_radio") > 0) {
        if (
          this.context.stepValues[key.replace("_radio", "")] &&
          this.context.stepValues[key] !== "Self" &&
          this.context.stepValues[key] !== "Self & Child(ren)" &&
          this.context.stepValues[key] !== "Self & Child" &&
          this.context.stepValues[key] !== "Single Parent Family"
        ) {
          has_family_option = true;
        }
      }
    });

    if (!has_family_option) {
      delete step.fieldCard2;
      delete step.radios;
    }

    return step;
  }

  // removeHHCSECQUESSTION(step) {
  //   step = JSON.parse(JSON.stringify(step));
  //   // console.log(step);
  //   // return step;
  //   let questionnaire = step.questionnaire;
  //   // console.log(questionnaire)
  //   questionnaire = questionnaire.map((item, i) => {
  //     // console.log(item.radios[0]);
  //     let vals = [item.radios[0].values[0], item.radios[0].values[1]];
  //     // console.log(vals);
  //     // let itemnew = JSON.parse(JSON.stringify(item));
  //     item.radios[0].values = vals;
  //     item.radios[0].isHalfHalf = false;
  //     item.radios[0].isHalf2 = true;
  //     return item;
  //   });
  //   // console.log(questionnaire)

  //   step.questionnaire = questionnaire;
  //   return step;
  // }

  step = false;
  plan = false;

  getPrice(price) {
    if (price === "NA.NA") {
      return price;
    }

    if (
      !this.context.stepValues["schedule"] ||
      this.context.stepValues["schedule"] === "Monthly"
    ) {
      return price;
    } else if (this.context.stepValues["schedule"] === "Weekly") {
      price = parseFloat(price);
      price = (price * 12) / 52;
    } else if (this.context.stepValues["schedule"] === "Bi Weekly") {
      price = parseFloat(price);
      price = (price * 12) / 26;
    } else if (this.context.stepValues["schedule"] === "Semi Monthly") {
      price = parseFloat(price);
      price = (price * 12) / 24;
    } else if (this.context.stepValues["schedule"] === "Annual") {
      price = parseFloat(price);
      price = price * 12;
      // }else{
      // price = parseFloat(price);
    }
    price = parseFloat(price).toFixed(2);
    return price;
  }


  doNextStep(step, index) {
    this.setState(
      {
        slideOut: true,
      },
      () => {
        setTimeout(() => {
          // debugger;
          if (step.is_price_step) {
            //  debugger;
            this.context.maybeLoadQuestions();
          }
          if (index == 1) {
            sessionStorage.setItem("is_internet_available", "no");
          }
          this.setState({
            slideOut: false,
            goToNext: true,
            goToRight: true,
          });
        }, 400);
        setTimeout(() => {
          this.setState({
            slideIn: true,
            goToRight: false,
          });
        }, 450);
        setTimeout(() => {
          this.setState({
            slideIn: false,
          });
        }, 850);
      },
    );
  }

  renderAgentConfigList(mode, selected) {
    if (mode === "list") {
      if (this.state.templates) {
        return (
          <div className="tempalteListWrap">
            <span>Select Template</span>
            <ul className="templateList">
              <li
                className={
                  selected == "default"
                    ? " selected templatechooser"
                    : "templatechooser"
                }>
                  <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                  
                <a
                  href="#"
                  className="isFirst"
                  onClick={e => {
                    e.preventDefault();
                    this.setAgentConfigName("default");
                  }}>
                  {" "}
                  {/* <strong> */}
                    {/* <i>Default</i> */}
                    <span className="metas">
                      <i>
                    {this.state.agent ? this.state.agent.name : ''} - {this.state.agent ? this.state.agent.agent_number : ''}
                    </i>
                  </span>
                  {/* </strong> */}
                </a>
              </li>
              {this.state.templates.map((item, i) => (
                <li
                  key={item.id}
                  className={
                    selected == "template_" + item.id
                      ? " selected templatechooser"
                      : " templatechooser"
                  }>
                    <span className="li_rad_ou">
                  <span className="li_rad_in"></span>
                  </span>
                  <a
                    href="#"
                    className="isFirst"
                    onClick={e => {
                      e.preventDefault();
                      this.setAgentConfigName("template_" + item.id);
                    }}>
                    <strong>
                      {item.template_name}
                      <span className="meta">
                        {item.printed_agent_name} - {item.agent_no}
                      </span>
                    </strong>
                  </a>
                  <Link
                    className="text-danger actionitem"
                    onClick={e => {
                      e.preventDefault();
                      this.deleteTemplate(item.id);
                      // this.setAgentConfigName('template_'+item.id);
                    }}
                    to={"/edit-template/" + item.id}>
                    {" "}
                    <i className="fa fa-trash"></i>
                  </Link>
                  <Link className="actionitem" to={"/edit-template/" + item.id}>Edit
                  </Link>
                </li>

                // <li key={item.id} className={selected == 'template_'+item.id ? " selected ": ""} value={item.id} onClick={()=>{
                //   this.setAgentConfigName('template_'+item.id);
                // }}><span>
                //   {item.template_name}</span>
                // </li>
              ))}
            </ul>
          </div>
        );
      }
    }
    return this.state.templates ? (
      this.state.templates.map((item, i) => (
        <option key={item.id} value={"template_" + item.id}>
          {item.template_name}
        </option>
      ))
    ) : (
      <option value="">No Template Available</option>
    );
  }

  render() {
    let index = this.props.match.params.step;
    let plan = this.props.match.params.plan;
    let mode = this.props.match.params.mode;
    // console.log(this.context.stepValues);
     
    let step = this.context.steps[index - 1];
    //  console.log(this.context.steps)
    if (this.state.showing_prices && step && step.is_price_step) {
      step.showing_prices = true;
    }
    if (index !== this.step || plan !== this.plan || mode !== this.mode) {
      window.scroll(0, 0);
      window.scroll(0, 0);
      setTimeout(function () {
        window.scroll(0, 0);
      }, 200);
      setTimeout(function () {
        window.scroll(0, 0);
      }, 300);
      window.scroll(0, 0);
      this.step = index;
      this.plan = plan;
      if (step) step.showing_prices = false;
      this.mode = this.props.match.params.mode;
      // this.context.mode  = mode;
      this.context.handleChangeMode(this.mode);
      this.setState(
        {
          goToNext: false,
          goingBackToStep: false,
          goToLast:false, 
          goingBack: false,
          showing_prices: false,
        },
        this.forwardData,
      );
      return false;
    }
    // console.log(this.context.steps , index, this.context.steps[index - 1] );

    //  console.log(step);
    if (step && step.is_price_step) {
      //
      //  console.log( this.props.match.params );
      if (!this.props.match.params.plan) {
        // add plan here.

        return (
          <Redirect
            to={`/mode/${mode}/steps/${index}/${this.getFirstSelectedPlan(
              this.context.stepValues,
            )}`}
          />
        );
      }
    }
    const isNextStepLength =
      this.props.match.params.step < this.context.steps.length;
    let disableNextStep = !this.isValid();

    if (!step) {
      window.location.href = "/inner";
      return <p>Loading...</p>;
    }

    if (step.is_plan_step) {
      console.log('------', step);
      step = this.removeProductsAgentDoesntOffer(step);

      step.checkboxes[0]["values"] = step.checkboxes[0]["values"].map(
        (item, i) => {
          let pid = item.id.replace("product_", "p_");
          let shouldHideAge = this.shouldHideBasedOnAge(
            pid,
            this.context.stepValues["dob_year"],
          );
          item.disabledAge = false;
          if (shouldHideAge) {
            item.disabled = true;
            // item.disabledAge = true;
          } else {
            item.disabled = item.disabledDefault;
          }

          return item;
        },
      );
    } else if (step && step.is_price_step) {
      step = this.overrideStepForPlans(step);
      step = this.removePlansAgentDoesntOffer(step);
    }

    if (step.is_ssn_step) {
      step = this.removeSpouseOptionsIfNotReqiure(step);
    } 

    if (
      step.is_price_step &&
      this.context.stepValues[plan + "_radio"] === "Family" &&
      (!this.context.stepValues["spouse-date"] ||
        this.context.stepValues["spouse-date"].indexOf("_") > -1 ||
        !this.context.stepValues["spouse-full-name"])
    ) {
      delete step.formHead;
      delete step.radiosAlt;
      delete step.formTitle;
      if (step.ckeckboxes_below_fields) {
        delete step.checkboxes;
        delete step.ckeckboxes_below_fields;
      }
      delete step.dates;
    }
    // if(step.is_billing_screen && this.context.stepValues['paymentTypeId'] === '140' || this.context.stepValues['paymentTypeId'] === 140)
    // {
    //   step.radios[0].isRequired = true;
    //   // step = JSON.parse(JSON.stringify(step));

    // }
    if (
      step.is_billing_screen &&
      this.context.stepValues["paymentTypeId"] === "142"
    ) {
      // step.radios[0].isRequired=false;
      step = JSON.parse(JSON.stringify(step));
      delete step.fields;
      delete step.radios;
    }
    // console.log(this.context.stepValues)
    let formData = this.context.stepValues;
    console.log('step', step);
    return (
      <Wrapper>
        <IonHeader ios-fixed>
          <div className="header_main">
            <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
              <div className="row">
                <div
                  className="col-5"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {formData.first_name && formData.last_name && index > 3 ? (
                    <>
                      <LogoHalf />
                      <div className="appliName">
                        <strong>
                          {formData.first_name} {formData.last_name}
                        </strong>
                        {index > 3 && formData.state ? (
                          <div className="align-center d-flex" onClick={()=>{
                            showMapPopup(formData.address)
                          }}>
                            <IonIcon icon={locationOutline}></IonIcon>{" "}
                            {formData.address}
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </>
                  ) : (
                    <Logo />
                  )}
                </div>
                <div className="col-1">
                  <a
                    style={{
                      width: 50,
                      height: 50,
                      padding: 10,
                      marginTop: 10,
                      display: "inline-block",
                      marginLeft: 0,
                    }}
                    onClick={e => {
                      e.preventDefault();
                      let buttons = ["Cancel", "Start Over"];
                      let className = "";
                      if (index > 3) {
                        className = "rev_wrp";
                        buttons = {
                          cancel: true,
                          confirm: "Start Over",
                          save_draft: {
                            text: "Save Draft and Start Over",
                            value: "save_draft",
                          },
                        };
                        // ['Save Draft and Start Over', 'Start Over', 'Cancel'];
                      }
                      window
                        .swal(
                          "WANT TO START OVER?",
                          "This will clear your answers and will start again from the beginning",
                          {
                            buttons: buttons,
                            className: className,
                          },
                        )
                        .then(value => {
                          if (value) {
                            if (value === "save_draft") {
                              let unique_id =
                                localStorage.getItem("unique_identifier");
                              let pending_drafts =
                                localStorage.getItem("pending_drafts");
                              if (!pending_drafts) pending_drafts = "[]";
                              pending_drafts = JSON.parse(pending_drafts);

                              pending_drafts.push(unique_id);
                              localStorage.setItem(
                                "pending_drafts",
                                JSON.stringify(pending_drafts),
                              );
                              localStorage.setItem(
                                "draft_" + unique_id,
                                JSON.stringify(
                                  JSON.parse(
                                    localStorage.getItem("step_data5"),
                                  ),
                                ),
                              );
                            }
                            localStorage.setItem("unique_identifier", "");
                            localStorage.setItem("step_data5", "");
                            localStorage.setItem("using_agent", "");
                            localStorage.setItem("editMode", "");
                            window.location.href = "/inner";
                          }
                        });
                    }}>
                    <IonIcon
                      style={{fontSize: 30}}
                      icon={refreshCircleOutline}></IonIcon>
                  </a>
                </div>

                <div
                  className={index > 3 ? "col-3 phone_ff" : "col-5 phone_ff"}>
                  <div className="cstdflex cstdflexcol">
                    <div className="cstdflex cstdflexrow has_icon_header">
                      {this.color_mode_show_pay_mode != "yes" ? (
                        false
                      ) : (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              is_schedule_selection:
                                !this.state.is_schedule_selection,
                            });
                          }}>
                          <span className="agent_name_av">
                            {this.context.stepValues["schedule"]}{" "}
                          </span>

                          <img
                            src={
                              require("../assets/new_icons/calendar.png")
                                
                            }
                          />
                        </a>
                      )}

                      <a href="tel:8006699030">
                        <img
                          src={require("../assets/new_icons/phone.png")}
                        />
                      </a>
                    </div>

                    {/* editing end */}

                    <div className="cstdflex cstdflexrow has_icon_header">
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}>
                        <span
                          className="agent_name_av"
                          onClick={() => {
                            this.setState({
                              showingCode: !this.state.showingCode,
                            });
                          }}>
                          {this.state.agent
                            ? this.state.showingCode
                              ? this.context.stepValues["agent-no"]
                              : index > 3
                              ? (
                                  this.context.stepValues["agent-name"] || ""
                                ).split(" ")[0]
                              : this.context.stepValues["agent-name"]
                            : ""}{" "}
                          <span>&rsaquo;</span>
                        </span>
                        <a
                          style={{position: "relative"}}
                          href="/inner"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              menuVisible: true,
                            });
                          }}>
                          <AvatarIcon />
                        </a>
                      </a>
                      {this.state.has_pending_updates ? (
                        <Link
                          style={{position: "relative"}}
                          to="/pendingSubmissions">
                          <img
                            src={require("../assets/new_icons/doc.png")}
                          />

                          <sub className="badge_count">
                            {this.state.pending_updates_count}
                          </sub>
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonHeader>

        <div style={{backgroundColor: "var(--color-mode-bg)"}}>
          <Section.Bar>
            <Progress
              onClickProgress={e => {
                e.preventDefault();
                this.setState({
                  showingCounts: !this.state.showingCounts,
                });
              }}
              noBack
              showingCounts={this.state.showingCounts}
              goBack={() => {
                this.goBack();
              }}
              index={index}
              showCart={
                this.state.is_cart ||
                step.has_cart ||
                this.state.is_schedule_selection
              }
            />
          </Section.Bar>

          {this.state.is_cart ||
          step.has_cart ||
          this.state.is_schedule_selection ? (
            false
          ) : (
            <Section noPaddingBottom noPaddingTop></Section>
          )}

          <Section
            noPaddingTop
            extraClass={"min-h-80-vh" + ( this.state.keyboardShowing ? ' pb300' : '')}
            showCart={this.state.is_cart || step.has_cart}
            showNextButton={step.has_cart}
            showScheduleSelection={this.state.is_schedule_selection}
            cartData={this.context.stepValues}
            cartChange={key => {
              let stepValues_sc = this.context.stepValues;
              stepValues_sc[key] = false;
              this.context.handleChangeStepValues(stepValues_sc);
              this.setState({
                is_cart: false,
              });
            }}
            
            cartDataChange={(key, vv) => {
              let stepValues_sc = this.context.stepValues;
              stepValues_sc[key] = vv;
              this.context.handleChangeStepValues(stepValues_sc);
            }}
            routeParams={this.props.match.params}
            onBack={() => {
              this.setState({
                is_schedule_selection: false,
                is_cart: false,
              });
            }}
            onScheduleChange={new_sc => {
              let stepValues_sc = this.context.stepValues;
              stepValues_sc["schedule"] = new_sc;
              this.context.handleChangeStepValues(stepValues_sc);
              this.setState({
                is_schedule_selection: false,
              });
            }}
            slideOut={this.state.slideOut}
            goToRight={this.state.goToRight}
            slideIn={this.state.slideIn}
            slideOutOpposite={this.state.slideOutOpposite}
            goToLeft={this.state.goToLeft}
            slideInOpposite={this.state.slideInOpposite}>
            <Section.Head step={this.step} cls={step.class || ""}>
              {step.subtitleTop && (
                <p>
                  {step.subtitleTop
                    .replace("<NAME>", this.context.stepValues["first_name"])
                    .replace(
                      "<AGENT_NAME>",
                      this.state.agent && this.state.agent.name
                        ? this.state.agent.name
                        : "",
                    )}
                </p>
              )}
              {step.preTitle ? (
                <h2
                  className={
                    "negMargin " + (step.preTitle.trim() === "" ? " noPd" : "")
                  }>
                  {step.preTitle}
                  {/* {step.is_gi_step ? (
                    <div style={{float: "right", textAlign: "center"}}>
                      <Radio
                        name={"is_gi"}
                        className="topsmall"
                        id={"is_gi"}
                        text={"Guarantee Issue"}
                        type="checkbox"
                        defaultChecked={this.state.showGi}
                        onChange={(a, b, c) => {
                          this.setState({
                            showGi: !this.state.showGi,
                          });
                        }}
                      />
                      {this.state.showGi ? (
                        <Button
                          extraClass="btn_sm_top"
                          onClick={() => {
                            this.setGiStep(step.gi_handler);
                          }}>
                          Confirm G.I.
                        </Button>
                      ) : (
                        false
                      )}
                    </div>
                  ) : (
                    false
                  )} */}
                </h2>
              ) : (
                false
              )}
              {step.is_agent_step ? (
                <>
                <div>
                  <GoBack onAction={this.goBack.bind(this)} />
                  </div>
                <div className="agent_config_select form-control">
                  <select
                    className=""
                    value={this.state.split_template}
                    onChange={e => {
                      this.setAgentConfigName(e.target.value);
                    }}>
                    <option value="">Agent Template</option>
                    <option value="default">Default</option>
                    {this.renderAgentConfigList()}
                  </select>
                </div>
                </>
              ) : (
                false
              )}
              <h1 className={step.titleClass || ""}>
                {(step.title || "").replace("<PLAN>", step.productName)}
              </h1>

              {step.subtitleBold && <h5>{step.subtitleBold}</h5>}

              {step.subtitle && (
                <p className={step.subTitleClass || ""}>
                  {step.subtitle
                    .replace("<NAME>", this.context.stepValues["first_name"])
                    .replace(
                      "<AGENT_NAME>",
                      this.state.agent && this.state.agent.name
                        ? this.state.agent.name
                        : "",
                    )}
                </p>
              )}

              {step.features && (
                <ListFeatures isSmall={step.featuresIsSmall}>
                  {step.features.map((feature, index) => {
                    return (
                      <li key={index}>
                        <p>{feature.text}</p>
                      </li>
                    );
                  })}
                </ListFeatures>
              )}

              {step.subtitleAlt && <p>{step.subtitleAlt}</p>}
            </Section.Head>

            <Section.Body
              step={this.step}
              cls={
                (step.class || "") +
                " " +
                (step.fields ? " has_fields " : "has_no_fields")
              }>
              <div className={"form "}>
                <form action="?" method="post">
                  {step.messages &&
                    step.messages.map((message, index) => {
                      return (
                        <p key={index} className="form__message">
                          {message.text}
                        </p>
                      );
                    })}

                  {/* {step.radioACH && (
                    <Radio
                      className="radio--alt"
                      name={step.radioACH.name}
                      id={step.radioACH.id}
                      text={step.radioACH.text}
                      defaultChecked={true}
                    />
                  )} */}

                  {step.radiosTop && (
                    <div className="radios" style={{marginBottom: 40}}>
                      {step.radiosTop.map((radio, index) => {
                        if (radio.hasAmount) {
                          if (
                            this.context.stepValues["paymentTypeId"] === "140"
                          ) {
                            return false;
                          }
                        }

                        return (
                          <ul
                            style={{marginBottom: 10}}
                            className="radio_card radio_card_4">
                            {radio.label ? (
                              <label className="text-center mb-3 mt-1">
                                {radio.is_bill_box &&
                                this.context.stepValues["paymentTypeId"] ===
                                  "142" ? (
                                  <>
                                    {radio.label_alt}{" "}
                                    <em>
                                      {this.context.stepValues["address"]}
                                    </em>
                                  </>
                                ) : (
                                  radio.label
                                )}
                              </label>
                            ) : (
                              false
                            )}

                            {radio.values.map(radioValue => {
                              if (
                                radioValue.text ===
                                  this.context.stepValues[radio.name] ||
                                radioValue.value ===
                                  this.context.stepValues[radio.name]
                              ) {
                                //
                              } else {
                                if (radio.hasAmount) {
                                  if (
                                    this.context.stepValues["paymentTypeId"] ===
                                    "140"
                                  ) {
                                    return false;
                                  } else if (
                                    this.context.stepValues["paymentTypeId"] ===
                                    "142"
                                  ) {
                                    if (radioValue.multipler === 1) {
                                      return false;
                                    }
                                  }
                                }
                              }
                              return (
                                <li key={radioValue.id}>
                                  <Radio
                                    price={
                                      radio.hasAmount ||
                                      (radio.hasAmount2 && radioValue.multipler)
                                        ? this.getTotals(radioValue.multipler)
                                        : undefined
                                    }
                                    name={radio.name}
                                    value={radioValue.value || radioValue.text}
                                    id={radioValue.id}
                                    defaultChecked={
                                      radioValue.value ===
                                        this.context.stepValues[radio.name] ||
                                      radioValue.text ===
                                        this.context.stepValues[radio.name]
                                    }
                                    text={radioValue.text}
                                    hint={radioValue.hint}
                                    onChange={(a, b, c) => {
                                      this.handleChange(
                                        a,
                                        b,
                                        c,
                                        radioValue.autoProceed,
                                      );
                                      if (a === "paymentTypeId") {
                                        if (b === "140") {
                                          this.handleChange(
                                            "schedule",
                                            "Monthly",
                                          );
                                        }
                                        if (b === "142") {
                                          this.handleChange(
                                            "schedule",
                                            "Quarterly",
                                          );
                                        }
                                      }
                                      // console.log(a, b, c);
                                      // this.setState({
                                      //   showingAll: false,
                                      // });
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        );
                      })}
                    </div>
                  )}

                  {step.fieldCard && (
                    <div
                      className="form__controls plr"
                      style={{marginBottom: 20}}>
                      <InputMask
                        label={step.fieldCard.text}
                        name={step.fieldCard.id}
                        pattern={step.fieldCard.pattern}
                        className="field"
                        placeholder={step.fieldCard.placeholder}
                        mask={step.fieldCard.mask}
                        value={this.context.stepValues[step.fieldCard.id] || ""}
                        onChange={this.handleChangeCard}
                      />

                      <img
                        src={step.fieldCard.icon}
                        alt=""
                        className="form__ico"
                      />
                      <label
                        className={"form__label  fixedlabel "}
                        style={{left: 25, width: "calc(100% - 59px)"}}>
                        Primary SSN
                      </label>
                    </div>
                  )}

                  {step.fieldCard2 && (
                    <div
                      className="form__controls plr"
                      style={{marginBottom: 20}}>
                      <InputMask
                        label={step.fieldCard2.text}
                        name={step.fieldCard2.id}
                        pattern={step.fieldCard2.pattern}
                        className="field"
                        placeholder={step.fieldCard2.placeholder}
                        mask={step.fieldCard2.mask}
                        value={
                          this.context.stepValues[step.fieldCard2.id] || ""
                        }
                        onChange={this.handleChangeCard}
                      />

                      <img
                        src={step.fieldCard2.icon}
                        alt=""
                        className="form__ico"
                      />
                      <label
                        className={"form__label  fixedlabel "}
                        style={{left: 25, width: "calc(100% - 59px)"}}>
                        Spouse SSN
                      </label>
                    </div>
                  )}

                  {disableNextStep &&
                  step.isdobstep &&
                  this.context.stepValues["dob_month"] &&
                  this.context.stepValues["dob_year"] &&
                  (this.context.stepValues["dob_year"] || "").length == 4 &&
                  this.context.stepValues["dob_date"] ? (
                    <p className="form__hint text-danger">
                      {this.isAplicantVeryOld
                        ? "Date of birth must reflect 120 yrs or younger."
                        : this.isAplicantLess18
                        ? "Date of birth must reflect 18 yrs or older."
                        : "Plesae use correct applicant age."}
                    </p>
                  ) : (
                    false
                  )}

                  {step.fields && !step.fields_after_radio && (
                    <div className={"form__row" + (step.fieldsClass || "")}>
                      {step.fields.map((field, index) => {
                        if (field.only_on && field.only_on !== mode)
                          return false;

                        if (field.visible_if && field.visible_if_condition.indexOf(this.context.stepValues[field.visible_if]) < 0)
                          return false;
                        if (
                          field.hideIfhasInternet &&
                          this.state.has_internet
                        ) {
                          return false;
                        }
                        if (field.title) {
                          return (
                            <div
                              key={index}
                              className="form__row form__row--teritary custom_pad">
                              <div className="form__col">
                                <div
                                  className={
                                    "form__controls " +
                                    (field.class ? field.class : "")
                                  }>
                                  <h5>
                                    {field.title}

                                    <i className={field.icon}></i>
                                  </h5>
                                </div>

                                {field.fields.map((field, index) => {
                                  // console.log(field);
                                  return (
                                    <Field
                                      key={index}
                                      name={field.id}
                                      readOnly={field.readonly}
                                      label={field.noLabel ? "" : field.text}
                                      label2={field.text2}
                                      placeholder={field.text}
                                      value={
                                        this.context.stepValues[field.id] ||
                                        field.value
                                      }
                                      onChange={this.handleChange}
                                      isRequired={field.isRequired}
                                      moveToNextAfter={
                                        field.moveToNextAfter
                                          ? field.moveToNextAfter
                                          : false
                                      }
                                      nextFieldId={
                                        field.nextFieldId
                                          ? field.nextFieldId
                                          : false
                                      }
                                      pattern={field.pattern}
                                      upperclass={field.class}
                                      min={field.min}
                                      max={field.max}
                                      maxLength={field.maxLength}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                        // return field.type;
                        if (field.type === "heading") {
                          return (
                            <h3
                              className={field.className || ""}
                              style={{width: "100%", paddingLeft: 20}}>
                              {field.text}
                            </h3>
                          );
                        }

                        return (
                          <div
                            key={index}
                            className={cx(
                              "form__col",
                              field.class || " ",
                              {["form__col--1of2"]: field.isHalf},
                              {["form__col--1of2r"]: field.isHalfr},

                              {"form__col--is20perr": field.is20perr},
                              {"form__col--is40perr": field.is40perr},

                              {"form__col--3of4": field.is34th},
                              {"form__col--1of4": field.is14th},
                              {"form__col--1of4r": field.is14thr},
                              {"form__col--1of5": field.is3070th},
                              {lesspsacebelow: field.lesspsacebelow},
                              {["form__col--small"]: field.isSmall},
                            )}>
                            <Field
                              name={field.id}
                              label={field.noLabel ? "" : field.text}
                              label2={field.text2}
                              placeholder={field.text}
                              forcePlaceholder={field.forcePlaceholder}
                              value={
                                this.context.stepValues[field.id] || field.value
                              }
                              prefix={field.prefix}
                              fixedLabel={field.fixedLabel}
                              suffix={field.suffix}
                              inputMode={field.inputmode}
                              options={field.options}
                              onChange={this.handleChange}
                              isRequired={field.isRequired}
                              pattern={field.pattern}
                              maxLength={field.maxLength}
                              type={field.type}
                              max={field.max}
                              min={field.min}
                            />
                            {field.showExtraInfo ? (
                              <div className="list-group">
                                <div className="list-group-item">
                                  <span>Max Montly Benefit</span>
                                  <span className="float-right">
                                    {this.context.stepValues["f-annual-salary"]
                                      ? "$" +
                                        this.showAmnt(
                                          (
                                            (this.context.stepValues[
                                              "f-annual-salary"
                                            ] *
                                              (60 / 100)) /
                                            12
                                          ).toFixed(0),
                                        )
                                      : 0.0}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {step.showLoading ? (
                    <div className="radio nocircle standout">
                      <label className="min_height_radio " for="product_6">
                        Please wait....
                      </label>
                    </div>
                  ) : (
                    false
                  )}

                  {step.checkboxes && !step.ckeckboxes_below_fields && (
                    <div className={"radios " + (step.radio_class || "")}>
                      {step.checkboxes.map((radio, index) => {
                        return (
                          <ul className="radio_card radio_card_1">
                            {radio.label ? <br /> : false}
                            {radio.label ? (
                              <label className="text-center mb-3 mt-1">
                                {radio.label}
                              </label>
                            ) : (
                              false
                            )}
                            {radio.values.map(radioValue => (
                              <li key={radioValue.id}>
                                <Radio
                                  showBrochure={
                                    step.is_plan_step ? true : false
                                  }
                                  brochureState={
                                    this.context.stepValues["state"]
                                  }
                                  brochurePlan={radioValue.id.replace(
                                    "product_",
                                    "",
                                  )}
                                  type="checkbox"
                                  name={radio.id}
                                  subtext={radioValue.subtext}
                                  id={radioValue.id}
                                  // defaultChecked={radioValue.isChecked}
                                  standout={radioValue.standout}
                                  hasMinHeight
                                  trueFalseMode
                                  text={radioValue.text}
                                  disabled={radioValue.disabled}
                                  disabledAge={radioValue.disabledAge}
                                  hint={radioValue.hint}
                                  onChange={this.handleChange}
                                  defaultChecked={
                                    true ===
                                    this.context.stepValues[radioValue.id]
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        );
                      })}
                    </div>
                  )}
                  {step.preradiolabel ? (
                    <label className={"text-center preradiolabel mt-1"}>
                      {step.preradiolabel}
                    </label>
                  ) : (
                    false
                  )}
                  {step.radios && (
                    <div
                      className={
                        "radios " + (step.radiosClass ? step.radiosClass : "")
                      }>
                      {step.radios.map((radio, index) => {
                        if (radio.has_visible_if) {
                          if (
                            this.context.stepValues[radio.visible_if] !==
                            radio.visibleIfCondition
                          ) {
                            return false;
                          }
                        }
                        return (
                          <ul
                            className={
                              "  radio_card radio_card_2 " +
                              (radio.extraClass ? "  " + radio.extraClass : "")
                            }>
                            {/* {radio.label && radio.values.length > 1 ? <br /> : false} */}
                            {radio.label && (radio.values.length > 1  )? (
                              <label
                                className={
                                  "text-center mb-3 mt-1" +
                                  (radio.isdib ? "  raddib" : "")
                                }>
                                {radio.label}
                              </label>
                            ) : (
                              false
                            )}
                            {radio.values.map(radioValue => {
                              if (radioValue.has_visible_if) {
                                if (
                                  this.context.stepValues[
                                    radioValue.visible_if
                                  ] !== radioValue.visibleIfCondition
                                ) {
                                  return false;
                                }
                              }
                              return (
                                <li
                                  key={radioValue.id}
                                  className={
                                    "ppg " +
                                    (radio.isHalfHalf ? "is_half_half " : " ") +
                                    (radio.isdib ? " dib" : "")
                                  }>
                                  {/* {
                                    this.context.stepValues[radio.name]
                                  }
                                  | 
                                  {
                                    radioValue.value
                                  } | 
                                  {
                                    radioValue.text
                                  }  */}
                                  <Radio
                                    name={radio.name}
                                    className={radio.className}
                                    //  showBrochure={step.is_price_step}
                                    //  brochureState={ this.context.stepValues['state'] }
                                    //  brochurePlan={ radio.name }
                                    id={radioValue.id}
                                    trueFalseMode={
                                      radioValue.trueFalseMode || false
                                    }
                                    trueRepresentative={
                                      radioValue.trueRepresentative || false
                                    }
                                    type={radio.values.length == 1 && radioValue.text  == 'Yes' ? 'checkbox' : radioValue.type || "radio"}
                                    defaultChecked={
                                      (radioValue.text &&
                                        radioValue.text ===
                                          this.context.stepValues[
                                            radio.name
                                          ]) ||
                                      (radioValue.value &&
                                        radioValue.value ===
                                          this.context.stepValues[
                                            radio.name
                                          ]) ||
                                      false
                                    }
                                    value={radioValue.value}
                                    text={radio.values.length == 1 && radioValue.text  == 'Yes' ? radioValue.label  : radioValue.text}
                                    hint={radioValue.hint}
                                    onChange={(a, b, c) => {
                                      // console.log(radioValue.autoProceed)
                                      this.handleChange(
                                        a,
                                        b,
                                        c,
                                        radioValue.autoProceed,
                                      );
                                      setTimeout(() => {
                                        this.setState({
                                          showing_prices: false,
                                        });
                                      }, 800);
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        );
                      })}
                    </div>
                  )}

                  {step.fields && step.fields_after_radio && (
                    <div className="form__row" style={{marginBottom: 20}}>
                      <h4
                        style={{
                          marginTop: 20,
                          textAlign: "left",
                          width: "100%",
                        }}>
                        Spouse Details
                      </h4>

                      {step.fields.map((field, index) => {
                        if (field.title) {
                          return (
                            <div
                              key={index}
                              className="form__row form__row--teritary custom_pad">
                              <div className="form__col">
                                <div
                                  className={
                                    "form__controls " +
                                    (field.class ? field.class : "")
                                  }>
                                  <h5>
                                    {field.title}

                                    <i className={field.icon}></i>
                                  </h5>
                                </div>

                                {field.fields.map((field, index) => {
                                  // console.log(field)
                                  return (
                                    <Field
                                      key={index}
                                      name={field.id}
                                      label={field.noLabel ? "" : field.text}
                                      readOnly={field.readonly}
                                      type={field.type || "text"}
                                      placeholder={field.text}
                                      value={
                                        this.context.stepValues[field.id] ||
                                        field.value
                                      }
                                      onChange={this.handleChange}
                                      isRequired={field.isRequired}
                                      moveToNextAfter={
                                        field.moveToNextAfter
                                          ? field.moveToNextAfter
                                          : false
                                      }
                                      nextFieldId={
                                        field.nextFieldId
                                          ? field.nextFieldId
                                          : false
                                      }
                                      pattern={field.pattern}
                                      upperclass={field.class}
                                      maxLength={field.maxLength}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }

                        // vasu

                        return (
                          <div
                            key={index}
                            className={cx(
                              "form__col",
                              {["form__col--1of2"]: field.isHalf},
                              {"form__col--3of4": field.is34th},
                              {"form__col--1of5": field.is3070th},
                              {["form__col--small"]: field.isSmall},
                            )}>
                            <Field
                              name={field.id}
                              label={field.noLabel ? "" : field.text}
                              label2={field.text2}
                              placeholder={field.text}
                              value={
                                this.context.stepValues[field.id] || field.value
                              }
                              mask="99/99/9999"
                              maskChar="_"
                              onChange={(a, b)=>{
                                console.log(a, b );
                                if( a == 'spouse-full-name')
                                {

                                  this.handleChange(a, b);
                                  setTimeout(()=>{
                                    this.handleChange('spouse-signature', b);
                                  }, 1000)
                                }else{
                                  this.handleChange(a, b);

                                }
                              }}
                              isRequired={field.isRequired}
                              pattern={field.pattern}
                              upperclass={field.class}
                              type={field.type}
                              max={field.max}
                              min={field.min}
                            />
                          </div>
                        );
                      })}
                      {step.showSpouseGender ? (
                        <div
                          className="drow genderdRow"
                          style={{marginTop: -10}}>
                          <Radio
                            className="gender_radio_custom"
                            onChange={(a, b) => {
                              this.handleChange("spouse-gender", b);
                              jquery("#spouse-gender_Male")
                                .attr("checked", "checked")
                                .prop("checked", true);
                            }}
                            defaultChecked={
                              this.context.stepValues["spouse-gender"] ===
                              "Male"
                            }
                            id={"spouse_gender_Male_2"}
                            name={"spouse-gender2"}
                            value={"Male"}
                            text="Male"
                          />
                          <Radio
                            className="gender_radio_custom"
                            onChange={(a, b) => {
                              this.handleChange("spouse-gender", b);
                              jquery("#spouse-gender_Female")
                                .attr("checked", "checked")
                                .prop("checked", true);
                            }}
                            defaultChecked={
                              this.context.stepValues["spouse-gender"] ===
                              "Female"
                            }
                            id={"spouse_gender_Female_2"}
                            name={"spouse-gender2"}
                            value={"Female"}
                            text="Female"
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  )}

                  {(!step.is_price_step ||
                    (this.context.stepValues &&
                      this.context.stepValues[this.plan + "_radio"] &&
                      this.context.stepValues[this.plan + "_radio"] !==
                        "Self")) &&
                    step.formTitle && (
                      <h1 className="form__title">{step.formTitle}</h1>
                    )}

                  {(!step.is_price_step ||
                    (this.context.stepValues &&
                      this.context.stepValues[this.plan + "_radio"] &&
                      this.context.stepValues[this.plan + "_radio"] !==
                        "Self")) &&
                    step.dates &&
                    step.dates.map((field, index) => {
                      //  console.log(this.context.stepValues[this.plan + "_radio"] )
                      if (
                        this.context.stepValues[this.plan + "_radio"] ===
                        "Self & Spouse"
                      ) {
                        if (index > 1) return false;
                      }
                      if (
                        this.context.stepValues[this.plan + "_radio"] ===
                          "Self & Child" &&
                        this.plan == "product_138"
                      ) {
                        if (index === 1 || index > 2) return false;
                      } else if (
                        this.context.stepValues[this.plan + "_radio"] ===
                        "Self & Child"
                      ) {
                        if (index === 1) return false;
                      }
                      if (
                        this.context.stepValues[this.plan + "_radio"] ===
                        "Single Parent Family"
                      ) {
                        if (index === 1) return false;
                      }
                      return (
                        <div className="form__row-outer p-0" key={index}>
                          {/* <h5>{}</h5> */}

                          <div className="form__row form__row--secondary dependent_row">
                            <div className="dcol">
                              <div className="drow">
                                <div className="form__col form__col--size1 dep_row_item_1">
                                  <Field
                                    name={field.fieldName.id}
                                    fixedLabel={true}
                                    label={"first_last"}
                                    label2={field.text2 || ""}
                                    // placeholder={field.fieldName.text}
                                    // disableLabel={true}
                                    value={
                                      this.context.stepValues[
                                        field.fieldName.id
                                      ]
                                        ? this.context.stepValues[
                                            field.fieldName.id
                                          ]
                                        : index === 0
                                        ? this.context.stepValues[
                                            "first_name"
                                          ] +
                                          " " +
                                          this.context.stepValues["last_name"]
                                        : ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                </div>

                                <div className="form__col form__col--size2 dep_row_item_2">
                                  <div className={"form__controls"}>
                                    {field.fieldDate.id === "spouse-date" ? (
                                      <InputMask
                                        readOnly
                                        className="field"
                                        mask="99/99/9999"
                                        name={field.fieldDate.id}
                                        label={"Date of Birth"}
                                        // fixedLabel
                                        // placeholder={field.fieldDate.text}
                                        value={
                                          this.context.stepValues[
                                            field.fieldDate.id
                                          ]
                                            ? this.context.stepValues[
                                                field.fieldDate.id
                                              ]
                                            : index === 0
                                            ? this.context.stepValues[
                                                "dob_month"
                                              ] +
                                              "/" +
                                              this.context.stepValues[
                                                "dob_date"
                                              ] +
                                              "/" +
                                              this.context.stepValues[
                                                "dob_year"
                                              ]
                                            : ""
                                        }
                                        onChange={this.handleChangeDate}
                                      />
                                    ) : (
                                      <InputMask
                                        className="field"
                                        mask="99/99/9999"
                                        // fixedLabel={true}
                                        name={field.fieldDate.id}
                                        label={"Date of Birth"}
                                        // placeholder={field.fieldDate.text}
                                        value={
                                          this.context.stepValues[
                                            field.fieldDate.id
                                          ]
                                            ? this.context.stepValues[
                                                field.fieldDate.id
                                              ]
                                            : index === 0
                                            ? this.context.stepValues[
                                                "dob_month"
                                              ] +
                                              "/" +
                                              this.context.stepValues[
                                                "dob_date"
                                              ] +
                                              "/" +
                                              this.context.stepValues[
                                                "dob_year"
                                              ]
                                            : ""
                                        }
                                        // onChange={this.handleChangeDate}
                                        onChange={(a, b, c, d) => {
                                          this.handleChangeDate(a, b, c, d);
                                          this.setState({
                                            showing_prices: false,
                                          });
                                        }}
                                      />
                                    )}

                                    <label
                                      className={
                                        "form__label doblabel fixedlabel" +
                                        (this.context.stepValues[
                                          field.fieldDate.id
                                        ] || index === 0
                                          ? " fixedlabel "
                                          : "  ")
                                      }
                                      htmlFor={field.fieldDate.id}>
                                      {"Date of Birth"}
                                    </label>
                                  </div>
                                </div>

                                <div className="form__col form__col--size3 dep_row_item_3">
                                  <div className="form__controls">
                                    <InputMask
                                      label={"Ht"}
                                      name={field.fieldH.id}
                                      pattern={
                                        step.disableHtWt
                                          ? ""
                                          : field.fieldH.pattern
                                      }
                                      className="field"
                                      disabled={step.disableHtWt}
                                      // placeholder={}
                                      mask={step.disableHtWt ? "**" : "9'99\""}
                                      maskChar="-"
                                      // disableUnderline
                                      value={
                                        step.disableHtWt
                                          ? "--"
                                          : this.context.stepValues[
                                              field.fieldH.id
                                            ]
                                          ? this.context.stepValues[
                                              field.fieldH.id
                                            ]
                                          : ""
                                      }
                                      onChange={e => {
                                        if (field.fieldH.id === "height-ftin") {
                                          let val = e.target.value;
                                          if (!val) {
                                            this.handleChangeDate({
                                              target: {
                                                name: "",
                                                value: "",
                                              },
                                            });
                                          }
                                          val = val.split("'");
                                          // console.log("val: ", val);
                                          let ft = val[0];
                                          let inc =
                                            val.length > 1 ? val[1] : "";
                                          // inc
                                          inc = inc.replace('"', "");
                                          inc = inc.replace("_", "");
                                          this.handleChangeDate({
                                            target: {
                                              name: "height-ft",
                                              value: ft,
                                            },
                                          });
                                          this.handleChangeDate({
                                            target: {
                                              name: "height-in",
                                              value: inc,
                                            },
                                          });
                                          this.handleChangeDate(e);

                                          return;
                                        }
                                        // stepValues[e.target.name] = e.target.value;
                                        this.handleChangeDate(e);
                                      }}
                                    />

                                    <label className="form__label doblabel fixedlabel">
                                      {"Ht"}
                                    </label>
                                  </div>
                                </div>

                                <div className="form__col form__col--size4 dep_row_item_4 ff">
                                  <Field
                                    name={field.fieldW.id}
                                    label={"Wt"}
                                    disable={step.disableHtWt}
                                    fixedLabel
                                    pattern={field.fieldW.pattern}
                                    // placeholder={field.fieldW.text}
                                    // disableLabel={true}
                                    value={
                                      this.context.stepValues[field.fieldW.id]
                                        ? this.context.stepValues[
                                            field.fieldW.id
                                          ]
                                        : ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="drow mt-4 genderdRow">
                                {/* <div className="form__col form__col--size2 dep_row_item_3"> */}
                                {/* <div className="form__controls"> */}
                                <Radio
                                  className="gender_radio_custom"
                                  onChange={(a, b) => {
                                    this.handleChange(a, b);
                                    this.setState({
                                      showing_prices: false,
                                    });
                                    if (a === "spouse-gender") {
                                      $("#spouse_gender_Male_2")
                                        .attr("checked", "checked")
                                        .prop("checked", true);
                                    }
                                  }}
                                  defaultChecked={
                                    this.context.stepValues[
                                      field.fieldGender.id
                                    ] === "Male"
                                  }
                                  id={field.fieldGender.id + "_Male"}
                                  name={field.fieldGender.id}
                                  value={"Male"}
                                  text="Male"
                                />
                                <Radio
                                  className="gender_radio_custom"
                                  onChange={(a, b) => {
                                    this.handleChange(a, b);
                                    this.setState({
                                      showing_prices: false,
                                    });
                                    if (a === "spouse-gender") {
                                      $("#spouse_gender_Female_2")
                                        .attr("checked", "checked")
                                        .prop("checked", true);
                                    }
                                  }}
                                  defaultChecked={
                                    this.context.stepValues[
                                      field.fieldGender.id
                                    ] === "Female"
                                  }
                                  id={field.fieldGender.id + "_Female"}
                                  name={field.fieldGender.id}
                                  value={"Female"}
                                  text="Female"
                                />
                                {/* <Field
                                  label={'Gender'}
                                  name={field.fieldGender.id}
                                  className="field"
                                 
                                  value={
                                    this.context.stepValues[field.fieldGender.id]
                                      ? this.context.stepValues[field.fieldGender.id]
                                      : ''
                                  }
                                  type='select'
                                  options={['Male', 'Female']}
                                 
                                  onChange={this.handleChange}
                
                                /> */}

                                {/* <label className="form__label doblabel fixedlabel"> */}
                                {/* {'Gender'} */}
                                {/* </label> */}
                                {/* </div> */}

                                {/* </div> */}
                              </div>
                            </div>

                            <Checkbox
                              id={field.checkbox.id}
                              checked={
                                index === 0 ||
                                (index === 1 &&
                                  this.context.stepValues[
                                    this.plan + "_radio"
                                  ] !== "Self")
                                  ? true
                                  : this.context.stepValues[field.checkbox.id]
                                  ? true
                                  : false
                              }
                              onChange={(a, b, c, d) => {
                                this.handleChange(a, b, c, d);
                                this.setState({
                                  showing_prices: false,
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {step.dates &&
                    (!step.is_price_step ||
                      (this.context.stepValues &&
                        this.context.stepValues[this.plan + "_radio"] &&
                        this.context.stepValues[this.plan + "_radio"] !==
                          "Self")) && (
                      <div className="form__actions">
                        <Button
                          color="border"
                          type="button"
                          size="small"
                          onClick={e => {
                            if (
                              this.plan == "product_138" &&
                              (this.context.stepValues[this.plan + "_radio"] ===
                                "Self & Child" ||
                                this.context.stepValues[
                                  this.plan + "_radio"
                                ] === "Self & Spouse")
                            ) {
                              e.preventDefault();
                              window
                                .swal(
                                  "Please change Plan Type to Perform this seclection",
                                  {
                                    dangerMode: false,
                                    className: "noflexbtns",

                                    buttons: {
                                      confirm: {
                                        text: "Change to Family Coverage",
                                        value: "change",
                                        visible: true,
                                        className: "",
                                        closeModal: true,
                                      },
                                      cancel: {
                                        text: "Remain on current Selection",
                                        value: "remain",
                                        visible: true,
                                        className: "",
                                        closeModal: true,
                                      },
                                    },
                                  },
                                )
                                .then(value => {
                                  if (value === "change") {
                                    this.handleChange(
                                      this.plan + "_radio",
                                      "Family",
                                    );
                                    jquery(
                                      '[name="' +
                                        this.plan +
                                        '_radio"][value="Family"]',
                                    ).prop("checked", true);
                                  } else {
                                  }
                                });
                              // alert("Choose \"Family\" option to perform this action.");
                              return;
                            }

                            this.handleAddRow(e);
                          }}>
                          <i className="ico-plus"></i>
                          Add Dependent
                        </Button>
                      </div>
                    )}

                  {(!step.is_price_step ||
                    (this.state.showing_prices &&
                      this.context.stepValues &&
                      this.context.stepValues[this.plan + "_radio"])) &&
                    step.formHead && (
                      <div className="form__head">
                        {/* <h1>{step.formHead.formHeadTitle}</h1> */}

                        <h1>
                          {step.formHead.formHeadTitle.replace(
                            "<PLAN>",
                            step.productName,
                          )}

                          {step.is_price_step && this.state.showing_prices ? (
                            <a
                              onClick={e => {
                                openUrl(
                                  e,
                                  "https://app.mhlez.com/api/manhattan.api.php?action=brochure&product_id=" +
                                    (this.plan || "").replace("product_", "") +
                                    "&state_code=" +
                                    (
                                      this.context.stepValues["state"] || ""
                                    ).toUpperCase(),
                                );
                              }}
                              href={
                                "https://app.mhlez.com/api/manhattan.api.php?action=brochure&product_id=" +
                                (this.plan || "").replace("product_", "") +
                                "&state_code=" +
                                (
                                  this.context.stepValues["state"] || ""
                                ).toUpperCase()
                              }
                              target="_blank"
                              style={{
                                fontSize: 11,
                                // float:'right',
                                // position: 'absolute',
                                // right: '20px',
                                fontWeight: "bold",

                                display: "block",
                                float: "right",
                                marginBottom: 10,
                                textAlign: "right",
                                marginTop: 10,
                              }}>
                              <img
                                alt="brochure download"
                                src={require("../assets/brochure.png")}
                                className="brochure_img"
                              />
                            </a>
                          ) : (
                            false
                          )}
                        </h1>

                        <p>
                          {(step.formHead.formHeadSubtitle || "").replace(
                            "{{radio_mode}}",

                            step.radios &&
                              step.radios.length &&
                              step.radios[0].name
                              ? this.context.stepValues[step.radios[0].name]
                              : "",
                          )}
                        </p>
                      </div>
                    )}

                  {(!step.is_price_step ||
                    (this.state.showing_prices &&
                      this.context.stepValues &&
                      this.context.stepValues[this.plan + "_radio"])) &&
                    step.radiosAlt && (
                      <div className="radios-alt">
                        {step.radiosAlt.map((radio, index) => {
                          // if(radio.showTitle)
                          return (
                            <ul
                              className={
                                "radio_card radio_card_5" +
                                " " +
                                (radio.class || "")
                              }>
                              {radio.preTitle ? (
                                <p style={{textAlign: "center"}}>
                                  {radio.preTitle}
                                </p>
                              ) : (
                                false
                              )}
                              {radio.showTitle ? <h1>{radio.title}</h1> : false}
                              {radio.values.map(radioValue => {
                                //  console.log(radio.values.length, radio.values)
                                //  console.log(this.context.stepValues[radio.name], radioValue.text, this.context.stepValues[radio.name] ===
                                // radioValue.text)
                                //  if (this.context.stepValues[radio.name]) {
                                //    if (
                                //     this.context.stepValues[radio.name] ===
                                //     radioValue.id || this.context.stepValues[radio.name] ===
                                //     radioValue.text
                                //    ) {
                                //    } else {
                                //      return false;
                                //    }
                                //  }
                                // console.log(radioValue.price)
                                if (
                                  !radioValue.price ||
                                  radioValue.price == "0" ||
                                  radioValue.price == 0
                                ) {
                                  return false;
                                }

                                let forceDisabled = false;
                                if (
                                  step.is_price_step &&
                                  this.state.showing_prices
                                ) {
                                  console.log("---------------");
                                  console.log(this.state.inactive_plans);
                                  console.log(radioValue);
                                  let id_ = radioValue.id;
                                  id_ = id_.split("|"); //"product_138|planUnit2188"
                                  id_ = id_[0];
                                  id_ = id_.replace("product_", "");
                                  console.log(id_);

                                  let tmp_ = id_ + "_" + radioValue.text;
                                  console.log(tmp_);
                                  if (
                                    this.state.inactive_plans &&
                                    this.state.inactive_plans.indexOf(tmp_) > -1
                                  ) {
                                    console.log('-disabled')
                                    forceDisabled = true;
                                  }
                                }
                                
                                return (
                                  <li key={radioValue.id}>
                                    <RadioAlt
                                      name={radio.name}
                                      id={radioValue.id}
                                      defaultChecked={
                                        radioValue.id ===
                                          this.context.stepValues[radio.name] ||
                                        radioValue.text ===
                                          this.context.stepValues[radio.name]
                                      }
                                      //  keyToUse={'id'}
                                      value={radioValue.id}
                                      text={radioValue.text}
                                      disabled={
                                        forceDisabled || radioValue.disabled
                                      }
                                      features={radioValue.features}
                                      price={this.getPrice(radioValue.price)}
                                      forceType={
                                        radioValue.forceType || "radio"
                                      }
                                      trueFalseMode
                                      schedule={
                                        this.context.stepValues["schedule"]
                                      }
                                      currentVal={
                                        this.context.stepValues[radio.name]
                                      }
                                      onChange={(a, b, c, d, e, f) => {
                                        this.handleChange(a, b, c, d, e, f);
                                      }}
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          );
                        })}
                        {/* </ul> */}
                      </div>
                    )}

                  {(!step.is_price_step ||
                    (this.state.showing_prices &&
                      this.context.stepValues &&
                      this.context.stepValues[this.plan + "_radio"])) &&
                    step.checkboxes &&
                    step.ckeckboxes_below_fields && (
                      <div className="radios mb-2 width-100">
                        <ul>
                          {step.checkboxes.map((radio, index) => {
                            return (
                              <div className="radio_card radio_card_3">
                                {/* {radio.label ? <br /> : false} */}
                                {radio.label ? (
                                  <label className="text-center mb-3 mt-1">
                                    {radio.label}
                                  </label>
                                ) : (
                                  false
                                )}
                                {radio.values.map(radioValue => (
                                  <li key={radioValue.id}>
                                    <Radio
                                      type="checkbox"
                                      name={radio.id}
                                      subtext={radioValue.subtext}
                                      id={radioValue.id}
                                      // defaultChecked={radioValue.isChecked}
                                      standout={radioValue.standout}
                                      // hasMinHeight
                                      trueFalseMode
                                      text={radioValue.text}
                                      disabled={radioValue.disabled}
                                      hint={radioValue.hint}
                                      price={this.getPrice(radioValue.price)}
                                      onChange={(a, b, c, d, e, f) => {
                                        this.handleChange(a, b, c, d, e, f);
                                        setTimeout(() => {
                                          this.setState({
                                            showing_prices: false,
                                          });
                                        }, 800);
                                      }}
                                      defaultChecked={
                                        true ===
                                        this.context.stepValues[radioValue.id]
                                      }
                                    />
                                  </li>
                                ))}
                              </div>
                            );
                          })}
                        </ul>
                        {/* </ul> */}
                      </div>
                    )}

                  {step.show_total ? (
                    <div
                      style={{
                        paddingTop: 10,
                        marginTop: 20,
                        borderTop: "1px solid #b9b8b8",
                        marginBottom: 30,
                      }}>
                      <h4>
                        Total :{" "}
                        <span className="float-right">
                          ${step.show_total_amnt}
                        </span>
                      </h4>
                    </div>
                  ) : (
                    false
                  )}

                  {step.contact && (
                    <div className="contact_box">
                      <div className="form__row">
                        <div className="form__col has_field_icon pb-0">
                          {/* <PhoneInput
                            onlyCountries={["us"]}
                            country={"us"}
                            placeholder={""}
                            required={true}
                            value={this.context.stepValues["contact-phone"]}
                            masks={{ us: "(...) ... ...." }}
                            onChange={this.handleChangePhone}
                          />   */}
                          <span className="field_icon">
                            <img src={step.contact.phone.icon} alt="" />
                          </span>
                          <div className="form__controls ">
                            <InputMask
                              // label={"Phone Number"}
                              type="tel"
                              name={"contact-phone"}
                              // pattern={step.fieldCard.pattern}
                              className="field"
                              // placeholder={"(XXX) XXX-XX-XX"}
                              mask="(999) 999-9999"
                              value={
                                this.context.stepValues["contact-phone"] || ""
                              }
                              onChange={this.handleChangeCard}
                            />
                            <label
                              className={
                                "form__label  " +
                                (this.context.stepValues["contact-phone"]
                                  ? " fixedlabel"
                                  : " ")
                              }>
                              Phone Number
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="dib plr align-right width-100per">
                        <button
                          className="small_btn btn--border btn btn--small"
                          style={{marginTop: 4}}
                          type="button"
                          onClick={() => {
                            let agent_info = localStorage.getItem("agent_info");
                            agent_info = JSON.parse(agent_info || "{}");
                            let phone = agent_info.phone || "";
                            if (phone[0] == "+" && phone[1] == "1") {
                              //
                            } else {
                              phone = "1" + phone;
                            }
                            this.handleChange("contact-phone", phone);
                          }}>
                          Substitute Agent's Tel. No.
                        </button>
                      </div>
                      <div className="form__row">
                        <div className="form__col has_field_icon pb-0">
                          <span className="field_icon">
                            <img src={step.contact.email.icon} alt="" />
                          </span>
                          <Field
                            key={index}
                            name={"email"}
                            type="email"
                            label={"Email address"}
                            // placeholder={field.text}
                            value={this.context.stepValues["email"]}
                            onChange={(a, b)=>{
                              
                              this.handleChange(a, b);
                              if (b && this.isValidEmail(b)) {
                                this.handleChange(
                                  "decline-email-contact",
                                  "no",
                                );
                              } else {
                                this.handleChange(
                                  "decline-email-contact",
                                  "yes",
                                );
                              }
                            }}
                            isRequired={false}
                          />
                        </div>
                      </div>
                      <div
                        className="dib plr width-100per"
                        style={{fontSize: 12}}>
                        <span></span>
                        <div className="is_small_radio small_btn btn--border btn btn--small">
                          <Radio
                            trueFalseMode
                            type="checkbox"
                            defaultChecked={
                              this.context.stepValues["mail_to_customer"]
                            }
                            name="mail_to_customer"
                            id="mail_to_customer"
                            text="E-mail copy to Applicant"
                            trueRepresentative="yes"
                            onChange={this.handleChange}
                            disabled={
                              !this.context.stepValues["email"] ||
                              !this.isValidEmail(
                                this.context.stepValues["email"],
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {step.questionnaire &&
                    step.questionnaire.map((field, index) => {
                      return (
                        <div key={index} className="form__row-def">
                          <p className="mb-0">{field.text}</p>

                          {field.radios && field.radios.length ? (
                            <div className="radios radios--alt question_box_set">
                              <ul>
                                {field.radios.map((radio, index) => {
                                  return radio.values.map(radioValue => (
                                    <li
                                      key={radioValue.id}
                                      className={
                                        radio.isHalfHalf ? "is_half_half " : " "
                                      }>
                                      {radioValue.hasExtLabel ? (
                                        <label
                                          style={{
                                            padding: 0,
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}>
                                          {radioValue.hasExtLabel}
                                        </label>
                                      ) : radio.isHalfHalf || radio.isHalf2 ? (
                                        <label
                                          style={{padding: 0, fontSize: 15}}>
                                          {" "}
                                          {""}&nbsp;
                                        </label>
                                      ) : (
                                        false
                                      )}
                                      <Radio
                                        name={radioValue.name || radio.name}
                                        id={radioValue.id}
                                        text={radioValue.text}
                                        hint={radioValue.hint}
                                        onChange={this.handleChange}
                                      />
                                    </li>
                                  ));
                                })}
                              </ul>
                            </div>
                          ) : (
                            false
                          )}
                          {/* {
                              JSON.stringify(field)
                            }
                            {field.textarea ? this.context.stepValues[ field.textarea.visible_if] : '-'} */}

                          {field.textarea &&
                            (!field.textarea.visible_if ||
                              this.context.stepValues[
                                field.textarea.visible_if
                              ] === field.textarea.visibleIfCondition) && (
                              <Textarea
                                name={field.textarea.id}
                                label={field.textarea.text}
                                placeholder={field.textarea.text}
                                value={
                                  this.context.stepValues[field.textarea.id]
                                }
                                onChange={this.handleChange}
                              />
                            )}
                        </div>
                      );
                    })}

                  {step.textarea &&
                  (!step.textarea.visible_if ||
                    this.context.stepValues[step.textarea.visible_if] ===
                      step.textarea.visibleIfCondition) ? (
                    step.textarea.type === "text" ? (
                      <Field
                        name={step.textarea.id}
                        label={step.textarea.text}
                        inputMode={step.textarea.inputmode}
                        prefix={step.textarea.prefix}
                        upperclass={step.textarea.className || ""}
                        placeholder={step.textarea.text}
                        fixedLabel={step.textarea.fixedLabel}
                        value={this.context.stepValues[step.textarea.id]}
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Textarea
                        fixedlabel={step.textarea.fixedlabel}
                        name={step.textarea.id}
                        label={step.textarea.text}
                        prefix={step.textarea.prefix}
                        placeholder={step.textarea.text}
                        value={this.context.stepValues[step.textarea.id]}
                        onChange={this.handleChange}
                      />
                    )
                  ) : (
                    false
                  )}
                  {step.textareaText &&
                  step.textarea &&
                  (!step.textarea.visible_if ||
                    this.context.stepValues[step.textarea.visible_if] ===
                      step.textarea.visibleIfCondition) ? (
                    <span className="note_msg">{step.textareaText}</span>
                  ) : (
                    false
                  )}

                  {step.textarea2 &&
                  (!step.textarea2.visible_if ||
                    this.context.stepValues[step.textarea2.visible_if] ===
                      step.textarea2.visibleIfCondition) ? (
                    step.textarea2.type === "text" ? (
                      <Field
                        name={step.textarea2.id}
                        label={step.textarea2.text}
                        inputMode={step.textarea2.inputmode}
                        prefix={step.textarea2.prefix}
                        upperclass={step.textarea2.className || ""}
                        placeholder={step.textarea2.text}
                        fixedLabel={step.textarea2.fixedLabel}
                        value={this.context.stepValues[step.textarea2.id]}
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Textarea
                        fixedlabel={step.textarea2.fixedlabel}
                        name={step.textarea2.id}
                        label={step.textarea2.text}
                        prefix={step.textarea2.prefix}
                        placeholder={step.textarea2.text}
                        value={this.context.stepValues[step.textarea2.id]}
                        onChange={this.handleChange}
                      />
                    )
                  ) : (
                    false
                  )}

                  {step.logo && (
                    <div className="form__image">
                      <img src={step.logo} alt="" />
                    </div>
                  )}

                  {step.hasClearOption ? (
                    <>
                      <Button
                        color="border"
                        extraClass="clearaddress"
                        type="button"
                        size="small"
                        onClick={e => {
                          e.preventDefault();
                          this.handleChange("zip", "");
                          this.handleChange("city", "");
                          this.handleChange("state", "");
                          this.handleChange("address", "");
                        }}>
                        <i className="ico-plus rotate-45"></i>
                        Clear
                      </Button>
                      <div className="mail_policy_to_wrap">
                        <strong>Mail Policy To</strong>
                        <div>
                          <select
                            className="mail_policy_to_select"
                            onChange={e => {
                              this.handleChange(
                                "mail_policy_to",
                                e.target.value,
                              );
                            }}
                            value={this.context.stepValues["mail_policy_to"]}>
                            <option value="Insured">Insured</option>
                            <option value="Agent">Agent</option>
                            {this.context.stepValues["mode"] == "payroll" ? (
                              <option value="Employer">Employer</option>
                            ) : (
                              false
                            )}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}

                  {/* {step.hints &&
                    step.hints.map((hint, index) => {
                      return (
                        <p key={index} className="form__hint">
                          {hint.text}
                        </p>
                      );
                    })} */}
                </form>
              </div>
              {this.state.submitted ? <Redirect to="/submitted" /> : false}
              {this.state.goingBack ? (
                <Redirect to={`/mode/${mode}/steps/${--index}`} />
              ) : (
                false
              )}
 {this.state.goingBackToStep ? (
              <Redirect to={`/mode/${mode}/steps/${this.goingBackTo}`} /> 
                
              ) : (
                false
              )}  
 

              {step.giSetup ? (
                <div
                  style={{
                    float: "right",
                    textAlign: "center",
                    marginTop: 30,
                    marginBottom: this.state.showingGender ? 0 : 30,
                  }}>
                  <Radio
                    name={"gender"}
                    className="topsmall"
                    id={"gender"}
                    text={
                      this.context.stepValues["gender"]
                        ? this.context.stepValues["gender"]
                        : "Gender"
                    }
                    type="checkbox"
                    checked={this.state.showingGender ? true : false}
                    // hint={radioValue.hint}
                    onChange={(a, b, c) => {
                      this.setState({
                        showingGender: !this.state.showingGender,
                      });
                    }}
                  />
                  {this.state.showingGender ? (
                    <>
                      <Button
                        extraClass="btn_sm_top"
                        onClick={() => {
                          this.handleChange("gender", "Male");
                          this.setState({
                            showingGender: false,
                          });
                        }}>
                        Male
                      </Button>
                      <Button
                        extraClass="btn_sm_top"
                        onClick={() => {
                          this.handleChange("gender", "Female");
                          this.setState({
                            showingGender: false,
                          });
                        }}>
                        Female
                      </Button>
                      <div>&nbsp;</div>
                    </>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}

{ (step.is_payroll_auth_alt &&  this.context.stepValues["is_pyrl_price_bank"] ==  "yes" && this.context.stepValues["mode"] == "payroll") ? (
                <div
                  className="payroll_auth_conf_box">
                  <div>
                  <Radio
                    name={"is_gi2"}
                    className="topsmall littlebiggerfont"
                    id={"is_gi2"}
                    // defaultChecked={false}
                    text={step.is_payroll_auth ? "ACH / Payroll": "Payroll Deduction" }
                    type="checkbox"
                    defaultChecked={this.state.showGi}
                    // hint={radioValue.hint}
                    onChange={(a, b, c) => {
                      // this.setState({
                      //   showis_pyrl_price_bank:
                      //     !this.state.showis_pyrl_price_bank,
                      // });
                    }}
                  />
                  
                    <button
                      type="button"
                      onClick={() => {
                        // is_pyrl_price_bank
                        let stepValues = this.context.stepValues;
                        // this.is_pyrl_price_with_bank = "yes";
                        if(stepValues["is_pyrl_price_bank"] ==  "yes")
                        {
                          delete stepValues["is_pyrl_price_bank"];
                        }else{
                          stepValues["is_pyrl_price_bank"] =  "yes";
                        }
                        this.context.handleChangeStepValues(stepValues);
                        
                      }}>
                      {'Yes'}
                    </button>
                    <button className="active"
                      type="button"
                      onClick={() => {
                        
                      }}>
                      {   'No'}
                    </button>
                    </div>
                </div>
              ) : (
                false
              )}

              {step.is_payroll_auth   ? (
                <div
                  style={{
                    float: "right",
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 22,
                    textAlign: "center",
                  }}>
                  {/* <div style={{float:'right', textAlign :'center', }}> */}
                  <Radio
                    name={"is_gi"}
                    className="topsmall littlebiggerfont"
                    id={"is_gi"}
                    // defaultChecked={false}
                    text={step.is_payroll_auth ? "ACH / Payroll": "Payroll Deduction" }
                    type="checkbox"
                    defaultChecked={this.state.showGi}
                    // hint={radioValue.hint}
                    onChange={(a, b, c) => {
                      this.setState({
                        showis_pyrl_price_bank:
                          !this.state.showis_pyrl_price_bank,
                      });
                    }}
                  />
                  {this.state.showis_pyrl_price_bank ? (
                    <Button
                      extraClass="btn_sm_top littlebiggerfont"
                      onClick={() => {
                        // is_pyrl_price_bank
                        let stepValues = this.context.stepValues;
                        // this.is_pyrl_price_with_bank = "yes";
                        if(stepValues["is_pyrl_price_bank"] ==  "yes")
                        {
                          delete stepValues["is_pyrl_price_bank"];
                        }else{
                          stepValues["is_pyrl_price_bank"] =  "yes";
                        }
                        this.context.handleChangeStepValues(stepValues);
                        this.setState({
                          showis_pyrl_price_bank:
                            !this.state.showis_pyrl_price_bank,
                        });
                      }}>
                      {step.is_payroll_auth ?  'Confirm ACH' :'Confirm Payroll Deduction'}
                    </Button>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}
            </Section.Body>

            <Section.Actions>
              {this.state.goToLast ? (
                <Redirect
                  to={`/mode/${mode}/steps/${this.context.steps.length}`}
                />
              ) : (
                false
              )}
              {this.state.goToNext ? (
                <Redirect
                  to={
                    step.is_plan_step
                      ? "undefined" ===
                        typeof this.getFirstSelectedPlan(
                          this.context.stepValues,
                        )
                        ? "/mode/" +
                          mode +
                          "/steps/" +
                          (function () {
                            index = parseInt(index) + 2;
                            return index;
                          })()
                        : `/mode/${mode}/steps/${++index}/${this.getFirstSelectedPlan(
                            this.context.stepValues,
                          )}`
                      : step.is_price_step
                      ? `/mode/${mode}/steps${this.getNextSelectedPlan(
                          this.context.stepValues,
                          index,
                        )}`
                      : `/mode/${mode}/steps/${++index}`
                  }
                />
              ) : (
                false
              )}

              {step.do_not_proceed ? (
                false
              ) : (
                <div className="nextBtnGroup fadeIn ">
                  {/* cart button */}
                  {this.state.showProgress ? (
                    false
                  ) : this.shouldShowCartBtn() ? (
                    <div
                      onClick={() => {
                        this.setState({
                          is_cart: !this.state.is_cart,
                        });
                      }}
                      className={
                        (this.state.forceSuccessBtn ? " success_btnt " : "") +
                        "cart_icon_btn on_right_side" +
                        (step.is_maiden_step && disableNextStep
                          ? " simulatedisable "
                          : "") +
                        " " +
                        ((this.state.loadingPrices ||
                          (disableNextStep &&
                            this.state.showing_prices &&
                            step.is_price_step) ||
                          (!step.is_price_step && disableNextStep) ||
                          (step.is_plan_step &&
                            "undefined" ===
                              typeof this.getFirstSelectedPlan(
                                this.context.stepValues,
                              ) &&
                            !this.context.stepValues["std_manhattan_life"])) &&
                        !step.is_maiden_step
                          ? "disabled"
                          : "")
                      }>
                      {step.total ? (
                        <span className="total-pricef">${step.total}</span>
                      ) : (
                        <>
                        <i className="ico-cart"></i>
                        <span className="CartCnt">{this.has_cart_cnt}</span>
                        </>
                        
                      )}
                    </div>
                  ) : (
                    false
                  )}

                  {/* back button */}
                  {index == 1 || this.state.showProgress ? (
                    false
                  ) : (
                    <div
                      className={
                        (this.state.forceSuccessBtn ? " success_btnt " : "") +
                        "cart_icon_btn " +
                        (this.state.loadingPrices ||
                        (disableNextStep &&
                          this.state.showing_prices &&
                          step.is_price_step) ||
                        (!step.is_price_step && disableNextStep) ||
                        (step.is_plan_step &&
                          "undefined" ===
                            typeof this.getFirstSelectedPlan(
                              this.context.stepValues,
                            ) &&
                          !this.context.stepValues["std_manhattan_life"])
                          ? "disabled"
                          : "")
                      }
                      onClick={this.goBack.bind(this)}>
                      <i className="ico-chev-left "></i>
                    </div>
                  )}

                  {/* center button */}
                  <Button.Link
                    refs={input => {
                      this.inputElement = input;
                    }}
                    to={"#"}
                    onClick={e => {
                      e.preventDefault();

                      if (!this.state.showing_prices && step.is_price_step) {
                        // step.showing_prices = true;
                        this.setState({
                          loadingPrices: true,
                        });

                        // .form__head
                        // this.contextloadPrices();
                        let mode = this.props.match.params.mode;
                        this.context.maybeLoadPrices(plan, mode, () => {
                          this.setState({
                            rnddd: Math.random(),
                            loadingPrices: false,
                            showing_prices: true,
                          });
                        });
                        setTimeout(function () {
                          const textElement =
                            document.getElementsByClassName("form__head");
                          if (textElement[0]) {
                            textElement[0].scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }, 1000);

                        return;
                      }

                      if (step.is_price_step) {
                        this.setState({
                          showProgress: true,
                          final_btn_txt: "please wait...",
                        });

                        setTimeout(() => {
                          this.setState({
                            showProgress: false,
                            final_btn_txt: "Added to Cart",
                            forceSuccessBtn: true,
                          });
                        }, 1000);
                        setTimeout(() => {
                          this.doNextStep(step, index);
                          this.setState({
                            final_btn_txt: "",
                            forceSuccessBtn: false,
                          });
                        }, 2500);
                        return;
                      }

                      if (step.is_agent_step) {
                        this.setState({
                          goingBack: true,
                        });
                        setTimeout(() => {
                          this.handleAgentInfoIsHere(false);
                        }, 500);
                        return;
                      }

                      if (isNextStepLength) {
                        this.doNextStep(step, index);

                        return false;
                      }

                      this.setState({
                        final_btn_txt: "please wait...",
                        showProgress: true,
                      });
                      this.forwardData(true);
                      setTimeout(() => {
                        this.setState({
                          final_btn_txt: "Verifying information...",
                          // showProgress: true
                        });
                      }, 3000);
                      setTimeout(() => {
                        this.setState({
                          final_btn_txt: "Confirming rates ...",
                        });
                      }, 6000);
                      setTimeout(() => {
                        this.setState({
                          final_btn_txt: "Submitting application...",
                        });
                      }, 9000);
                      // setTimeout(() => {
                      //   this.setState(
                      //     { final_btn_txt: "Please wait..." },
                      //     () => {}
                      //   );
                      // }, 6000);
                    }}
                    size={
                      (this.has_cart_item
                        ? "large-alt nextBtn"
                        : "large-alt nextBtn fadeIn") +
                      (this.state.showProgress ? " is_f_smbting " : "") +
                      (this.state.loadingPrices
                        ? " smallsizebtn"
                        : this.state.showing_prices || !step.is_price_step
                        ? step.total
                          ? " smallsizebtn "
                          : " "
                        : " smallsizebtn") +
                      (this.state.forceSuccessBtn ? " success_btnt " : "")
                    }
                    disabled={
                      this.state.loadingPrices ||
                      (disableNextStep &&
                        this.state.showing_prices &&
                        step.is_price_step) ||
                      (!step.is_price_step && disableNextStep) ||
                      (step.is_plan_step &&
                        "undefined" ===
                          typeof this.getFirstSelectedPlan(
                            this.context.stepValues,
                          ) &&
                        !this.context.stepValues["std_manhattan_life"])
                    }>
                    {isNextStepLength ? (
                      <Fragment>
                        {this.state.showProgress ? (
                          <span
                            style={{marginRight: 10}}
                            className="spinnerbox">
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </span>
                        ) : (
                          false
                        )}
                        {this.state.final_btn_txt
                          ? this.state.final_btn_txt
                          : this.state.loadingPrices
                          ? "please wait..."
                          : this.state.showing_prices || !step.is_price_step
                          ? "Next"
                          : "Show Plans"}
                        {this.state.showProgress ? <span></span> : false}
                        {/* <i className="ico-angle-right-white"></i> */}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {this.state.showProgress ? (
                          <span
                            style={{marginRight: 10}}
                            className="spinnerbox">
                            <div className="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </span>
                        ) : (
                          false
                        )}
                        {this.state.final_btn_txt || "Submit"}

                        <i className="ico-check-big"></i>
                      </Fragment>
                    )}
                  </Button.Link>
                </div>
              )}

              {step.contact && <p>{step.contact.hint}</p>}
              {step.is_maiden_step ? (
                <div style={{marginTop: 20, textAlign: "center"}}>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleAgentInfoIsHere(true);
                    }}>
                    Agent Info. / Eff Date
                  </a>
                  <br />
                  <br />
                  <div className="ag_info_end radio_card m-0" style={{}}>
                    <div>
                      <strong style={{fontWeight: "500"}}>Agent Name:</strong>{" "}
                      {this.context.stepValues["agent-name"]}
                    </div>
                    <div>
                      <strong style={{fontWeight: "500"}}>Agent Code:</strong>{" "}
                      {this.context.stepValues["agent-no"]}
                    </div>
                    <div>
                      <strong style={{fontWeight: "500"}}>
                        Agent Signature Date:
                      </strong>{" "}
                      {moment(
                        this.context.stepValues["agent-signature-date"],
                      ).format("MM-DD-YYYY")}
                    </div>
                    <div>
                      <strong style={{fontWeight: "500"}}>
                        Desired Withdrawal Date:
                      </strong>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          this.handleAgentInfoIsHere(true);
                        }}>
                        {this.addTh(
                          this.context.stepValues["desired-withdrawal-date"],
                        )}
                      </a>
                    </div>
                    <div>
                      <strong style={{fontWeight: "500"}}>
                        Requested Effective Date:
                      </strong>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          this.handleAgentInfoIsHere(true);
                        }}>
                        {moment(
                          this.context.stepValues["requested-effective-date"],
                        ).format("MM-DD-YYYY")}
                      </a>
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        textAlign: "left",
                        borderTop: "1px solid grey",
                        marginTop: 12,
                        paddingTop: 21,
                        fontSize: 13,
                      }}>
                      <strong className="policy_sent_to_drop">
                        Mail Policy To
                        <select
                          className="mail_policy_to_select"
                          onChange={e => {
                            this.handleChange("mail_policy_to", e.target.value);
                          }}
                          value={this.context.stepValues["mail_policy_to"]}>
                          <option value="Insured">Insured</option>
                          <option value="Agent">Agent</option>
                          {this.context.stepValues["mode"] == "payroll" ? (
                            <option value="Employer">Employer</option>
                          ) : (
                            false
                          )}
                        </select>
                      </strong>
                      {this.context.stepValues["mail_policy_to"] ==
                      "Insured" ? (
                        <>
                          {this.context.stepValues["address"]},{" "}
                          {this.context.stepValues["city"]},{" "}
                          {this.context.stepValues["state"]},{" "}
                          {this.context.stepValues["zip"]}
                        </>
                      ) : (
                        this.context.stepValues["mail_policy_to"]
                      )}
                    </div>
                  </div>

                  <div
                    className="  width-100per is_ls_mail_option  small_btn btn--border btn btn--small"
                    style={{fontSize: 12}}>
                    <div>E-mail copy of Application To</div>
                    <div className="is_small_radio">
                      <Radio
                        trueFalseMode
                        type="checkbox"
                        defaultChecked={
                          this.context.stepValues["mail_to_agent"]
                        }
                        name="mail_to_agent"
                        id="mail_to_agent"
                        text="Agent"
                        trueRepresentative="yes"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="is_small_radio">
                      <Radio
                        trueFalseMode
                        type="checkbox"
                        defaultChecked={
                          this.context.stepValues["mail_to_customer"]
                        }
                        name="mail_to_customer"
                        id="mail_to_customer"
                        text="Applicant"
                        trueRepresentative="yes"
                        onChange={this.handleChange}
                        disabled={
                          !this.context.stepValues["email"] ||
                          !this.isValidEmail(this.context.stepValues["email"])
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}

              {step.contact && (
                <>
                  <div
                    className="widget-message"
                    style={{fontSize: 12, textAlign: "left"}}>
                    <strong>EMAIL CONSENT AUTHORIZATION</strong>
                    <br />I give my written consent to allow ManhattanLife
                    Insurance Company of America (the Company) to communicate
                    with me by email to the address(es) listed below. I confirm
                    that I have authorization to provide consent for email to
                    the email address(es) that I provide below and further agree
                    to indemnify and hold harmless the Company for any action or
                    loss arising from any incorrect or false email address(es)
                    provided below. I acknowledge that, should I desire to
                    revoke this written authorization, I will inform the
                    Company, in writing, of such revocation. q I decline to give
                    consent to the Company to communicate with me by email (do
                    not provide email addresses below). Primary email address:
                    Secondary email address: Signature: Date: Note: The
                    applicant electing to allow for notices and communications
                    to be sent to the electronic mail address provided by the
                    policyholder should be aware that the insurer rightfully
                    considers this election to be consent by the applicant that
                    all notices may be sent electronically, including notice of
                    non-renewal and notice of cancellation. Therefore, the
                    applicant should be diligent in updating the electronic mail
                    address provided to the insurer in the event that the
                    address should change
                  </div>

                  <div
                    className="widget__foot declinefoot"
                    style={{paddingTop: 10}}>
                    <CheckboxAlt
                      id="ch-decline2"
                      text="I decline to give consent to the Company to communicate with me by email."
                      checked={
                        this.context.stepValues["decline-email-contact"] ===
                        "yes"
                          ? true
                          : false
                      }
                      onChange={(e, b) => {
                        let phone = b ? "yes" : "no";
                        this.handleChange("decline-email-contact", phone);
                      }}
                    />
                  </div>
                </>
              )}
              <SmallComps.Hints hints={step.hints} />

              {step.showMessage ? <SmallComps.FinalMsg /> : false}
            </Section.Actions>
            <img
              alt=""
              style={{width: 1, height: 1}}
              src={require("../assets/images/img2.png")}
            />
          </Section>
        </div>
        <Menu
          agent_info={this.agent_info}
          isVisible={this.state.menuVisible}
          onClose={() => {
            this.setState({
              menuVisible: false,
            });
          }}
          renderDropdown={
            <div className="agent_config_select_new">
              {this.renderAgentConfigList("list", this.state.split_template)}
              {/* </select> */}
              <Link
                to="/add-template"
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  width: "100%",
                  textAlign: "center",
                  display: "inline-block",
                }}>
                + Add template
              </Link>
            </div>
          }
        />
      </Wrapper>
    );
  }
}

Steps.contextType = AppContext;

export default Steps;
