/**
 * External dependencies
 */
 import React, {Component} from 'react';
 import cx from 'classnames';
 import Geosuggest from 'react-geosuggest';
import InputMask from 'react-input-mask';
 import BenefRepeater from './BenefRepeater';
 import SplitRepeater from './SplitRepeater';
 import zipstate from '../../data/zip_state';
 
import moment from 'moment';
 
 // import DatePicker from "react-datepicker";
 /**
  * Provider
  */
 import AppContext from '../../app-provider';

 const capitalizeF = (o)=>{
	 if(!o)return '';
	
	const words = o.split(" ");

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
	}
	return words.join(' ');

 }
 
 class Field extends Component {
   state = {focused: false};
   // handleChangeDate = ()
   handleChange = (e, b, c, d) => { // event/value, name
	// console.log(e);
	 if (!e) return;
	 // console.log(e)
	 if (typeof e == 'string' || d=='yes') {
	   if (c) {
		 e = new Date(e).toJSON().slice(0, 10).split('-').reverse().join('/');
	   }
	   this.props.onChange && this.props.onChange(b ? b : this.props.name, e);
	   return;
	 }
	 const {moveToNextAfter, nextFieldId} = this.props;
	 e && e.preventDefault && e.preventDefault();
	 // console.log(moveToNextAfter)
	 this.props.onChange && this.props.onChange(e.target.name, e.target.value);
	 if(e.target.name === 'zip')
	 {
		 let stt = zipstate[e.target.value];
		if(stt)
		{
			// alert(capitalizeF(stt.ct||'')+' '+stt.ct)
			// console.log('state is : ', e.target.value, stt);
			this.props.onChange && this.props.onChange('state', capitalizeF(stt.st||''));
			this.props.onChange && this.props.onChange('city', capitalizeF(stt.ct||''));
		}
		
		
	 }
	 if (
	   moveToNextAfter &&
	   e.target.value &&
	   e.target.value.length >= moveToNextAfter
	 ) {
	   // move to next focusable field;
	   document.getElementById(nextFieldId).focus();
	 }
   };
 
   render() {
	 const {
	   name,
	   label,
	   label2,
	   innerClass,
	   options,
	   readonly,
	   prefix,
	   suffix,
	   type,
	   placeholder,
	   forcePlaceholder,
	   fixedLabel,
	   inputMode,
	   state_fixed,
	   isRequired,
	   value,
	   icon,
	   size,
	   pattern,
	   maxLength,
		 disable,
	   max,
	   min,
	   upperclass,
		 mask,
		 maskChar
	 } = this.props;

	 if(type ==='blank'){
		return(<div
		prefix={prefix ? prefix : ''}
		suffix={suffix ? suffix : ''}
		className={
		  'form__controls' +
		  (upperclass || prefix ? ' ' + upperclass : ' ') +
		  (' field_' + name)
		}>
			{/* {} */}
		</div>)
	 }
 
	 return (
	   <div
		 prefix={prefix ? prefix : ''}
		 suffix={suffix ? suffix : ''}
		 className={
		   'form__controls' +
		   (upperclass || prefix ? ' ' + upperclass : ' ') +
		   (' field_' + name)
		 }>
		 {/* {prefix ? prefix:''} */}
		 {type === 'benef-repeater' ?
		 <BenefRepeater  name={name}
			id={name}
			value={value || ''} onChange={this.handleChange}  /> :  type === 'split-repeater' ?
			<SplitRepeater  name={name}
			   id={name}
			   value={value || ''} onChange={this.handleChange}  /> : type === 'geo' ? (
		   <Geosuggest
			 // inputClassName="field"
			 country="US"
			 onFocus={() => {
			   this.setState({
				 focused: true,
			   });
			 }}
			 autoComplete="off"
			 onBlur={() => {
			   this.setState({
				 focused: true,
			   });
			 }}
			 inputClassName={
			   cx('field', {[`field--${size}`]: size}) + ' smallfont'
			 }
			 name={name}
			 id={name}
			 placeholder={label ? '' : placeholder}
			 initialValue={value || ''}
			 onChange={this.handleChange}
			 onSuggestSelect={(a, b, c, d) => {
			   console.log(a?.gmaps?.address_components);
			   if (!a || !a.gmaps || !a.gmaps.address_components) return;
			   let ln = a.gmaps.address_components.length;
			   let value = 'AZ';
			//    console.log(a.gmaps.address_components);
			   // console.log('length is '+ln);
			   let zip = '';
			   let city = '';
			   let city_alt = '';
			   for (let i = 0; i < ln; i++) {
				 if (
				   a.gmaps.address_components[i].types.indexOf('postal_code') >
				   -1
				 ) {
				   zip = a.gmaps.address_components[i].short_name;
				 }
				 if( a.gmaps.address_components[i].types.indexOf("locality") > -1)
				 {
					city= a.gmaps.address_components[i].short_name;
				 }
				 if( a.gmaps.address_components[i].types.indexOf("neighborhood") > -1)
				 {
					city_alt= a.gmaps.address_components[i].short_name;
				 }
				 if (
				   a.gmaps.address_components[i].short_name &&
				   a.gmaps.address_components[i].short_name.length === 2 &&
				   a.gmaps.address_components[i].short_name != 'US'
				 ) {
				   value = a.gmaps.address_components[i].short_name;
				 }
			   }
			   if (zip) {
				 if (!a.description) a.description = '';
				 let description = a.description.replace('USA', '');
				 description = description + zip + ', USA';
				 this.handleChange(description);
			   } else this.handleChange(a.description);
			   this.handleChange(value, 'state');
			   this.handleChange(zip, 'zip');
			   console.log(city )
			   console.log(city_alt)
			   this.handleChange(city || city_alt, 'city');

			 }}
			 required
			 pattern={pattern}
			 maxLength={maxLength}
			  
		   />
		 ) : type === 'select' ? (
		   <select
			 type="text"
			 className="field"
			 className={cx('field', {[`field--${size}`]: size})}
			 name={name}
			 id={name}
			 //  disabled={name =='state' && state_fixed}
			 // placeholder={label ? '' : placeholder}
			 value={value || ''}
			 onChange={this.handleChange}
			 required
			 // pattern={pattern}
			 // maxLength={maxLength}
		   >
				  <option value="">Select</option>

			   {
				   options ? options.map((item,i )=>
				   <option key={item} value={item}>{item}</option>) : 

				  <>
				  <option value="Checking Account">Checking Account</option>

			 <option value="Savings Account">Savings Account</option>
</>
			   }
			 
		   </select>
		 ) : type === 'mask' ? (<>
			<InputMask
			 className="field" disabled={disable}
			 mask={disable ? '\*\*' :mask ? mask :  "99/99/9999"}
			 name={name}   maskChar={disable?  '-' : maskChar?maskChar : ' '}
			 label={'Date of Birth'}
			 // placeholder={field.fieldDate.text}
			 value={disable ? '--': value ? value : ''}
			 onChange={this.handleChange}
		   /></>
		 ) : type === 'radioset' ? (
		   <div className="field radio-group-field">
			 {options.map((item, i) => {
			   return (
				 <label key={item}>
				   <input
					 type="radio"
					 name={name}
					 value={item}
					 onChange={e => {
					   this.handleChange(e);
					   setTimeout(() => {
						 this.handleChange('a', 'b');
					   }, 50);
					 }}
					 checked={value === item}
				   />{' '}
				   {item}
				 </label>
			   );
			 })}
		   </div>
		 ) : type === 'info' ? (
		   <div className="field radio-group-field">
			 {options.map((item, i) => {
			   return (
				 <label key={item} style={{fontSize: 14}}>
				   {/* <input type="radio" name={name} value={item}
						onChange={(e)=>{
							this.handleChange(e);
							setTimeout(()=>{
								this.handleChange('a','b');
							}, 50)
						}}  
						checked={value ===item}
						/>*/}
				   {item}
				 </label>
			   );
			 })}
		   </div>
		 ) : type === 'datepicker' ? (

			<div className="dp_f_wrap">
				<button type="button" style={{background: '#d9d9d9',border: 0}}  className="dp_f_left" onClick={e=>{
					e.preventDefault();
					let tmp = value ? moment(value ) : moment();
					tmp = tmp.subtract(1, 'day')
					tmp = tmp.format('YYYY-MM-DD')
					this.handleChange({
						target:{
							name: name, value: tmp
						}
					})
				}}>►</button>
				
				<input
			 type={'date'}
			 className="field"
			 className={cx('field', (innerClass||''), {[`field--${size}`]: size})}
			 name={name}
			 readOnly={readonly}
				//  required={isRequired||false}
				// prefix={prefix}
			 id={name}
			 inputMode={inputMode}
			 placeholder={label ? '' : placeholder}
			 value={value || ''}
			 onChange={this.handleChange}
			 required
			 pattern={pattern}
			 maxLength={maxLength}
			 max={max || moment().add(90, 'days').format('YYYY-MM-DD')}
			 min={min}
		   />
				<button type="button" style={{background: '#d9d9d9',border: 0}}  className="dp_f_right" onClick={e=>{
					e.preventDefault();
					let af90da = moment().add(90, 'days') 
					
					let tmp = value ? moment(value ) : moment();
					tmp = tmp.add(1, 'day')
					if(tmp.isAfter(af90da))
					{
						return false;
					}
					tmp = tmp.format('YYYY-MM-DD')
					this.handleChange({
						target:{
							name: name, value: tmp
						}
					})
				}}>►</button>
				</div>
		 ) : (
		   <input
			 type={type || 'text'}  
			 disabled={disable}
			 className="field"
			 className={cx('field', {[`field--${size}`]: size})}
			 name={name}
			 readOnly={readonly}
				//  required={isRequired||false}
				// prefix={prefix}
			 id={name}
			 inputMode={inputMode}
			 placeholder={forcePlaceholder ? forcePlaceholder : label ? '' : placeholder}
			 value={disable?  '--' :  value ? value : ''}
			 onChange={this.handleChange}
			 required
			 pattern={pattern}
			 maxLength={maxLength}
			 max={max}
			 min={min}
		   />
		 )}
		 {label ? (
		   <label style={label2? {width:'calc(100% - 22px)'} : {}}
			 className={
			   'form__label' +
			   (value ||
			   type === 'select' ||
			   this.state.focused ||
			   fixedLabel ||
			   prefix ||
			   suffix ||
			   type === 'radioset' ||
			   type === 'info'
				 ? ' fixedlabel '
				 : '  ')
			 }
			 htmlFor={name}>
			 {label == 'first_last'  ? <>First &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last</>: label}
			 {
				 label2 ? <span className="field_extra" >{label2}</span> : false
			 }
		   </label>
		 ) : (
		   false
		 )}
 
		 {icon ? <img src={icon} alt="" className="form__ico" /> : false}
	   </div>
	 );
   }
 }
 
 Field.contextType = AppContext;
 
 export default Field;
 