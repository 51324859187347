import React from "react";
import Section from "../layouts/section";
import Wrapper from "../layouts/wrapper";

class ResumeApplication extends React.Component{
  componentDidMount(){
    let mode = this.props.match.params.appId;
    window.localStorage.setItem('resuming_item', 'yes');
    window.localStorage.setItem('resuming_item_id', mode);
    window.location.href="/";
  }
  render(){
    return(
      <Wrapper>
      <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png')}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Resuming Failed Application
                </h1>
                <div className="text-center" style={{marginTop:40}}>PLEASE WAIT...</div>
                </div></Section.Head>
      </Section>
      </div>
    </Wrapper>

    )
  }
}

export default ResumeApplication;
