/**
 * External dependencies
 */
 import React, {Component} from 'react';

 class Textarea extends Component {
   handleChange = e => {
	 e.preventDefault();
 
	 this.props.onChange(e.target.name, e.target.value);
   };
 
   render() {
	 const {name, label, placeholder, prefix, value, fixedlabel} = this.props;
 
	 return (
	   <div
		 className="form__controls form__controls--textarea"
		 prefix={prefix ? prefix : ''}>
		 {/* <label 
					 className="form__label" 
					 htmlFor={name}
				 >
					 {label}
				 </label> */}
 
		 <textarea
		   name={name}
		   id={name}
		   placeholder={label ? '' : placeholder}
		   value={value || ''}
		   onChange={this.handleChange}
		   className="field"></textarea>
		 {label ? (
		   <label
			 className={
			   'form__label' + (value || fixedlabel ? ' fixedlabel ' : '  ')
			 }
			 htmlFor={name}>
			 {label}
		   </label>
		 ) : (
		   false
		 )}
	   </div>
	 );
   }
 }
 
 export default Textarea;
 