/**
 * External dependencies.
 */
import React, { Component } from 'react';
import cx from 'classnames';

class Header extends Component {
	render() {
		const { className, children } = this.props
		
		return (
			<div className={cx('header', className)}>
				<div className="shell">
					{children}
				</div>
			</div>
		);
	}
}

export default Header;
